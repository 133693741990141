import React from "react";
import LandingPage from "../../layouts/landing-page";
import FullScreenMessage from "../../components/full-screen-message";

class PasswordResetInvalid extends React.Component {
  render() {
    return (
      <LandingPage>
        <span aria-hidden={true} data-testid={"page-password-reset-invalid"} />
        <FullScreenMessage title={"Password Reset"}>
          This reset link is invalid. Please request a new one.
        </FullScreenMessage>
      </LandingPage>
    );
  }
}

export default PasswordResetInvalid;
