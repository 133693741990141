import React from "react";
import SidebarFacility from "../../../views/sidebar-facility";
import Api from "../../../services/api";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  fetchedFacility,
  facilityFetchError
} from "../../../redux/reducers/map/actions";

class MapFacilityPage extends React.Component {
  static defaultProps = {
    dispatchFetchedFacility: payload => {
      console.warn("property not assigned");
    }
  };
  static propTypes = {
    dispatchFetchedFacility: PropTypes.func
  };

  componentDidMount() {
    this.initfacilityMode();
  }

  initfacilityMode() {
    Api.getFacility(
      this.props.match.params.sslug,
      this.props.match.params.fslug
    )
      .then(data => {
        this.props.dispatchFetchedFacility(data.data);
      })
      .catch(error => {
        this.props.dispatchFacilityFetchError(error);
      });
  }

  render() {
    const sidebar = (
      <>
        <SidebarFacility />
        <span aria-hidden={true} data-testid={"page-map-facility"} />
      </>
    );

    return sidebar;
  }
}

export default withRouter(
  connect(null, dispatch => ({
    dispatchFetchedFacility: payload => dispatch(fetchedFacility(payload)),
    dispatchFacilityFetchError: payload => dispatch(facilityFetchError(payload))
  }))(MapFacilityPage)
);
