import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchLocation } from "@fortawesome/free-solid-svg-icons";

class ExploreThisArea extends React.Component {
  static defaultProps = {
    onClick: () => {}
  };

  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string
  };

  render() {
    return (
      <>
        <button
          className={classNames([
            `rounded-lg py-1 px-2 bg-white mt-2 inline-flex m-auto items-start`,
            this.props.className || "relative"
          ])}
          onClick={this.props.onClick}
        >
          <FontAwesomeIcon
            icon={faSearchLocation}
            size={"lg"}
            className={"h-full inline-block mr-1 inset-y-0"}
          />
          <span className={"leading-5"}>
            {this.props.children || "Explore this area"}
          </span>
        </button>
      </>
    );
  }
}

export default ExploreThisArea;
