import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  stadiumMouseEnter,
  stadiumMouseLeave
} from "../../redux/reducers/map/actions";
import { SidebarSearch } from "../sidebar-search";

export default withRouter(
  connect(
    state => ({
      results: state.map.cityMode.results,
      currentPage: state.map.cityMode.sidebarCurrentPage,
      error: state.map.cityMode.error,
      highlightedMarker: state.map.mapAreaTrigger.highlightedMarker
    }),
    dispatch => ({
      dispatchMouseEnter: payload => dispatch(stadiumMouseEnter(payload)),
      dispatchMouseLeave: payload => dispatch(stadiumMouseLeave(payload))
    })
  )(SidebarSearch)
);
