import React from "react";
import { connect } from "react-redux";
import {
  showModal,
  updateMyPlacesResults,
  myPlacesFetchError,
  startLoadingMyPlaces
} from "../../redux/reducers/login/actions";
import LoadingComponent from "../../components/loading-component";
import Api from "../../services/api";
import ErrorMessage from "../../components/error-message";
import {
  closeReviewModal,
  openReviewModal
} from "../../redux/reducers/map/actions";
import Modal from "../../components/modal";
import ReviewFacilityForm from "../review-facility-form";
import FacilityList from "../../components/facility-list";

export class SidebarMyPlaces extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loggedInUser === null && this.props.loggedInUser !== null) {
      this.callApi();
    }
  }
  callApi() {
    this.props.dispatchStartLoadingMyPlaces();
    Api.getMyPlaces()
      .then(data => {
        this.props.dispatchUpdateMyPlacesResults(data.data);
      })
      .catch(error => {
        this.props.dispatchMyPlacesFetchError(error);
      });
  }
  componentDidMount() {
    if (this.props.loggedInUser !== null) {
      this.callApi();
    }
  }

  renderLoggedIn() {
    if (this.props.error !== null) {
      return (
        <ErrorMessage className={"py-6 px-4"}>{this.props.error}</ErrorMessage>
      );
    }

    if (this.props.stadiums === null) {
      return <LoadingComponent />;
    }

    // Calculate the pagination offset
    const offset = (this.props.currentPage - 1) * 10;
    // Add links to the stadiums
    let data = this.props.stadiums
      .slice(offset, offset + 10)
      .map(stadiumData => {
        const copy = { ...stadiumData };
        copy.link = {
          to: `/map/stadium/${stadiumData.slug}`
        };

        return copy;
      });

    return (
      <div>
        {data.length === 0 ? (
          <p className={"p-6 text-gray-700 text-center"}>
            Start saving places to see them here.
          </p>
        ) : (
          <>
            {this.props.showReviewModal && (
              <Modal
                title={`${this.props.reviewModalTitle}`}
                onClose={this.closeReviewModal}
              >
                <ReviewFacilityForm />
              </Modal>
            )}
            {data.map(stadium => (
              <>
                <div>
                  <h3 className={"w-full bg-blue-900 text-white px-6 py-3"}>
                    {stadium.name}
                  </h3>
                </div>
                <FacilityList stadium={stadium} />
                {/*{stadium.relationships.facilities.map(facility => {*/}
                {/*  return (*/}
                {/*    <FacilityCard*/}
                {/*      showReviews={true}*/}
                {/*      showFewReviews={true}*/}
                {/*      readMoreLink={{*/}
                {/*        to: `/map/stadium/${stadium.slug}/facility/${facility.what3words}`*/}
                {/*      }}*/}
                {/*      data={facility}*/}
                {/*      onReviewClick={() =>*/}
                {/*        this.props.dispatchOpenReviewModal(facility)*/}
                {/*      }*/}
                {/*    />*/}
                {/*  );*/}
                {/*})}*/}
              </>
            ))}
          </>
        )}
      </div>
    );
  }

  renderNotLoggedIn() {
    return (
      <div className={"py-3 px-5 font-serif text-gray-700 text-sm"}>
        <button
          className={"text-blue-600 underline font-sans"}
          onClick={e => {
            e.preventDefault();
            this.props.dispatchLogIn();
          }}
        >
          Sign in
        </button>{" "}
        to save your favorite places.
      </div>
    );
  }

  render() {
    if (!this.props.loggedInUser) {
      return this.renderNotLoggedIn();
    } else {
      return this.renderLoggedIn();
    }
  }
}

export default connect(
  state => ({
    loggedInUser: state.login.loggedInUser,
    stadiums: state.login.myPlaces.stadiums,
    currentPage: state.login.myPlaces.sidebarCurrentPage,
    error: state.login.myPlaces.error,
    showReviewModal: state.map.reviewForm.showModal,
    reviewModalTitle: state.map.reviewForm.modalTitle,
    reviewFacilityId: state.map.reviewForm.facilityId
  }),
  dispatch => ({
    dispatchLogIn: payload => dispatch(showModal(payload)),
    dispatchStartLoadingMyPlaces: payload =>
      dispatch(startLoadingMyPlaces(payload)),
    dispatchUpdateMyPlacesResults: payload =>
      dispatch(updateMyPlacesResults(payload)),
    dispatchMyPlacesFetchError: payload =>
      dispatch(myPlacesFetchError(payload)),
    dispatchOpenReviewModal: payload => dispatch(openReviewModal(payload)),
    dispatchCloseReviewModal: payload => dispatch(closeReviewModal(payload))
  })
)(SidebarMyPlaces);
