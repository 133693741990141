import React from "react";
import PropTypes from "prop-types";
import s from "./Stars.module.scss";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

class Stars extends React.PureComponent {
  static defaultProps = {
    total: 0,
    size: "xs",
    showTotal: true
  };

  static propTypes = {
    total: PropTypes.number.isRequired,
    count: PropTypes.number,
    showTotal: PropTypes.bool
  };

  generateStars(size) {
    let separationClass;

    switch (size) {
      case "lg":
        separationClass = "ml-1 mr-0.5";
        break;
      case "xs":
      default:
        separationClass = "mx-0.5";
        break;
    }

    let stars = [];
    for (let i = 1; i <= 5; i++) {
      let star;
      if (this.props.total >= i) {
        star = <FontAwesomeIcon icon={faStar} className={"text-red-500"} />;
      } else if (Math.ceil(this.props.total) === i) {
        const partialStarClassName = `partial-star__w-${
          (this.props.total % 1).toFixed(1).split(".")[1]
        }`;
        star = (
          <>
            <span
              className={classNames([
                s["partial-star"],
                s[partialStarClassName]
              ])}
            >
              <FontAwesomeIcon icon={faStar} className={"text-red-500"} />
            </span>
            <FontAwesomeIcon icon={faStar} className={"text-gray-600"} />
          </>
        );
      } else {
        star = <FontAwesomeIcon icon={faStar} className={"text-gray-600"} />;
      }
      stars.push(
        <span
          className={classNames([
            "relative first:ml-0 last:mr-0 inline-block",
            separationClass
          ])}
          key={i}
        >
          {star}
        </span>
      );
    }

    return stars;
  }

  render() {
    let overallSize, starSize;
    switch (this.props.size) {
      case "lg":
        overallSize = "text-lg";
        starSize = "text-sm mx-2";
        break;
      case "md":
        overallSize = "text-md";
        starSize = "text-sm mx-1";
        break;
      case "sm":
        overallSize = "text-sm";
        starSize = "text-xs mx-1";
        break;
      case "xs":
      default:
        overallSize = "text-xs";
        starSize = "text-tiny mx-2";
        break;
    }

    if (this.props.total === 0) {
      return <></>;
      // return <div className={"text-xs text-gray-700"}>No reviews</div>;
    }

    return (
      <div className={classNames(["flex items-center", overallSize])}>
        {this.props.showTotal && (
          <span className={"text-red-500"}>{this.props.total.toFixed(1)}</span>
        )}
        <div
          className={classNames([
            "text-red-500",
            starSize,
            !this.props.showTotal && "ml-0"
          ])}
          title={`${this.props.total}/5`}
        >
          {this.generateStars(this.props.size)}
        </div>
        <span className={"text-gray-700"}>
          {this.props.count && `(${this.props.count})`}
        </span>
      </div>
    );
  }
}

export default Stars;
