import React from "react";
import MainHeader from "../../components/main-header";
import { withRouter } from "react-router";
import classNames from "classnames";
import Modal from "../../components/modal";
import SignInForm from "../../views/sign-in-form";
import { connect } from "react-redux";
import { hideModal } from "../../redux/reducers/login/actions";
import UserMenu from "../../components/user-menu";
import ErrorBanner from "../../components/error-banner";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }
  closeModal() {
    this.props.dispatchHideModal();
  }
  render() {
    return (
      <div
        className={classNames([
          "flex flex-col h-screen w-screen min-h-0 font-sans",
          this.props.className
        ])}
      >
        <MainHeader
          onSearch={q => {
            this.props.history.push(`/search/${q}`);
          }}
          rightMenu={<UserMenu />}
        />
        {this.props.showModal && (
          <Modal title={this.props.loginTitle} onClose={this.closeModal}>
            <SignInForm />
          </Modal>
        )}
        {this.props.children}

        <div id={"portal"}></div>
        {this.props.error && (
          <div>
            <ErrorBanner
              onClose={() => this.props.dispatchUpdateGeneralError(null)}
            >
              {this.props.error}
            </ErrorBanner>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    loginTitle: state.login.title,
    showModal: state.login.showModal,
    loggedInUser: state.login.loggedInUser,
    error: state.map.generalError
  }),
  dispatch => ({
    dispatchHideModal: payload => dispatch(hideModal(payload))
  })
)(withRouter(LandingPage));
