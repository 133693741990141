import { createReducer } from "@reduxjs/toolkit";
import {
  updateExploreResults,
  updateSearchResults,
  updateSearchString,
  openStadium,
  userLocationLoaded,
  mapAreaUpdated,
  exploreNewArea,
  updateHistorySearchResults,
  stadiumMouseEnter,
  stadiumMouseLeave,
  fetchedStadium,
  fetchedFacility,
  facilityLike,
  facilityDislike,
  pageChanged,
  newSearchStarted,
  closeSidebarFacility,
  closeSidebarStadium,
  stadiumFetchError,
  facilityFetchError,
  closeReviewModal,
  openReviewModal,
  exploreModeFetchError,
  sidebarCollapse,
  sidebarExpand,
  startSearch,
  cancelSearch,
  closeSearch,
  searchFetchError,
  openSearch,
  updateGeneralError,
  commitSearch,
  updateSearchErrorMessage,
  updateSearchModeSearchResults,
  clearSearchMode,
  clearStadiumMode,
  clearExploreMode,
  updateRatingFilter,
  searchPageChanged,
  changeMapStyle,
  adjustMap,
  endSearch,
  setCityModeCity,
  updateCityModeResults,
  clearCityMode,
  newCitySearchStarted,
  updateCitySearchErrorMessage,
  updateCityModeSearchResults,
  updateCitySearchRatingFilter,
  citySearchPageChanged,
  openFacility
} from "./actions";
import { cloneDeep } from "lodash";

import initialState from "./initialState";

function clone(state) {
  return { ...state };
}

const actionsMap = {
  [userLocationLoaded]: (state, { payload }) => {
    let newState = clone(state);
    newState.userLocation = payload;
    newState.initialLocation = payload;
    return newState;
  },
  [mapAreaUpdated]: (state, { payload }) => {
    let newState = clone(state);
    newState.mapArea = payload;
    newState.mapAreaChanged = true;

    if (newState.userLocation.center === null) {
      newState.userLocation = payload;
    }
    return newState;
  },
  [exploreNewArea]: (state, { payload }) => {
    let newState = clone(state);
    newState.mapAreaChanged = false;
    newState.exploreMode = clone(state.exploreMode);
    newState.exploreMode.sidebarCurrentPage = 1;
    newState.exploreMode.loading = true;
    return newState;
  },
  [fetchedStadium]: (state, { payload }) => {
    let newState = clone(state);
    newState.stadiumMode = clone(state.stadiumMode);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.stadiumMode.stadium = payload;
    newState.mapAreaTrigger.data = payload.geojson;
    newState.mapAreaTrigger.adjustToMarkers = true;
    newState.mapAreaTrigger.markerImage = "marker-facility";
    newState.mapAreaTrigger.markerHoverImage = "marker-facility_hover";
    newState.stadiumMode.error = null;
    return newState;
  },
  [updateSearchErrorMessage]: (state, { payload }) => {
    let newState = clone(state);
    newState.searchMode = clone(state.searchMode);

    newState.searchMode.error = payload;
    return newState;
  },
  [stadiumFetchError]: (state, { payload }) => {
    let newState = clone(state);
    newState.stadiumMode = clone(state.stadiumMode);

    newState.stadiumMode.error = payload;
    return newState;
  },
  [fetchedFacility]: (state, { payload }) => {
    let newState = clone(state);
    newState.stadiumMode = clone(state.stadiumMode);
    newState.facilityMode = clone(state.facilityMode);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.facilityMode.facility = payload;
    newState.mapAreaTrigger.data = payload.geojson;
    newState.mapAreaTrigger.adjustToMarkers = true;
    newState.mapAreaTrigger.markerImage = "marker-facility";
    newState.mapAreaTrigger.markerHoverImage = "marker-facility_hover";
    newState.stadiumMode.error = null;
    return newState;
  },

  [facilityFetchError]: (state, { payload }) => {
    let newState = clone(state);
    newState.facilityMode = clone(state.facilityMode);

    newState.facilityMode.error = payload;
    return newState;
  },
  [updateExploreResults]: (state, { payload }) => {
    if (payload && payload.data) {
      // Immutable
      let newState = clone(state);
      newState.exploreMode = clone(state.exploreMode);
      newState.mapAreaTrigger = clone(state.mapAreaTrigger);

      newState.exploreMode.error = null;
      newState.exploreMode.stadiums = payload.data;
      newState.exploreMode.loading = false;
      newState.mapAreaTrigger.data = payload.geojson;
      newState.mapAreaTrigger.adjustToMarkers = false;
      newState.mapAreaTrigger.markerImage = "marker";
      newState.mapAreaTrigger.markerHoverImage = "marker_hover";
      newState.mapAreaChanged = false;
      newState.exploreMode.sidebarCurrentPage = 1;

      // Return
      return newState;
    }
    return state;
  },
  [updateSearchResults]: (state, { payload }) => {
    // Immutable
    let newState = clone(state);
    newState.searcher = clone(state.searcher);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.mapAreaTrigger.markerImage = "marker";
    newState.mapAreaTrigger.markerHoverImage = "marker_hover";

    newState.searcher.results = { ...newState.searcher.results, ...payload };
    newState.mapAreaTrigger.adjustToMarkers = true;

    // Return
    return newState;
  },
  [updateSearchModeSearchResults]: (state, { payload }) => {
    // Immutable
    let newState = clone(state);
    newState.searchMode = clone(state.searchMode);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.searchMode.error = null;
    newState.searchMode.results = payload;
    newState.searchMode.mapShouldReadjust = true;

    newState.mapAreaTrigger.data = payload.geojson;
    newState.mapAreaTrigger.adjustToMarkers = true;

    newState.mapAreaTrigger.markerImage = "marker";
    newState.mapAreaTrigger.markerHoverImage = "marker_hover";

    // Return
    return newState;
  },
  [updateHistorySearchResults]: (state, { payload }) => {
    // Immutable
    let newState = clone(state);
    newState.searcher = clone(state.searcher);
    newState.searcher.results = clone(state.searcher.results);

    if (newState.searcher.results === null) {
      newState.searcher.results = {};
    }
    newState.searcher.results.history = payload;

    // Return
    return newState;
  },
  [searchFetchError]: (state, { payload }) => {
    let newState = clone(state);
    newState.searcher = clone(state.searcher);

    newState.searcher.error = payload;
    return newState;
  },
  [newSearchStarted]: (state, { payload }) => {
    // Immutable
    let newState = clone(state);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.searcher = clone(state.searcher);
    newState.mapAreaTrigger.adjustToMarkers = false;

    newState.searcher.error = null;
    newState.searcher.searching = true;

    // Return
    return newState;
  },
  [newCitySearchStarted]: (state, { payload }) => {
    // Immutable
    let newState = clone(state);
    newState.cityMode = clone(state.cityMode);
    newState.mapAreaTrigger.markerImage = "marker";
    newState.mapAreaTrigger.markerHoverImage = "marker_hover";

    newState.cityMode.error = null;
    newState.cityMode.searching = true;

    // Return
    return newState;
  },
  [updateSearchString]: (state, { payload }) => {
    let newState = clone(state);
    newState.searcher = clone(state.searcher);

    newState.searcher.query = payload;
    return newState;
  },
  [openStadium]: (state, { payload }) => {
    let newState = clone(state);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.mapAreaTrigger.highlightedMarker = null;
    return newState;
  },
  [stadiumMouseEnter]: (state, { payload }) => {
    let newState = clone(state);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.mapAreaTrigger.highlightedMarker = payload;
    return newState;
  },
  [stadiumMouseLeave]: (state, { payload }) => {
    let newState = clone(state);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.mapAreaTrigger.highlightedMarker = null;
    return newState;
  },
  [pageChanged]: (state, { payload }) => {
    let newState = clone(state);
    newState.exploreMode = clone(state.exploreMode);

    newState.exploreMode.sidebarCurrentPage = payload;
    return newState;
  },
  [facilityLike]: (state, { payload }) => {
    let newState = clone(state);

    function setToTrue(facility) {
      if (facility.id === payload) {
        facility.liked = true;
      }
    }
    if (
      newState.stadiumMode.stadium &&
      newState.stadiumMode.stadium.relationships
    ) {
      newState.stadiumMode = cloneDeep(state.stadiumMode);
      newState.stadiumMode.stadium.relationships.facilities.forEach(setToTrue);
    }
    if (newState.facilityMode.facility) {
      newState.facilityMode = cloneDeep(state.facilityMode);
      setToTrue(newState.facilityMode.facility);
    }

    return newState;
  },
  [facilityDislike]: (state, { payload }) => {
    let newState = clone(state);
    function setToFalse(facility) {
      if (facility.id === payload) {
        facility.liked = false;
      }
    }
    if (
      newState.stadiumMode.stadium &&
      newState.stadiumMode.stadium.relationships
    ) {
      newState.stadiumMode = cloneDeep(state.stadiumMode);
      newState.stadiumMode.stadium.relationships.facilities.forEach(setToFalse);
    }

    if (newState.facilityMode.facility) {
      newState.facilityMode = cloneDeep(state.facilityMode);
      setToFalse(newState.facilityMode.facility);
    }
    return newState;
  },
  [closeReviewModal]: (state, { payload }) => {
    let newState = clone(state);
    newState.reviewForm = clone(state.reviewForm);

    newState.reviewForm.showModal = false;
    newState.reviewForm.modalTitle = initialState.reviewForm.modalTitle;

    return newState;
  },
  [openReviewModal]: (state, { payload }) => {
    let newState = clone(state);
    newState.reviewForm = clone(state.reviewForm);

    newState.reviewForm.showModal = true;
    newState.reviewForm.modalTitle = payload.name;
    newState.reviewForm.facilityId = payload.id;
    newState.reviewForm.facilityReviews = payload.relationships?.reviews;

    return newState;
  },
  [sidebarCollapse]: (state, { payload }) => {
    let newState = clone(state);

    newState.sidebarExpanded = false;
    return newState;
  },
  [sidebarExpand]: (state, { payload }) => {
    let newState = clone(state);

    newState.sidebarExpanded = true;
    return newState;
  },
  [startSearch]: (state, { payload }) => {
    let newState = clone(state);
    newState.searcher = clone(state.searcher);

    newState.searcher.open = true;
    newState.searcher.error = null;
    newState.searcher.searching = true;
    newState.searcher.query = payload;
    return newState;
  },
  [endSearch]: (state, { payload }) => {
    let newState = clone(state);
    newState.searcher = clone(state.searcher);

    newState.searcher.searching = false;
    return newState;
  },
  [commitSearch]: (state, { payload }) => {
    // Save
    let sh = JSON.parse(window.localStorage.getItem("search-history") || "[]");
    if (!sh.includes(payload)) {
      sh.push(payload);
    }
    window.localStorage.setItem("search-history", JSON.stringify(sh));

    return state;
  },
  [cancelSearch]: (state, { payload }) => {
    let newState = clone(state);

    newState.searcher = clone(initialState.searcher);
    return newState;
  },
  [closeSearch]: (state, { payload }) => {
    let newState = clone(state);
    newState.searcher = clone(state.searcher);

    newState.searcher.open = false;
    return newState;
  },
  [openSearch]: (state, { payload }) => {
    let newState = clone(state);
    newState.searcher = clone(state.searcher);

    newState.searcher.open = true;
    return newState;
  },
  [closeSidebarStadium]: (state, { payload }) => {
    let newState = clone(state);

    newState.stadiumMode = clone(initialState.stadiumMode);
    return newState;
  },
  [closeSidebarFacility]: (state, { payload }) => {
    let newState = clone(state);

    newState.facilityMode = clone(initialState.facilityMode);
    return newState;
  },
  [exploreModeFetchError]: (state, { payload }) => {
    let newState = clone(state);
    newState.exploreMode = clone(state.exploreMode);

    newState.exploreMode.error = payload;
    newState.exploreMode.loading = false;
    return newState;
  },
  [updateGeneralError]: (state, { payload }) => {
    let newState = clone(state);

    newState.generalError = payload;
    return newState;
  },
  [clearSearchMode]: (state, { payload }) => {
    let newState = clone(state);

    newState.searchMode = clone(initialState.searchMode);
    return newState;
  },
  [clearCityMode]: (state, { payload }) => {
    let newState = clone(state);

    newState.cityMode = clone(initialState.cityMode);
    return newState;
  },
  [clearStadiumMode]: (state, { payload }) => {
    let newState = clone(state);

    newState.stadiumMode = clone(initialState.stadiumMode);
    return newState;
  },
  [clearExploreMode]: (state, { payload }) => {
    let newState = clone(state);

    newState.exploreMode = clone(initialState.exploreMode);
    return newState;
  },
  [updateRatingFilter]: (state, { payload }) => {
    let newState = clone(state);
    newState.searchMode = clone(state.searchMode);

    newState.searchMode.ratingFilter = payload;
    newState.searchMode.sidebarCurrentPage = 1;
    return newState;
  },
  [updateCitySearchRatingFilter]: (state, { payload }) => {
    let newState = clone(state);
    newState.cityMode = clone(state.cityMode);

    newState.cityMode.ratingFilter = payload;
    newState.cityMode.sidebarCurrentPage = 1;
    return newState;
  },
  [searchPageChanged]: (state, { payload }) => {
    let newState = clone(state);
    newState.searchMode = clone(state.searchMode);

    newState.searchMode.sidebarCurrentPage = payload;
    return newState;
  },
  [changeMapStyle]: (state, { payload }) => {
    let newState = clone(state);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.mapAreaTrigger.mapStyle = payload;
    return newState;
  },
  [adjustMap]: (state, { payload }) => {
    let newState = clone(state);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.mapArea = initialState.mapArea;

    newState.mapAreaTrigger.center = {
      lat: payload.lat,
      lng: payload.lng
    };
    newState.mapAreaTrigger.zoom = payload.zoom;
    newState.mapAreaTrigger.adjustToMarkers = false;

    return newState;
  },
  [setCityModeCity]: (state, { payload }) => {
    let newState = clone(state);
    newState.cityMode = clone(state.cityMode);
    newState.cityMode.state = payload.state;
    newState.cityMode.city = payload.city;
    newState.mapAreaTrigger.markerImage = "marker";
    newState.mapAreaTrigger.markerHoverImage = "marker_hover";
  },
  [updateCityModeResults]: (state, { payload }) => {
    // Immutable
    let newState = clone(state);
    newState.cityMode = clone(state.cityMode);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.cityMode.results = { ...newState.cityMode.results, ...payload };
    newState.mapAreaTrigger.adjustToMarkers = true;

    // Return
    return newState;
  },
  [updateCitySearchErrorMessage]: (state, { payload }) => {
    let newState = clone(state);
    newState.cityMode = clone(state.cityMode);

    newState.cityMode.error = payload;
    return newState;
  },

  [updateCityModeSearchResults]: (state, { payload }) => {
    // Immutable
    let newState = clone(state);
    newState.cityMode = clone(state.cityMode);
    newState.mapAreaTrigger = clone(state.mapAreaTrigger);

    newState.cityMode.error = null;
    newState.cityMode.results = payload;
    newState.cityMode.mapShouldReadjust = true;

    newState.mapAreaTrigger.data = payload.geojson;
    newState.mapAreaTrigger.adjustToMarkers = true;

    // Return
    return newState;
  },
  [citySearchPageChanged]: (state, { payload }) => {
    let newState = clone(state);
    newState.cityMode = clone(state.cityMode);

    newState.cityMode.sidebarCurrentPage = payload;
    return newState;
  },
  [openFacility]: (state, { payload }) => {
    let newState = clone(state);
    newState.stadiumMode = clone(state.stadiumMode);
    newState.stadiumMode.openedFacility = { id: payload }; // it's an object so it triggers an update
    return newState;
  }
};

export default createReducer(initialState, actionsMap);
