import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import s from "./NewsCard.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faTag } from "@fortawesome/free-solid-svg-icons";

class NewsCard extends React.Component {
  static defaultProps = {};

  static propTypes = {
    data: PropTypes.array.isRequired
  };

  formatDate(stringDate) {
    const date = new Date(stringDate);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    return `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  }
  render() {
    return (
      <article>
        <a
          className={classNames([
            this.props.className,
            s["news-card"],
            "cursor-pointer hover:bg-gray-200 w-full block"
          ])}
          href={this.props.data.url}
        >
          <div className={"flex items-center"}>
            <div
              className={"flex-none mr-6 overflow-hidden"}
              style={{ width: "100px", height: "100px" }}
            >
              <img
                className={"w-full h-full object-cover object-center"}
                src={this.props.data.thumbnail}
                loading="lazy"
                alt={""}
              />
            </div>
            <div>
              <h3 className={"text-lg mb-2"}>{this.props.data.title}</h3>
              <p
                className={"font-serif text-sm"}
                dangerouslySetInnerHTML={{
                  __html: `${this.props.data.highlights ||
                    this.props.data.excerpt}`
                }}
              />
              <dl className={"flex font-serif text-sm mt-2"}>
                <dt className={"mr-2"}>
                  <FontAwesomeIcon icon={faCalendar} />
                </dt>
                <dd className={"mr-4"}>
                  {this.formatDate(this.props.data.date)}
                </dd>
                {this.props.data.tags && (
                  <>
                    <dt className={"mr-2"}>
                      <FontAwesomeIcon icon={faTag} />
                    </dt>
                    <dd>{this.props.data.tags}</dd>
                  </>
                )}
              </dl>
            </div>
          </div>
        </a>
      </article>
    );
  }
}

export default NewsCard;
