import React from "react";
import ReactDom from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import s from "./CarouselLightbox.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";

function LeftArrow({ onClick, ...rest }) {
  return (
    <button
      onClick={() => onClick()}
      className={classNames([
        "absolute left-0 flex-none block rounded-full bg-blue-600 ml-2 text-white flex items-center justify-center",
        s["arrow"],
        rest.className
      ])}
    >
      <FontAwesomeIcon size={"sm"} icon={faChevronLeft} />
    </button>
  );
}
function RightArrow({ onClick, ...rest }) {
  return (
    <button
      onClick={() => onClick()}
      className={classNames([
        "absolute right-0 flex-none block rounded-full bg-blue-600 mr-2 text-white flex items-center justify-center",
        s["arrow"],
        rest.className
      ])}
    >
      <FontAwesomeIcon size={"sm"} icon={faChevronRight} />
    </button>
  );
}
function Close(props) {
  return (
    <button
      className={classNames([
        "absolute right-0 top-0 p-4 text-white",
        props.className
      ])}
      onClick={props.onClick}
    >
      <FontAwesomeIcon size={"lg"} icon={faTimes} />
    </button>
  );
}

export default class CarouselLightbox extends React.PureComponent {
  static propTypes = {
    itemClass: PropTypes.string,
    items: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: this.props.index
    };

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }

  getSlide() {
    return this.props.items[this.state.currentIndex];
  }
  next() {
    this.setState(prevState => ({
      currentIndex: (prevState.currentIndex + 1) % this.props.items.length
    }));
  }
  prev() {
    this.setState(prevState => ({
      currentIndex:
        prevState.currentIndex === 0
          ? this.props.items.length - 1
          : prevState.currentIndex - 1
    }));
  }

  render() {
    if (typeof window === "undefined") {
      return <></>;
    }
    return ReactDom.createPortal(
      <div
        className={
          "fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center"
        }
      >
        <div
          className={
            "cursor-hand absolute top-0 left-0 w-full h-full bg-blue-900 bg-opacity-75 z-10"
          }
          onClick={this.props.onClose}
        ></div>
        <Close className={"z-30"} onClick={this.props.onClose} />

        {this.props.items.length > 1 && (
          <LeftArrow className={"z-30"} onClick={this.prev} />
        )}
        <div className={"z-20 flex justify-center items-center"}>
          {this.getSlide()}
        </div>
        {this.props.items.length > 1 && (
          <RightArrow className={"z-30"} onClick={this.next} />
        )}
      </div>,
      document.getElementById("portal")
    );
  }
}
