import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

class BannerTitle extends React.Component {
  static defaultProps = {};

  static propTypes = {};

  render() {
    return (
      <header className={"bg-gray-500 font-serif pt-3 pb-2 px-6"}>
        <h1>{this.props.children}</h1>
      </header>
    );
  }
}

export default BannerTitle;
