import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Stars from "../stars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as faHeartSolid,
  faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { connect } from "react-redux";
import Api from "../../services/api";
import {
  facilityLike,
  facilityDislike,
  updateGeneralError
} from "../../redux/reducers/map/actions";
import { showModal } from "../../redux/reducers/login/actions";
import ReadMore from "../read-more";
import ReviewList from "../review-list";
import facilityIcon from "./facility-icon.svg";
import s from "./FacilityCard.module.scss";

const glazingClassName = s["glazing"];
const highlightedClassName = "bg-gray-200";

export class FacilityCard extends React.PureComponent {
  static defaultProps = {
    data: {},
    link: {},
    loggedInUser: null,
    showReviews: false,
    showFewReviews: false,
    onMouseEnter: id => {},
    onMouseLeave: id => {},
    onClick: null
  };

  static propTypes = {
    className: PropTypes.any,
    data: PropTypes.object,
    link: PropTypes.object,
    loggedInUser: PropTypes.object,
    onReviewClick: PropTypes.func,
    showReviews: PropTypes.bool,
    showFewReviews: PropTypes.bool,
    readMoreLink: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.glaze !== this.props.glaze && this.props.glaze === true) {
      this.scrollIntoView();
    }
  }

  constructor(props) {
    super(props);

    this.like = this.like.bind(this);
    this.onReadMoreReviews = this.onReadMoreReviews.bind(this);
    this.showSignIn = this.showSignIn.bind(this);
    this.reviewClick = this.reviewClick.bind(this);
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.glaze === true) {
      this.scrollIntoView();
    }
  }
  scrollIntoView() {
    if (this.ref.current) {
      this.ref.current.scrollIntoView({
        alignToTop: true,
        behavior: "smooth"
      });
    }
  }

  onReadMoreReviews() {
    this.props.onReadMoreReviews();
  }

  showSignIn() {
    this.props.dispatchShowModal();
  }
  like() {
    if (this.props.loggedInUser === null) {
      return this.showSignIn();
    }

    Api.likeFacility(this.props.data.id)
      .then(() => this.props.dispatchLike(this.props.data.id))
      .catch(error => {
        this.props.dispatchUpdateGeneralError(error);
      });
  }
  dislike() {
    if (this.props.loggedInUser === null) {
      return this.showSignIn();
    }

    Api.dislikeFacility(this.props.data.id)
      .then(() => this.props.dispatchDislike(this.props.data.id))
      .catch(error => {
        this.props.dispatchUpdateGeneralError(error);
      });
  }

  userAlreadyReviewed() {
    if (this.props.loggedInUser === null) {
      return false;
    }
    let reviewed = false;
    this.props.data.relationships.reviews.forEach(review => {
      if (review.user.id === this.props.loggedInUser.id) {
        reviewed = true;
      }
    });
    return reviewed;
  }

  reviewClick() {
    if (this.props.loggedInUser === null) {
      return this.showSignIn();
    }

    this.props.onReviewClick();
  }

  render() {
    let baseClassName = "pt-4";

    if (this.props.link && (this.props.link.href || this.props.link.to)) {
      baseClassName += " cursor-pointer hover:bg-gray-200";
    }

    let details;

    if (this.props.data.attributes.specifications) {
      details = this.props.data.attributes.specifications.map(detail => {
        return [
          <li>
            {detail.label}: {detail.value}
          </li>
        ];
      });
      details = <ul className={"text-xs font-serif"}>{details}</ul>;
    }
    let reviews;
    if (
      this.props.data?.relationships?.reviews?.length > 0 &&
      this.props.showReviews
    ) {
      reviews = (
        <div className={"pt-4 px-6"}>
          <ReviewList
            data={this.props.data?.relationships?.reviews}
            total={this.props.data?.rating?.count}
            onEditClick={e => {
              e.preventDefault();
              this.reviewClick();
            }}
          />
        </div>
      );
      if (this.props.showFewReviews) {
        reviews = (
          <>
            <hr />
            <ReadMore
              readMoreLink={this.props.readMoreLink.to}
              className={"mb-4"}
            >
              {reviews}
            </ReadMore>
            <hr />
          </>
        );
      }
    }

    const card = (
      <article
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        ref={this.ref}
        className={classNames([
          this.props.className,
          baseClassName,
          this.props.glaze ? glazingClassName : null,
          this.props.highlighted ? highlightedClassName : null
        ])}
      >
        <div className={"w-full pl-6 pr-3 pb-4"}>
          <div className={"pr-4 relative"}>
            <span className={"absolute right-0"}>
              <FontAwesomeIcon
                className={"text-red-500"}
                onClick={e => {
                  e.preventDefault();

                  this.props.data.liked === true ? this.dislike() : this.like();
                }}
                icon={
                  this.props.data.liked === true ? faHeartSolid : faHeartOutline
                }
                title={"Save to my places"}
              />
            </span>
            <h3 className={"font-bold text-xl flex-full mb-2"}>
              {this.props.onClick !== null ? (
                <button
                  onClick={this.props.onClick}
                  className={"text-blue-700"}
                >
                  {this.props.data.name}
                  {console.log(this.props.onClick)}
                </button>
              ) : (
                this.props.data.name
              )}
            </h3>
          </div>
          <address
            className={"not-italic text-xs font-serif mb-2 flex items-center"}
          >
            <img
              src={facilityIcon}
              className={"inline align-middle mr-2"}
              width={"23px"}
              alt={""}
            />
            <a
              href={`https://map.what3words.com/${this.props.data.what3words}`}
              target={"_blank"}
              className={"text-blue-700"}
            >
              <span
                className={"text-base align-middle inline-block tracking-wider"}
              >
                ///
              </span>{" "}
              <span className={"align-middle"}>
                {this.props.data.what3words}
              </span>
            </a>
          </address>
          {details}
          <div className={"flex justify-between items-center mt-2 text-lg"}>
            {this.props.data.rating && (
              <>
                <Stars total={this.props.data.rating.total} />
                {!this.userAlreadyReviewed() && (
                  <>
                    <button
                      className={"text-blue-700 font-bold text-xs"}
                      onClick={e => {
                        e.preventDefault();
                        this.reviewClick();
                      }}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className={"mr-1"} />
                      Write a review
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {reviews}
      </article>
    );

    if (this.props.link && this.props.link.to) {
      return (
        <Link to={this.props.link.to} target={this.props.link.target}>
          {card}
        </Link>
      );
    } else if (this.props.link && this.props.link.href) {
      return (
        <a href={this.props.link.href} target={this.props.link.target}>
          {card}
        </a>
      );
    } else {
      return card;
    }
  }
}

export default connect(
  state => ({
    loggedInUser: state.login.loggedInUser
  }),
  dispatch => ({
    dispatchLike: payload => dispatch(facilityLike(payload)),
    dispatchDislike: payload => dispatch(facilityDislike(payload)),
    dispatchUpdateGeneralError: payload =>
      dispatch(updateGeneralError(payload)),
    dispatchShowModal: payload => dispatch(showModal(payload))
  })
)(FacilityCard);
