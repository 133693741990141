import React from "react";
import SocialSignIn from "../../components/social-sign-in";
import SignInFormComponent from "../../components/sign-in-form";
import { connect } from "react-redux";
import {
  showSignUpForm,
  showSignInForm,
  showPasswordRecoveryForm,
  passwordRecoveryAttempt,
  emailUpdated,
  signInAttempt,
  signedIn,
  signUpAttempt,
  updateSignInErrorMessage,
  updatePasswordRecoveryErrorMessage,
  updatePasswordRecoverySuccessMessage,
  updateSignUpErrorMessage,
  updateSignUpSuccessMessage
} from "../../redux/reducers/login/actions";
import PasswordRecoveryForm from "../../components/pasword-recovery-form";
import SignUpForm from "../../components/sign-up-form";
import Auth from "../../services/auth";

export class SignInForm extends React.Component {
  constructor(props) {
    super(props);
    this.googleClick = this.googleClick.bind(this);
    this.facebookClick = this.facebookClick.bind(this);
    this.linkedinClick = this.linkedinClick.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signUp = this.signUp.bind(this);
    this.recoverPassword = this.recoverPassword.bind(this);
  }

  googleClick() {
    Auth.googleSignIn();
  }
  facebookClick() {
    Auth.facebookSignIn();
  }
  linkedinClick() {
    Auth.linkedinSignIn();
  }
  signIn(data) {
    Auth.signIn(data)
      .then(answer => {
        if (answer.errors) {
          this.props.dispatchUpdateSignInErrorMessage(answer.errors[0]);
        } else {
          this.props.dispatchSignedIn(answer.data);
        }
      })
      .catch(e => {
        this.props.dispatchUpdateSignInErrorMessage(
          "Oops! There's been an error. Please try again."
        );
      });
  }
  signUp(data) {
    Auth.signUp(data)
      .then(answer => {
        if (answer.errors) {
          this.props.dispatchUpdateSignUpErrorMessage(answer.errors[0]);
        } else {
          this.props.dispatchUpdateSignUpSuccessMessage(answer.data.message);
        }
      })
      .catch(() => {
        this.props.dispatchUpdateSignUpErrorMessage(
          "Oops! There's been an error. Please try again."
        );
      });
  }

  recoverPassword(data) {
    Auth.recoverPassword(data)
      .then(answer => {
        if (answer.errors) {
          this.props.dispatchUpdatePasswordRecoveryErrorMessage(
            answer.errors[0]
          );
        } else {
          this.props.dispatchUpdatePasswordRecoverySuccessMessage(
            answer.data.message
          );
        }
      })
      .catch(() => {
        this.props.dispatchUpdatePasswordRecoveryErrorMessage(
          "Oops! There's been an error. Please try again."
        );
      });
  }

  render() {
    let form;
    let caption;

    switch (this.props.stage) {
      case "password-recovery":
        form = !this.props.passwordRecoverySuccessMessage ? (
          <PasswordRecoveryForm
            autocompletedEmail={this.props.email}
            recoverPassword={this.recoverPassword}
            onEmailUpdated={this.props.dispatchEmailUpdated}
            onCancel={this.props.dispatchShowSignInForm}
            disabled={this.props.loading}
            errorMessage={this.props.passwordRecoveryError}
            recaptchaKey={Auth.getCaptchaKey()}
            captchaChallengeFails={() =>
              this.props.dispatchUpdatePasswordRecoveryErrorMessage(
                "Captcha Failed"
              )
            }
            captchaChallengeStarts={this.props.dispatchPasswordRecoveryAttempt}
          />
        ) : (
          <p className={"text-sm text-blue-700"}>
            {this.props.passwordRecoverySuccessMessage}
          </p>
        );
        break;
      case "sign-up":
        form = !this.props.signUpSuccessMessage ? (
          <SignUpForm
            autocompletedEmail={this.props.email}
            onEmailUpdated={this.props.dispatchEmailUpdated}
            captchaChallengeStarts={this.props.dispatchSignUpAttempt}
            captchaChallengeFails={() =>
              this.props.dispatchUpdateSignUpErrorMessage("Captcha Failed")
            }
            recaptchaKey={Auth.getCaptchaKey()}
            signUp={this.signUp}
            disabled={this.props.loading}
            errorMessage={this.props.signUpError}
          />
        ) : (
          <p className={"text-sm text-blue-700"}>
            {this.props.signUpSuccessMessage}
          </p>
        );
        caption = (
          <>
            <span className={"text-sm font-serif"}>
              Do you already have an account?{" "}
            </span>
            <button
              className={"text-sm text-blue-700"}
              onClick={e => {
                e.preventDefault();
                this.props.dispatchShowSignInForm();
              }}
              tabIndex={19}
            >
              Sign In Now
            </button>
          </>
        );
        break;
      case "sign-in":
      default:
        form = (
          <SignInFormComponent
            recoverPassword={this.props.dispatchShowPasswordRecoveryForm}
            captchaChallengeStarts={this.props.dispatchSignInAttempt}
            captchaChallengeFails={() =>
              this.props.dispatchUpdateSignInErrorMessage("Captcha Failed")
            }
            signIn={this.signIn}
            autocompletedEmail={this.props.email}
            onEmailUpdated={this.props.dispatchEmailUpdated}
            disabled={this.props.loading}
            errorMessage={this.props.signInError}
            recaptchaKey={Auth.getCaptchaKey()}
          />
        );
        caption = (
          <>
            <span className={"text-sm font-serif"}>
              Don't have an account yet?{" "}
            </span>
            <button
              className={"text-sm text-blue-700"}
              onClick={e => {
                e.preventDefault();
                this.props.dispatchShowSignUpForm();
              }}
              tabIndex={19}
            >
              Sign Up Now
            </button>
          </>
        );
        break;
    }

    return (
      <>
        <div className={"flex w-full justify-center align-stretch"}>
          <div className={"flex items-center justify-center px-6"}>
            <SocialSignIn
              onGoogleClick={this.googleClick}
              onFacebookClick={this.facebookClick}
              onLinkedinClick={this.linkedinClick}
            />
          </div>
          <div className={"flex flex-col px-6"}>
            <form>{form}</form>
          </div>
        </div>
        <div className={"pt-6 text-center"}>{caption}</div>
      </>
    );
  }
}

export default connect(
  state => ({
    email: state.login.email,
    stage: state.login.stage,
    loading: state.login.loading,
    signInError: state.login.signInError,
    signUpError: state.login.signUpError,
    passwordRecoveryError: state.login.passwordRecoveryError,
    passwordRecoverySuccessMessage: state.login.passwordRecoverySuccessMessage,
    signUpSuccessMessage: state.login.signUpSuccessMessage
  }),
  dispatch => ({
    dispatchShowPasswordRecoveryForm: payload =>
      dispatch(showPasswordRecoveryForm(payload)),
    dispatchPasswordRecoveryAttempt: payload =>
      dispatch(passwordRecoveryAttempt(payload)),
    dispatchEmailUpdated: payload => dispatch(emailUpdated(payload)),
    dispatchShowSignInForm: payload => dispatch(showSignInForm(payload)),
    dispatchShowSignUpForm: payload => dispatch(showSignUpForm(payload)),
    dispatchSignInAttempt: payload => dispatch(signInAttempt(payload)),
    dispatchSignUpAttempt: payload => dispatch(signUpAttempt(payload)),
    dispatchSignedIn: payload => dispatch(signedIn(payload)),
    dispatchUpdateSignInErrorMessage: payload =>
      dispatch(updateSignInErrorMessage(payload)),
    dispatchUpdateSignUpErrorMessage: payload =>
      dispatch(updateSignUpErrorMessage(payload)),
    dispatchUpdateSignUpSuccessMessage: payload =>
      dispatch(updateSignUpSuccessMessage(payload)),
    dispatchUpdatePasswordRecoveryErrorMessage: payload =>
      dispatch(updatePasswordRecoveryErrorMessage(payload)),
    dispatchUpdatePasswordRecoverySuccessMessage: payload =>
      dispatch(updatePasswordRecoverySuccessMessage(payload))
  })
)(SignInForm);
