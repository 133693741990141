import React from "react";
import MapRouter from "./pages/map";
import Verified from "./pages/verified";
import VerificationFailed from "./pages/verification_failed";
import PasswordResetInvalid from "./pages/password-reset-invalid";
import PasswordReset from "./pages/password-reset";
import { Route, Switch } from "react-router";
import Home from "./pages/home-page";
import Search from "./pages/search";
import "./global.scss";
import Stories from "./pages/stories";

// import whyDidYouRender from "@welldone-software/why-did-you-render";
// whyDidYouRender(React, {
//   trackAllPureComponents: true
// });
function App() {
  return (
    <Switch>
      <Route path={"/map"} component={MapRouter} />

      <Route path={"/search/map/:query"} component={Search} />
      <Route path={"/search/news/:query"} component={Search} />
      <Route path={"/search/stories/:query"} component={Search} />
      <Route path={"/search/:query"} component={Search} />

      <Route path={"/verified"} exact={true} component={Verified} />
      <Route
        path={"/verification_failed"}
        exact={true}
        component={VerificationFailed}
      />

      <Route
        path={"/password_reset_invalid"}
        exact={true}
        component={PasswordResetInvalid}
      />

      <Route path={"/password_reset/:token/:email"} component={PasswordReset} />

      <Route
        path={"/password_reset"}
        exact={true}
        component={PasswordResetInvalid}
      />

      <Route path={"/stories"} exact={true} component={Stories} />
      <Route path="/" component={props => <Home path={props.match.path} />} />
    </Switch>
  );
}

export default App;
