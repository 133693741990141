import React from "react";
import LandingPage from "../../layouts/landing-page";
import FullScreenMessage from "../../components/full-screen-message";

class Stories extends React.Component {
  render() {
    return (
      <LandingPage>
        <span aria-hidden={true} data-testid={"page-stories"} />
        <FullScreenMessage title={"Stories"}>
          This feature is not ready yet.
          <br />
          Please come back in the following days.
        </FullScreenMessage>
      </LandingPage>
    );
  }
}

export default Stories;
