import React from "react";

class FilterBar extends React.Component {
  static defaultProps = {};

  static propTypes = {};

  render() {
    return (
      <div className={"w-full px-6 py-2 border-b"}>{this.props.children}</div>
    );
  }
}

export default FilterBar;
