import React from "react";
import FacilityIcon from "../../components/facility-icon";
import Stars from "../../components/stars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faShower } from "@fortawesome/free-solid-svg-icons";
import Carousel from "../../components/carousel";
import GoBackContainer from "../../components/go-back-container";
import { connect } from "react-redux";
import LoadingComponent from "../../components/loading-component";
import Modal from "../../components/modal";
import ReviewFacilityForm from "../review-facility-form";
import {
  closeReviewModal,
  openReviewModal,
  stadiumFetchError,
  closeSidebarStadium,
  stadiumMouseEnter,
  stadiumMouseLeave
} from "../../redux/reducers/map/actions";
import ErrorMessage from "../../components/error-message";
import FacilityList from "../../components/facility-list";
import kebabCase from "lodash/kebabCase";
import s from "./SidebarStadium.module.scss";
import CarouselLightbox from "../../components/carousel-lightbox";
import classNames from "classnames";

class SidebarStadium extends React.Component {
  static $props = {
    stadium: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      showReviewModal: props.showReviewModal,
      expandedGroup: null,
      storiesModalOpen: false
    };
    this.closeReviewModal = this.closeReviewModal.bind(this);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.openFacility = this.openFacility.bind(this);
    this.onStoryClick = this.onStoryClick.bind(this);
    this.closeStoryModal = this.closeStoryModal.bind(this);
  }

  onMouseEnter(id) {
    this.props.dispatchMouseEnter(id);
  }
  onMouseLeave(id) {
    this.props.dispatchMouseLeave(id);
  }
  onStoryClick(index) {
    this.setState({
      storiesModalOpen: true,
      modalIndex: index
    });
  }
  closeStoryModal() {
    this.setState({
      storiesModalOpen: false
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.triggerOpenFacility !== this.props.triggerOpenFacility &&
      this.props.triggerOpenFacility !== null
    ) {
      if (this.props.stadium.relationships) {
        const targetFacility = this.props.stadium.relationships.facilities.filter(
          f => f.id == this.props.triggerOpenFacility.id
        );
        if (targetFacility && targetFacility.length > 0) {
          this.triggerFacilityGroupExpansion(targetFacility[0].type, false);
        } else {
          console.warn(
            "Couldn't find the facility " +
              this.props.triggerOpenFacility.id +
              " in the current stadium"
          );
        }
      }
    }
  }
  openFacility(facility) {
    this.triggerFacilityGroupExpansion(facility.type, false);
  }

  triggerFacilityGroupExpansion(type, glaze = true) {
    let kebabCaseType = kebabCase(type);
    const index = this.props.stadium.attributes.facilities.indexOf(type);
    if (index !== false) {
      this.setState({ expandedGroup: { index: null, glaze: null } }, () => {
        this.setState({
          expandedGroup: { index, glaze }
        });
      });
    }
  }
  closeReviewModal() {
    this.props.dispatchCloseReviewModal();
  }

  componentWillUnmount() {
    this.props.dispatchCloseSidebarStadium();
  }

  /** @todo if I come from search results don't go back to map */
  render() {
    if (this.props.error) {
      return (
        <ErrorMessage className={"px-6 py-3"}>{this.props.error}</ErrorMessage>
      );
    }

    if (!this.props.stadium.id) {
      return <LoadingComponent />;
    }

    let stories = this.props.stadium?.relationships?.stories;
    // Mock data
    if (!stories || stories.count === 0) {
      stories = {
        items: [
          {
            id: 1,
            picture: {
              srcset:
                "https://nalathletics.com/blog/assets/img/harvey-hadden-athletics-stadium.jpg"
            },
            thumbnail: {
              srcset:
                "https://nalathletics.com/blog/assets/img/harvey-hadden-athletics-stadium.jpg"
            }
          }
        ],
        count: 1
      };
    }

    let amenities = this.props.stadium?.attributes?.amenities;
    if (!amenities || amenities.length == 0) {
      amenities = [
        { name: "Lights", value: "Yes" },
        { name: "Bathroom", value: "3" }
      ];
    }

    return (
      <>
        <GoBackContainer>
          <article>
            <header className={"w-full px-6 pt-3 pb-4"}>
              <h1 className={"font-semi-bold text-2xl"}>
                {this.props.stadium.name}
              </h1>
              <div className={"flex w-full justify-between pt-1"}>
                <ul className={"flex mt-1 pt-0.5"}>
                  {this.props.stadium.attributes.facilities &&
                    this.props.stadium.attributes.facilities.map(type => (
                      <FacilityIcon
                        key={type}
                        onClick={() => this.triggerFacilityGroupExpansion(type)}
                        icon={type}
                        className={
                          "cursor-pointer mx-1 px-0.5 first:ml-0 last:mr-0 first:pl-0 last:pr-0"
                        }
                      />
                    ))}
                </ul>

                <div className={"flex-none flex items-center"}>
                  {this.props.stadium.rating && (
                    <Stars
                      count={this.props.stadium.rating.count}
                      total={this.props.stadium.rating.total}
                      size={"lg"}
                    />
                  )}
                </div>
              </div>
            </header>
            <hr />
            <ul className={"py-3 px-6"}>
              <li>
                <address className={"not-italic text-xs mt-1 font-serif"}>
                  <span className={"mr-3 text-blue-800 w-2 inline-block"}>
                    <FontAwesomeIcon size={"lg"} icon={faMapMarkerAlt} />
                  </span>
                  {this.props.stadium.address}
                </address>
              </li>
              {amenities && amenities.length > 0 && (
                <li className={"not-italic text-xs mt-2 font-serif"}>
                  <span className={"mr-3 text-blue-800 w-2 inline-block"}>
                    <FontAwesomeIcon size={"lg"} icon={faShower} />
                  </span>
                  <dl className={"inline"}>
                    {amenities.map(amenity => (
                      <React.Fragment key={amenity.name}>
                        <dt className={"inline"} key={`dt${amenity.name}`}>
                          {amenity.name}:{" "}
                        </dt>
                        <dd
                          className={"inline font-bold mr-3 last:mr-0"}
                          key={`dd${amenity.name}`}
                        >
                          {amenity.value}
                        </dd>
                      </React.Fragment>
                    ))}
                  </dl>
                </li>
              )}
            </ul>
            {stories && stories.count > 0 && (
              <div className={"pl-6 pt-2 pb-4"}>
                <h2 className={"text-sm font-bold mb-2"}>
                  Stories ({stories.count})
                </h2>
                <Carousel
                  containerClass={s["carousel-container"]}
                  items={stories.items.map(story => (
                    <button
                      className={classNames([
                        s["story-thumb"],
                        "cursor-hand inline-block relative"
                      ])}
                      onClick={() =>
                        this.onStoryClick(stories.items.indexOf(story))
                      }
                    >
                      <picture key={story.id}>
                        <source
                          // key={story.picture[mimeType]["srcset"]}
                          srcSet={`${story.thumbnail.srcset}`}
                          // type={mimeType}
                        />
                        <img
                          alt={`${this.props.stadium.name}`}
                          srcSet={story.thumbnail.srcset}
                        />
                      </picture>
                    </button>
                  ))}
                />
                {this.state.storiesModalOpen === true && (
                  <CarouselLightbox
                    onClose={this.closeStoryModal}
                    index={this.state.modalIndex}
                    items={stories.items.map(story => (
                      <picture key={story.id}>
                        <source
                          // key={story.picture[mimeType]["srcset"]}
                          srcSet={`${story.picture.srcset}`}
                          // type={mimeType}
                        />
                        <img
                          className={"max-h-screen fit-contain"}
                          alt={`${this.props.stadium.name}`}
                          srcSet={story.picture.srcset}
                        />
                      </picture>
                    ))}
                  />
                )}
              </div>
            )}
            <FacilityList
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              openFacility={this.openFacility}
              expandedGroup={this.state.expandedGroup}
              triggerOpenFacility={this.props.triggerOpenFacility}
              stadium={this.props.stadium}
              highlighted={this.props.highlightedMarker}
            />
          </article>

          {this.props.showReviewModal && (
            <Modal
              title={`${this.props.stadium.name} - ${this.props.reviewModalTitle}`}
              onClose={this.closeReviewModal}
            >
              <ReviewFacilityForm />
            </Modal>
          )}
        </GoBackContainer>
      </>
    );
  }
}

export default connect(
  state => ({
    stadium: state.map.stadiumMode.stadium,
    showReviewModal: state.map.reviewForm.showModal,
    reviewModalTitle: state.map.reviewForm.modalTitle,
    reviewFacilityId: state.map.reviewForm.facilityId,
    triggerOpenFacility: state.map.stadiumMode.openedFacility,
    highlightedMarker: state.map.mapAreaTrigger.highlightedMarker,
    error: state.map.stadiumMode.error
  }),
  dispatch => ({
    dispatchOpenReviewModal: payload => dispatch(openReviewModal(payload)),
    dispatchCloseReviewModal: payload => dispatch(closeReviewModal(payload)),
    dispatchCloseSidebarStadium: payload =>
      dispatch(closeSidebarStadium(payload)),
    dispatchStadiumFetchError: payload => dispatch(stadiumFetchError(payload)),
    dispatchMouseEnter: payload => dispatch(stadiumMouseEnter(payload)),
    dispatchMouseLeave: payload => dispatch(stadiumMouseLeave(payload))
  })
)(SidebarStadium);
