export function getUserLocation() {
  if (typeof window !== "undefined") {
    const value = window.localStorage.getItem("user-location");
    return value ? JSON.parse(value) : null;
  }
}

export function saveUserLocation(location, source) {
  if (typeof window !== "undefined") {
    // We don't overwrite browser geolocation with api geolocation
    if (
      source === "api" &&
      window.localStorage.getItem("user-location-source") === "geo"
    ) {
      return;
    }

    // Save on local storage
    window.localStorage.setItem("user-location", JSON.stringify(location));
    window.localStorage.setItem("user-location-source", source);
  }
}
