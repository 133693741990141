import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const baseTabClass = "px-6 py-4 -mb-px block";
const activeTabClass = "border-b-2 border-blue-700 box-border";
const regularTabClass = "text-blue-700 cursor-pointer";

class TabContainerFs extends React.Component {
  static defaultProps = {};

  static propTypes = {
    tabs: PropTypes.array.isRequired,
    matchPath: PropTypes.func.isRequired,
    url: PropTypes.func.isRequired
  };

  render() {
    let contents = {};
    let activeTab;

    this.props.tabs.forEach(t => {
      contents[t.key] = t.content;

      t.paths.forEach(path => {
        if (this.props.matchPath(path)) {
          activeTab = t.key;
        }
      });
    });

    let tabs = [];
    tabs = this.props.tabs.map(t => {
      const isActive = activeTab === t.key;
      const Tag = isActive ? "h2" : "a";
      return (
        <nav key={t.key}>
          <Tag
            href={this.props.url(t.paths)}
            className={classNames([
              baseTabClass,
              isActive ? activeTabClass : regularTabClass
            ])}
          >
            {t.label}
          </Tag>
        </nav>
      );
    });

    return (
      <>
        <div className={"flex border-b"}>{tabs}</div>

        {contents[activeTab]}
      </>
    );
  }
}

export default TabContainerFs;
