import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const icons = {
  throw: require("./static/icon_throw.svg"),
  run: require("./static/icon_run.svg"),
  jump: require("./static/icon_jump.svg")
};
const blueIcons = {
  throw: require("./static/icon_throw_blue.svg"),
  run: require("./static/icon_run_blue.svg"),
  jump: require("./static/icon_jump_blue.svg")
};

const titles = {
  throw: "Throw field available",
  run: "Running track available",
  jump: "Jump pit available"
};

class FacilityIcon extends React.PureComponent {
  static defaultProps = {};
  static propTypes = {
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func
  };

  render() {
    return (
      <img
        onClick={this.props.onClick}
        className={classNames([this.props.className, "h-4"])}
        alt={this.props.icon}
        src={
          this.props.onClick
            ? blueIcons[this.props.icon.toLowerCase()]
            : icons[this.props.icon.toLowerCase()]
        }
        title={titles[this.props.icon.toLowerCase()]}
      />
    );
  }
}

export default FacilityIcon;
