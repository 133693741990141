import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import history from "./redux/history";
import { BootstrapLogIn } from "./services/auth";
// import * as Sentry from "@sentry/browser";

// Sentry.init({
//   dsn:
//     "https://830a195094874b31aad4ab1da0d8ca03@o397308.ingest.sentry.io/5251659"
// });
ReactDOM.hydrate(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <BootstrapLogIn />
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
