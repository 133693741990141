import React from "react";
import PropTypes from "prop-types";
import ErrorMessage from "../error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faHeart,
  faCity,
  faSearch,
  faMapMarkerAlt,
  faStreetView
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ThreeDots from "../three-dots";

class SearchResultsDropdownList extends React.Component {
  static defaultProps = {
    onClick: () => {}
  };

  static propTypes = {
    results: PropTypes.object.isRequired,
    searching: PropTypes.bool,
    query: PropTypes.string.isRequired,
    onClick: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.renderHistoryResult = this.renderHistoryResult.bind(this);
    this.renderMyPlacesResult = this.renderMyPlacesResult.bind(this);
    this.renderSuggestionsResult = this.renderSuggestionsResult.bind(this);
    this.renderStadiumsResult = this.renderStadiumsResult.bind(this);
    this.renderCities = this.renderCities.bind(this);
  }

  renderCities(result) {
    const fullName = result.name + ", " + result.state_code;
    return (
      <Link
        className={"cursor-pointer block w-full px-3 py-2"}
        to={`/map/city/${encodeURIComponent(
          result.state_code
        )}/${encodeURIComponent(result.name)}/`}
      >
        <FontAwesomeIcon icon={faCity} className={"mr-2"} />
        <span
          dangerouslySetInnerHTML={{
            __html: this.highlightQueryString(fullName)
          }}
        />
      </Link>
    );
  }
  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  highlightQueryString(label) {
    const regex = new RegExp(
      "(" + this.escapeRegExp(this.props.query) + ")",
      "i"
    );
    return label.replace(regex, `<mark>$1</mark>`);
  }

  renderHistoryResult(result) {
    return (
      <Link
        className={"cursor-pointer block w-full px-3 py-2"}
        to={`/map/search/${encodeURIComponent(result)}`}
      >
        <FontAwesomeIcon icon={faClock} className={"mr-2"} />
        <span
          dangerouslySetInnerHTML={{
            __html: this.highlightQueryString(result)
          }}
        />
      </Link>
    );
  }
  renderMyPlacesResult(result) {
    return (
      <Link
        className={"cursor-pointer block w-full px-3 py-2"}
        to={`/map/stadium/${encodeURIComponent(result.slug)}`}
      >
        <FontAwesomeIcon icon={faHeart} className={"mr-2"} />
        <span
          dangerouslySetInnerHTML={{
            __html: this.highlightQueryString(result.name)
          }}
        />
      </Link>
    );
  }
  renderSuggestionsResult(result) {
    return (
      <Link
        className={"cursor-pointer block w-full px-3 py-2"}
        to={`/map/search/${encodeURIComponent(result)}`}
      >
        <FontAwesomeIcon icon={faSearch} className={"mr-2"} />
        <span>Search {result}</span>
      </Link>
    );
  }
  renderNearMeResult(result) {
    return (
      <Link
        className={"cursor-pointer block w-full px-3 py-2"}
        to={`/map/search/${encodeURIComponent(result)}`}
      >
        <FontAwesomeIcon icon={faStreetView} className={"mr-2"} />
        <span>{result} near me</span>
      </Link>
    );
  }
  renderStadiumsResult(result) {
    return (
      <Link
        className={"cursor-pointer block w-full px-3 py-2"}
        to={`/map/stadium/${encodeURIComponent(result.slug)}`}
      >
        <FontAwesomeIcon icon={faMapMarkerAlt} className={"mr-2"} />
        <span
          dangerouslySetInnerHTML={{
            __html: this.highlightQueryString(result.name)
          }}
        />
      </Link>
    );
  }

  render() {
    if (this.props.error !== null) {
      return <ErrorMessage className={"p-2"}>{this.props.error}</ErrorMessage>;
    }

    let render = [];

    let searchResults = [];

    if (this.props.results.history !== null) {
      searchResults.push(
        this.props.results.history.map(this.renderHistoryResult)
      );
    }

    if (this.props.results.myPlaces !== null) {
      searchResults.push(
        this.props.results.myPlaces.map(this.renderMyPlacesResult)
      );
    }
    if (this.props.results.stadiums !== null) {
      searchResults.push(
        this.props.results.stadiums.map(this.renderStadiumsResult)
      );
    }

    if (this.props.results.cities !== null) {
      searchResults.push(this.props.results.cities.map(this.renderCities));
    }

    if (this.props.results.near_me) {
      searchResults.push(
        this.props.results.near_me.map(this.renderNearMeResult)
      );
    }
    if (this.props.results.suggestions !== null) {
      searchResults.push(
        this.props.results.suggestions.map(this.renderSuggestionsResult)
      );
    }

    render.push(
      searchResults.map(results =>
        results.map(result => (
          <div
            className={"hover:bg-blue-100 cursor-pointer text-gray-600"}
            onClick={this.props.onClick}
          >
            {result}
          </div>
        ))
      )
    );

    if (
      this.props.query &&
      this.props.query.length > 3 &&
      (this.props.results.suggestions === null ||
        this.props.results.myPlaces === null ||
        this.props.results.stadiums === null)
    ) {
      render.push(
        <div
          className={
            "pb-2 text-center flex justify-center items-center text-gray-600"
          }
        >
          <ThreeDots />
        </div>
      );
    }
    return render;
  }
}

export default SearchResultsDropdownList;
