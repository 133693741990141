import React from "react";
import GoBackContainer from "../../components/go-back-container";
import FacilityCard from "../../components/facility-card";
import { connect } from "react-redux";
import LoadingComponent from "../../components/loading-component";
import { withRouter } from "react-router";
import {
  closeReviewModal,
  openReviewModal,
  closeSidebarFacility
} from "../../redux/reducers/map/actions";
import Modal from "../../components/modal";
import ReviewFacilityForm from "../review-facility-form";
import ErrorMessage from "../../components/error-message";

class SidebarFacility extends React.Component {
  static $props = {
    facility: {}
  };

  constructor(props) {
    super(props);
    this.closeReviewModal = this.closeReviewModal.bind(this);
  }

  closeReviewModal() {
    this.props.dispatchCloseReviewModal();
  }

  componentWillUnmount() {
    this.props.dispatchCloseSidebarFacility();
  }

  render() {
    if (this.props.error) {
      return (
        <ErrorMessage className={"px-5 py-3"}>{this.props.error}</ErrorMessage>
      );
    }

    if (!this.props.facility.id) {
      return <LoadingComponent />;
    }

    return (
      <>
        <GoBackContainer>
          <FacilityCard
            showReviews={true}
            data={this.props.facility}
            onReviewClick={() =>
              this.props.dispatchOpenReviewModal(this.props.facility)
            }
          />
          {this.props.showReviewModal && (
            <Modal
              title={`${this.props.reviewModalTitle}`}
              onClose={this.closeReviewModal}
            >
              <ReviewFacilityForm />
            </Modal>
          )}
        </GoBackContainer>
      </>
    );
  }
}

export default connect(
  state => ({
    facility: state.map.facilityMode.facility,
    showReviewModal: state.map.reviewForm.showModal,
    reviewModalTitle: state.map.reviewForm.modalTitle,
    error: state.map.facilityMode.error
  }),
  dispatch => ({
    dispatchOpenReviewModal: payload => dispatch(openReviewModal(payload)),
    dispatchCloseReviewModal: payload => dispatch(closeReviewModal(payload)),
    dispatchCloseSidebarFacility: payload =>
      dispatch(closeSidebarFacility(payload))
  })
)(withRouter(SidebarFacility));
