import React from "react";

function tick(dots) {
  switch (dots) {
    case ".":
      return "..";
    case "...":
      return ".";
    case "..":
    default:
      return "...";
  }
}

class ThreeDots extends React.Component {
  static defaultProps = {};

  static propTypes = {};

  constructor(props) {
    super(props);
    this.state = {
      dots: "."
    };
    this.interval = null;
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(prevState => ({
        dots: tick(prevState.dots)
      }));
    }, 300);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return <>{this.state.dots}</>;
  }
}

export default ThreeDots;
