import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Captcha from "../captcha";

const baseButtonClass = "text-white rounded-md py-2 mt-3";
const activeButtonClass = "bg-blue-700";
const disabledButtonClass = "bg-blue-200";

const emailValidationRule = /^[\w.\-_]+?@\w+\.[^$]/;

export default class SignUpForm extends React.Component {
  static defaultProps = {
    signUp: () => {},
    onEmailUpdated: email => {},
    autocompletedEmail: "",
    errorMessage: "",
    disabled: false
  };
  static propTypes = {
    signUp: PropTypes.func.isRequired,
    onEmailUpdated: PropTypes.func.isRequired,
    autocompletedEmail: PropTypes.string,
    errorMessage: PropTypes.string,
    recaptchaKey: PropTypes.string.isRequired,
    captchaChallengeFails: PropTypes.func.isRequired,
    captchaChallengeStarts: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      email: "",
      password: "",
      passwordHint: null,
      emailHint: null,
      showPasswordHint: false,
      showEmailHint: false
    };
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.signUp = this.signUp.bind(this);
    this.continueSigningUp = this.continueSigningUp.bind(this);
    this.showPasswordHint = this.showPasswordHint.bind(this);
    this.hidePassworodHint = this.hidePassworodHint.bind(this);
    this.showEmailHint = this.showEmailHint.bind(this);
    this.hideEmailHint = this.hideEmailHint.bind(this);
    this.refEmail = React.createRef();
    this.refCaptcha = React.createRef();
    this.captchaStarted = false;
  }
  setEmail(email) {
    const colorClass = email.match(emailValidationRule)
      ? "text-blue-700"
      : "text-red-500";
    this.setState({
      emailHint: (
        <span className={classNames([colorClass, "text-xs"])}>
          Must be a valid email address.
        </span>
      ),
      email
    });
    this.props.onEmailUpdated(email);
  }
  setName(name) {
    this.setState({ name });
  }
  setLastName(lastName) {
    this.setState({ lastName });
  }
  setPassword(password) {
    const colorClass = password.length >= 8 ? "text-blue-700" : "text-red-500";
    this.setState({
      passwordHint: (
        <span className={classNames([colorClass, "text-xs"])}>
          At least 8 characters
        </span>
      ),
      password
    });
  }
  showPasswordHint() {
    this.setState({
      showPasswordHint: true
    });
  }
  hidePassworodHint() {
    this.setState({
      showPasswordHint: false
    });
  }
  showEmailHint() {
    this.setState({
      showEmailHint: true
    });
  }
  hideEmailHint() {
    this.setState({
      showEmailHint: false
    });
  }

  componentDidMount() {
    this.setState({ email: this.props.autocompletedEmail });

    if (this.refEmail.current) {
      this.refEmail.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const disabled =
      !this.state.email ||
      !this.state.password ||
      this.state.password.length < 8 ||
      !this.state.email.match(emailValidationRule);

    if (
      (prevState.disabled && !disabled) ||
      (!prevState.disabled && disabled)
    ) {
      this.setState({ disabled });
    }

    if (
      prevProps.errorMessage !== this.props.errorMessage &&
      this.props.errorMessage &&
      this.refCaptcha.current
    ) {
      this.failedSigningUp();
    }
  }
  failedSigningUp() {
    this.refCaptcha.current.reset();
  }

  continueSigningUp(token) {
    this.props.signUp({
      email: this.state.email,
      name: this.state.name,
      last_name: this.state.lastName,
      password: this.state.password,
      "g-recaptcha-response": token
    });
  }
  signUp() {
    this.refCaptcha.current.execute();
    this.props.captchaChallengeStarts();
  }

  render() {
    return (
      <div className={"flex flex-col px-6"}>
        {this.props.errorMessage && (
          <p className={"text-red-500 text-sm mb-1"}>
            {this.props.errorMessage}
          </p>
        )}
        <input
          placeholder={"Email"}
          onChange={e => this.setEmail(e.target.value)}
          type={"email"}
          value={this.state.email}
          ref={this.refEmail}
          className={"border border-gray-200 rounded-md py-1 px-2"}
          tabIndex={10}
          disabled={this.props.disabled}
          onFocus={this.showEmailHint}
          onBlur={this.hideEmailHint}
        />
        {this.state.showEmailHint && this.state.emailHint}
        <input
          placeholder={"First Name"}
          onChange={e => this.setName(e.target.value)}
          type={"text"}
          className={"border border-gray-200 rounded-md py-1 px-2 mt-3"}
          tabIndex={11}
          disabled={this.props.disabled}
        />
        <input
          placeholder={"Last Name"}
          onChange={e => this.setLastName(e.target.value)}
          type={"text"}
          className={"border border-gray-200 rounded-md py-1 px-2 mt-3"}
          tabIndex={12}
          disabled={this.props.disabled}
        />
        <input
          placeholder={"Password"}
          onChange={e => this.setPassword(e.target.value)}
          type={"password"}
          className={"border border-gray-200 rounded-md py-1 px-2 mt-3"}
          tabIndex={13}
          disabled={this.props.disabled}
          onFocus={this.showPasswordHint}
          onBlur={this.hidePassworodHint}
        />
        {this.state.showPasswordHint && this.state.passwordHint}
        <button
          type={"submit"}
          onClick={e => {
            e.preventDefault();
            this.signUp();
          }}
          disabled={this.state.disabled || this.props.disabled}
          className={classNames([
            baseButtonClass,
            this.state.disabled || this.props.disabled
              ? disabledButtonClass
              : activeButtonClass
          ])}
          tabIndex={14}
        >
          Sign Up
        </button>
        <Captcha
          recaptchaRef={this.refCaptcha}
          recaptchaKey={this.props.recaptchaKey}
          onErrored={this.props.captchaChallengeFails}
          onChange={this.continueSigningUp}
        />
      </div>
    );
  }
}
