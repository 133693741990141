import React from "react";
import { Redirect } from "react-router";
import LandingPage from "../../layouts/landing-page";
import InternalPage from "../../layouts/map-page";

class Home extends React.Component {
  render() {
    return (
      <LandingPage>
        <span aria-hidden={true} data-testid={"page-home"} />
        <Redirect to="/map" />
      </LandingPage>
    );
  }
}

export default Home;
