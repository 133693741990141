import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import s from "./SocialSignIn.module.scss";

export default class SocialSignIn extends React.PureComponent {
  static propTypes = {
    onLinkedinClick: PropTypes.func.isRequired,
    onFacebookClick: PropTypes.func.isRequired,
    onGoogleClick: PropTypes.func.isRequired
  };

  render() {
    return (
      <ul className={"flex"}>
        <li className={"mr-6"}>
          <button
            disabled={"disabled"}
            className={ClassNames([
              s["icon"],
              "cursor-default inline-block rounded-full bg-gray-400 text-white" //blue-300
            ])}
            title={"Not available at the moment"}
            onClick={e => {
              e.preventDefault();
              this.props.onLinkedinClick();
            }}
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </button>
        </li>
        <li className={"mr-6"}>
          <button
            onClick={e => {
              e.preventDefault();
              this.props.onFacebookClick();
            }}
            className={ClassNames([
              s["icon"],
              "inline-block rounded-full bg-blue-800 text-white"
            ])}
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </button>
        </li>
        <li>
          <button
            className={ClassNames([
              s["icon"],
              "inline-block rounded-full bg-red-500 text-white"
            ])}
            onClick={e => {
              e.preventDefault();
              this.props.onGoogleClick();
            }}
          >
            <FontAwesomeIcon icon={faGoogle} />
          </button>
        </li>
      </ul>
    );
  }
}
