import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from "prop-types";

export default class Captcha extends React.PureComponent {
  static propTypes = {
    recaptchaRef: PropTypes.object.isRequired,
    recaptchaKey: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onErrored: PropTypes.func.isRequired
  };
  render() {
    return (
      <ReCAPTCHA
        ref={this.props.recaptchaRef}
        size="invisible"
        sitekey={this.props.recaptchaKey}
        onChange={this.props.onChange}
        onErrored={this.props.onErrored}
      />
    );
  }
}
