import React from "react";
import MainSearchBar from "../../components/main-search-bar";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  startSearch,
  closeSearch,
  openSearch,
  cancelSearch,
  searchFetchError,
  updateSearchResults,
  commitSearch,
  updateHistorySearchResults,
  endSearch
} from "../../redux/reducers/map/actions";
import SearchResultsDropdownList from "../../components/search-results-dropdown-list";
import DropdownSearcher from "../../services/dropdown-searcher";
import s from "./SidebarHeader.module.scss";
import throttle from "lodash/throttle";

export class SidebarHeader extends React.PureComponent {
  static defaultProps = {
    searcherInitialValue: null
  };
  static propTypes = {
    searcherInitialValue: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.throttledSearch = null;
    this.q = null;

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.shouldSearchDropdownBeOpen = this.shouldSearchDropdownBeOpen.bind(
      this
    );
    this._apiSearchs = this._apiSearchs.bind(this);
  }
  onSubmit(q) {
    this.props.history.push(`/map/search/${q}`);
    this.props.dispatchCommitSearch(q);
  }

  _apiSearchs() {
    const q = this.q;
    // Search using the current map coordinates or the user location as fallback
    let searchArea;
    if (
      this.props.mapArea.center !== null &&
      this.props.mapArea.center.lat !== null
    ) {
      searchArea = this.props.mapArea.center;
    } else {
      searchArea = this.props.userLocation.center;
    }

    const likesPromise = DropdownSearcher.searchFromLikes(
      q,
      searchArea.lat,
      searchArea.lng
    );
    const suggestionPromise = DropdownSearcher.search(
      q,
      searchArea.lat,
      searchArea.lng
    );

    likesPromise.then(results => {
      this.props.dispatchUpdatedSearchResults(results);
    });

    suggestionPromise.then(results => {
      this.props.dispatchUpdatedSearchResults(results);
    });

    Promise.all([likesPromise, suggestionPromise])
      .then(() => this.props.dispatchEndSearch())
      .catch(e => {
        this.props.dispatchEndSearch();
        console.error(e);
        this.props.dispatchSearchFetchError(
          `Oops! There's been an error. Please try again.`
        );
      });
  }

  onChange(q) {
    if (q === "") {
      return this.props.dispatchCancelSearch(q);
    }

    q = q.trim();

    this.props.dispatchStartSearch(q);
    this.props.dispatchUpdateHistorySearchResult(
      DropdownSearcher.searchFromHistory(q)
    );
    if (q.length < 3) {
      return this.props.dispatchEndSearch();
    }

    this.q = q;

    if (!this.throttledSearch) {
      this.throttledSearch = throttle(this._apiSearchs, 500, {
        leading: true,
        trailing: true
      });
    }
    this.throttledSearch();
  }
  onClear(q) {
    this.props.dispatchCancelSearch(q);
  }
  onBlur(q) {
    this.props.dispatchCloseSearch(q);
  }

  onFocus() {
    if (
      this.props.results.myPlaces !== null ||
      this.props.results.suggestions !== null ||
      this.props.results.myPlaces !== null
    ) {
      this.props.dispatchOpenSearch();
    }
  }

  shouldSearchDropdownBeOpen() {
    const wantsToOpen = this.props.dropdownOpen;
    const isQueryLongEnough = this.props.query && this.props.query.length > 3;

    const allResults = Object.keys(this.props.results).reduce((prev, key) => {
      if (this.props.results[key] !== null) {
        prev = [...prev, ...this.props.results[key]];
      }
      return prev;
    }, []);

    const areThereOldResults = allResults.length > 0;

    const result = wantsToOpen && (isQueryLongEnough || areThereOldResults);
    return result;
  }

  render() {
    const shouldSearchDropdownBeOpen = this.shouldSearchDropdownBeOpen();
    return (
      <header className={"bg-blue-700 py-3 px-6 flex-none relative"}>
        <MainSearchBar
          isActive={shouldSearchDropdownBeOpen}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
          onClear={this.onClear}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          placeholder={"Search by city or stadium name"}
          initialValue={this.props.searcherInitialValue}
        />
        {shouldSearchDropdownBeOpen && (
          <div
            className={ClassNames([
              s["sidebar-header__dropdown"],
              `absolute shadow-md bg-white z-20
              rounded-b rounded-b-lg
              border
              `
            ])}
          >
            <SearchResultsDropdownList
              error={this.props.error}
              results={this.props.results}
              query={this.props.query}
              onClick={this.props.dispatchCloseSearch}
            />
          </div>
        )}
      </header>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      query: state.map.searcher.query,
      searcherInitialValue: state.map.searcher.query,
      dropdownOpen: state.map.searcher.open,
      results: state.map.searcher.results,
      mapArea: state.map.mapArea,
      userLocation: state.map.userLocation,
      error: state.map.searcher.error
    }),
    dispatch => ({
      dispatchStartSearch: payload => dispatch(startSearch(payload)),
      dispatchEndSearch: payload => dispatch(endSearch(payload)),
      dispatchCancelSearch: payload => dispatch(cancelSearch(payload)),
      dispatchCloseSearch: payload => dispatch(closeSearch(payload)),
      dispatchOpenSearch: payload => dispatch(openSearch(payload)),
      dispatchSearchFetchError: payload => dispatch(searchFetchError(payload)),
      dispatchUpdatedSearchResults: payload =>
        dispatch(updateSearchResults(payload)),
      dispatchUpdateHistorySearchResult: payload =>
        dispatch(updateHistorySearchResults(payload)),
      dispatchCommitSearch: payload => dispatch(commitSearch(payload))
    })
  )(SidebarHeader)
);
