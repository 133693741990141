import React from "react";
import ReactDom from "react-dom";
import PropTypes from "prop-types";

export default class Modal extends React.PureComponent {
  static defaultProps = {
    title: ""
  };
  static propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired
  };
  render() {
    if (typeof window === "undefined") {
      return <></>;
    }
    return ReactDom.createPortal(
      <div className={"w-screen h-screen fixed z-50"}>
        <div
          className={
            "fixed top-0 left-0 bg-gray-900 opacity-50 w-screen h-screen"
          }
          onClick={this.props.onClose}
        />
        <section
          className={`fixed top-1/2 left-1/2
          transform -translate-x-1/2 -translate-y-1/2 bg-white
          max-h-screen
          w-screen
          max-w-xl
          shadow-md
          flex
          flex-col
           overflow-hidden
          `}
        >
          <header className={"relative flex-none pt-6 pb-3 text-center"}>
            <h1 className={"text-2xl"}>{this.props.title}</h1>
            <button
              className={
                "absolute right-0 top-0 text-2xl mt-1 mx-3 text-gray-500"
              }
              tabIndex={20}
              onClick={this.props.onClose}
            >
              x
            </button>
          </header>
          <div className={"h-full overflow-y-auto flex-1 p-5"}>
            {this.props.children}
          </div>
        </section>
      </div>,
      document.getElementById("portal")
    );
  }
}
