import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Stars from "../stars";
import FacilityIcon from "../facility-icon";

const baseClassName = "";
const linkableClassName = "cursor-pointer hover:bg-blue-100";
const highlightedClassName = "bg-blue-100";
const likedClassName = "bg-red-100";

class StadiumCard extends React.PureComponent {
  static defaultProps = {
    link: {},
    data: {},
    highlighted: false
  };

  static propTypes = {
    link: PropTypes.object,
    data: PropTypes.object,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    highlighted: PropTypes.bool
  };

  render() {
    const isLinkable =
      this.props.link && (this.props.link.href || this.props.link.to);
    const isLiked =
      typeof this.props.data.liked !== "undefined" &&
      this.props.data.liked === true;

    const card = (
      <article
        onMouseLeave={this.props.onMouseLeave}
        onMouseEnter={this.props.onMouseEnter}
        className={classNames([
          this.props.className,
          baseClassName,
          isLinkable && linkableClassName,
          isLiked && likedClassName,
          this.props.highlighted && highlightedClassName
        ])}
      >
        <div className={"w-full flex"}>
          <div className={"flex-1 py-3 px-6"}>
            <h3 className={"font-bold text-lg"}>{this.props.data.name}</h3>
            <Stars {...this.props.data.rating} />
            <address
              className={"not-italic text-xs text-gray-700 mt-1 font-serif"}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} className={"mr-1"} />
              {this.props.data.address}
            </address>
            {this.props.data.attributes && (
              <ul className={"flex mt-1 pt-0.5"}>
                {this.props.data.attributes.facilities &&
                  this.props.data.attributes.facilities.map(facility => (
                    <FacilityIcon
                      key={facility}
                      icon={facility}
                      className={
                        "mx-1 px-0.5 first:ml-0 last:mr-0 first:pl-0 last:pr-0"
                      }
                    />
                  ))}
              </ul>
            )}
          </div>
          <div className={"flex-none p-4"}>
            {this.props.data.attributes && this.props.data.attributes.picture && (
              <picture>
                <img
                  alt="sample"
                  srcSet={this.props.data.attributes.picture}
                  src={this.props.data.attributes.picture}
                  style={{ width: "88px", height: "88px", objectFit: "cover" }}
                />
              </picture>
            )}
          </div>
        </div>
      </article>
    );

    if (this.props.link && this.props.link.to) {
      return (
        <Link to={this.props.link.to} target={this.props.link.target}>
          {card}
        </Link>
      );
    } else if (this.props.link && this.props.link.href) {
      return (
        <a href={this.props.link.href} target={this.props.link.target}>
          {card}
        </a>
      );
    } else {
      return card;
    }
  }
}

export default StadiumCard;
