import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarOutline } from "@fortawesome/free-regular-svg-icons";

export default class StarSelector extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.number
  };
  constructor(props) {
    super(props);
    this.state = {
      hovered: null,
      clicked: this.props.initialValue
    };
    this.updateHover = this.updateHover.bind(this);
    this.clear = this.clear.bind(this);
    this.updateClick = this.updateClick.bind(this);
  }
  updateHover(key) {
    this.setState({ hovered: key });
  }
  updateClick(key) {
    this.setState({ clicked: key });
    this.props.onChange(key);
  }
  clear() {
    this.setState({ hovered: null });
  }
  renderStar(key) {
    return (
      <FontAwesomeIcon
        icon={faStar}
        className={"text-blue-700 cursor-pointer text-2xl mr-2"}
        key={key}
        onMouseEnter={() => this.updateHover(key)}
        onClick={() => this.updateClick(key)}
      />
    );
  }
  renderEmptyStar(key) {
    return (
      <FontAwesomeIcon
        icon={faStarOutline}
        className={"text-blue-700 cursor-pointer text-2xl mr-2"}
        size={"xl"}
        key={key}
        onMouseEnter={() => this.updateHover(key)}
        onClick={() => this.updateClick(key)}
      />
    );
  }

  render() {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      if (this.state.hovered !== null && this.state.hovered >= i) {
        stars.push(this.renderStar(i));
      } else if (this.state.clicked !== null && this.state.clicked >= i) {
        stars.push(this.renderStar(i));
      } else {
        stars.push(this.renderEmptyStar(i));
      }
    }
    return <div onMouseLeave={this.clear}>{stars}</div>;
  }
}
