import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

class ErrorBanner extends React.Component {
  static defaultProps = {
    onClose: null
  };

  static propTypes = {
    onClose: PropTypes.func
  };

  render() {
    return (
      <div
        className={classNames([
          this.props.className,
          "fixed bottom-0 w-screen bg-red-400 flex justify-center items-center px-5 z-50"
        ])}
      >
        <p className={"font-serif p-2 text-sm"}>{this.props.children}</p>
        {this.props.onClose !== null && (
          <button
            className={"absolute right-0 px-3"}
            onClick={this.props.onClose}
          >
            x
          </button>
        )}
      </div>
    );
  }
}

export default ErrorBanner;
