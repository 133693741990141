import React from "react";
import { Route, Switch } from "react-router";
import MapDefaultPage from "./default";
import MapExplorePage from "./explore";
import MapStadiumPage from "./stadium";
import MapFacilityPage from "./facility";
import MapSearchPage from "./search";
import InternalPage from "../../layouts/map-page";
import MapView from "../../views/main-map";
import { withRouter } from "react-router";
import { matchPath } from "react-router";
import { openFacility } from "../../redux/reducers/map/actions";
import { connect } from "react-redux";
import GetLastLocationOrGeolocateUser from "../../services/location";
import MapCityPage from "./city";
const MapExplorePageElement = <MapExplorePage />;

const PATH_MY_PLACES = "/map/my-places/";
const PATH_STADIUM = "/map/stadium/:slug";
const PATH_FACILITY = "/map/stadium/:sslug/facility/:fslug";
const PATH_SEARCH = "/map/search/:searchString";
const PATH_CITY = "/map/city/:state/:city";
const PATH_NEARBY = "/map/:lat/:lng/:zoom?";
const PATH_DEFAULT = "/map/";

class MapRouter extends React.Component {
  constructor(props) {
    super(props);
    this.exploreArea = this.exploreArea.bind(this);
    this.onGeolocationEnds = this.onGeolocationEnds.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  onMarkerClick(id) {
    if (!this.isFacilityPage() && !this.isStadiumPage()) {
      this.props.history.push(`/map/stadium/${id}`);
    } else if (this.isStadiumPage()) {
      this.props.dispatchOpenFacility(id);
    }
  }

  exploreArea({ center, zoom }) {
    this.props.history.push(`/map/${center.lat}/${center.lng}/${zoom}`);
  }
  onGeolocationEnds(lat, lng, zoom) {
    // only when I'm on /map I start an explore mode
    if (this.isMapPage()) {
      this.props.history.replace(`/map/${lat}/${lng}/${zoom}`);
    }
  }

  isExplorePage() {
    const match = matchPath(this.props.location.pathname, {
      path: PATH_NEARBY
    });

    const city = matchPath(this.props.location.pathname, {
      path: PATH_CITY
    });

    if (!match || city) {
      return null;
    }

    const wrongMatch = ["stadium", "my-places", "search"].includes(
      match.params.lat
    );

    return wrongMatch ? null : match;
  }
  isCityPage() {
    return matchPath(this.props.location.pathname, {
      path: PATH_CITY
    });
  }
  isMapPage() {
    return matchPath(this.props.location.pathname, {
      path: PATH_DEFAULT,
      exact: true
    });
  }
  isStadiumPage() {
    return matchPath(this.props.location.pathname, {
      path: PATH_STADIUM
    });
  }
  isFacilityPage() {
    return matchPath(this.props.location.pathname, {
      path: PATH_FACILITY
    });
  }
  isSearchPage() {
    return matchPath(this.props.location.pathname, {
      path: PATH_SEARCH
    });
  }
  shouldGeolocate() {
    return (
      !this.isStadiumPage() &&
      !this.isFacilityPage() &&
      !this.isCityPage() &&
      !this.isSearchPage()
    );
  }

  /**
   * Get the initial location and zoom as soon as a new render takes place.
   * This is used when visiting the explore page
   * We can't wait until redux updates the state, because the map
   * needs an initial location and that location is the one used
   * to fetch from the server.
   *
   * When visiting the explore page, a normal action will be dispatched
   * this action updates the lat, lng and zoom properly forcing a recentering
   * and re zoom. This is useful because the explore page is sometimes visited
   * coming from a different page, where the map zoom or position were already changed
   * the initial location doesn't take precedence over those
   */
  getInitialLocation() {
    const isExplorePage = this.isExplorePage();
    if (isExplorePage) {
      return [
        parseFloat(isExplorePage.params.lng),
        parseFloat(isExplorePage.params.lat)
      ];
    } else {
      return null;
    }
  }
  getInitialZoom() {
    const isExplorePage = this.isExplorePage();
    if (isExplorePage) {
      return parseFloat(isExplorePage.params.zoom);
    } else {
      return null;
    }
  }

  render() {
    return (
      <InternalPage
        sidebar={
          <Switch>
            {/* /map/my-places */}
            <Route path={PATH_MY_PLACES} render={() => MapExplorePageElement} />

            {/* /map/stadium/*\/facility/* */}
            <Route path={PATH_FACILITY} component={() => <MapFacilityPage />} />

            {/* /map/stadium/* */}
            <Route path={PATH_STADIUM} render={() => <MapStadiumPage />} />

            {/* /map/search/* */}
            <Route path={PATH_SEARCH} component={() => <MapSearchPage />} />

            {/* /map/city/*\/* */}
            <Route path={PATH_CITY} component={() => <MapCityPage />} />

            {/* /map/<lat>/<lng>* */}
            <Route path={PATH_NEARBY} render={() => MapExplorePageElement} />

            {/* /map/* */}
            <Route
              path={PATH_DEFAULT}
              render={() => (
                <MapDefaultPage onExploreThisArea={this.exploreArea} />
              )}
            />
          </Switch>
        }
      >
        <span aria-hidden={true} data-testid={"page-map"} />
        <MapView
          onMarkerClick={this.onMarkerClick}
          onExploreThisArea={this.exploreArea}
          initialLocation={this.getInitialLocation()}
          initialZoom={this.getInitialZoom()}
          geolocateIfNoInitialLocation={this.shouldGeolocate()}
          onGeolocationEnds={this.onGeolocationEnds}
        />
        <GetLastLocationOrGeolocateUser />
      </InternalPage>
    );
  }
}

export default connect(null, dispatch => ({
  dispatchOpenFacility: payload => dispatch(openFacility(payload))
}))(withRouter(MapRouter));
