import React from "react";
import LandingPage from "../../layouts/landing-page";
import FullScreenMessage from "../../components/full-screen-message";

class VerificationFailed extends React.Component {
  render() {
    return (
      <LandingPage>
        <span aria-hidden={true} data-testid={"page-verification-failed"} />
        <FullScreenMessage title={"Email Verification"}>
          There was a problem verifying this email account
          <br />
          If you clicked on the button from the email that we sent to you, or
          you are sure that you copied and pasted the link properly and still
          see this error, please contact{" "}
          <a
            className={"underline text-blue-400"}
            href={"mailto:support@nalathletics.com"}
          >
            our support team
          </a>
        </FullScreenMessage>
      </LandingPage>
    );
  }
}

export default VerificationFailed;
