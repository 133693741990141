import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showModal, logout } from "../../redux/reducers/login/actions";
import s from "./UserMenu.module.scss";

function Button(props) {
  return (
    <button
      className={`${s["button"]} rounded-lg bg-blue-600 text-white text-md ml-3 px-4 py-2`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export class UserMenu extends React.PureComponent {
  static propTypes = {
    loggedInUser: PropTypes.object,
    dispatchLogOut: PropTypes.func
  };

  openModal() {
    this.props.dispatchShowModal();
  }
  logout() {
    this.props.dispatchLogOut();
  }
  render() {
    if (this.props.loggedInUser) {
      return <Button onClick={e => this.logout()}>Log Out</Button>;
    } else {
      return <Button onClick={e => this.openModal()}>Sign In</Button>;
    }
  }
}

export default connect(
  state => ({
    loggedInUser: state.login.loggedInUser
  }),
  dispatch => ({
    dispatchShowModal: payload => dispatch(showModal(payload)),
    dispatchLogOut: payload => dispatch(logout(payload))
  })
)(UserMenu);
