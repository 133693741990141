import React from "react";
import Api from "../../../services/api";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  clearStadiumMode,
  fetchedStadium,
  stadiumFetchError,
  openStadium
} from "../../../redux/reducers/map/actions";
import SidebarStadium from "../../../views/sidebar-stadium";

class MapStadiumPage extends React.Component {
  static defaultProps = {
    stadium: {},
    dispatchFetchedStadium: payload => {
      console.warn("property not assigned");
    }
  };

  static propTypes = {
    dispatchFetchedStadium: PropTypes.func
  };

  componentDidMount() {
    this.initStadiumMode();
    this.props.dispatchOpenStadium();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.initStadiumMode();
    }
  }
  componentWillUnmount() {
    this.props.dispatchClearStadiumMode();
  }

  initStadiumMode() {
    Api.getStadium(this.props.match.params.slug)
      .then(data => {
        this.props.dispatchFetchedStadium(data.data);
      })
      .catch(error => {
        this.props.dispatchStadiumFetchError(error);
      });
  }
  render() {
    return (
      <>
        <span aria-hidden={true} data-testid={"page-map-stadium"} />
        <SidebarStadium />
      </>
    );
  }
}

export default withRouter(
  connect(null, dispatch => ({
    dispatchFetchedStadium: payload => dispatch(fetchedStadium(payload)),
    dispatchStadiumFetchError: payload => dispatch(stadiumFetchError(payload)),
    dispatchClearStadiumMode: payload => dispatch(clearStadiumMode(payload)),
    dispatchOpenStadium: payload => dispatch(openStadium(payload))
  }))(MapStadiumPage)
);
