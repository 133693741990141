import React from "react";
import SidebarSearch from "../../../views/sidebar-search";
import { connect } from "react-redux";
import {
  updateRatingFilter,
  updateSearchString,
  newSearchStarted,
  updateSearchErrorMessage,
  updateSearchModeSearchResults,
  clearSearchMode,
  searchPageChanged
} from "../../../redux/reducers/map/actions";
import { withRouter } from "react-router";
import Api from "../../../services/api";
import Pagination from "../../../components/pagination";
import LoadingComponent from "../../../components/loading-component";
import FilterBar from "../../../components/filter-bar";
import RatingFilter from "../../../components/rating-filter";

class MapSearchPage extends React.Component {
  static defaultProps = {
    data: null
  };

  constructor(props) {
    super(props);
    this.fetch = this.fetch.bind(this);
    this.onRatingFilterChanged = this.onRatingFilterChanged.bind(this);
  }

  componentDidMount() {
    this.props.dispatchUpdateSearchString(this.props.match.params.searchString);
    this.props.dispatchNewSearchStarted();
    if (this.props.userLocation.center !== null) {
      this.fetch();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.params.searchString !==
      this.props.match.params.searchString
    ) {
      this.props.dispatchUpdateSearchString(
        this.props.match.params.searchString
      );
      this.props.dispatchNewSearchStarted();
      this.fetch();
    } else {
      if (
        (prevProps.userLocation.center === null &&
          this.props.userLocation.center !== null) ||
        prevProps.ratingFilter !== this.props.ratingFilter
      ) {
        this.fetch();
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatchClearSearchMode("");
  }

  fetch() {
    Api.mapSearch(
      this.props.match.params.searchString,
      this.props.userLocation.center && this.props.userLocation.center.lat,
      this.props.userLocation.center && this.props.userLocation.center.lng,
      this.props.ratingFilter
    )
      .then(data => {
        this.props.dispatchUpdateSearchModeSearchResults(data.data);
      })
      .catch(error => {
        this.props.dispatchUpdateSearchErrorMessage(error);
      });
  }
  onRatingFilterChanged(value) {
    this.props.dispatchUpdateRatingFilter(value);
  }

  render() {
    let totalPages = 0;

    if (this.props.data !== null) {
      totalPages += this.props.data.stadiums.length;
      if (this.props.data.savedPlaces) {
        totalPages += this.props.data.savedPlaces.length;
      }

      totalPages = Math.ceil(totalPages / 10);
    }

    const sidebar = (
      <>
        <span aria-hidden={true} data-testid={"page-map-search"} />
        <FilterBar>
          <RatingFilter
            selected={this.props.ratingFilter}
            onChange={this.onRatingFilterChanged}
          />
        </FilterBar>
        <SidebarSearch className={"h-full min-h-0 flex-1 overflow-y-auto"} />

        {this.props.data !== null ? (
          <div className={"flex-0 text-sm p-2 border-t"}>
            <Pagination
              onPageChanged={payload => {
                this.props.dispatchSearchPageChanged(payload);
              }}
              currentPage={this.props.currentPage}
              totalPages={totalPages}
            />
          </div>
        ) : (
          <div className={"flex-0 text-sm p-2 border-t"}>
            <LoadingComponent />
          </div>
        )}
      </>
    );

    return sidebar;
  }
}

export default connect(
  state => ({
    data: state.map.searchMode.results,
    currentPage: state.map.searchMode.sidebarCurrentPage,
    searching: state.map.searchMode.searching,
    userLocation: state.map.userLocation,
    ratingFilter: state.map.searchMode.ratingFilter
  }),
  dispatch => ({
    dispatchSearchPageChanged: payload => dispatch(searchPageChanged(payload)),
    dispatchUpdateSearchString: payload =>
      dispatch(updateSearchString(payload)),
    dispatchUpdateSearchModeSearchResults: payload =>
      dispatch(updateSearchModeSearchResults(payload)),
    dispatchNewSearchStarted: payload => dispatch(newSearchStarted(payload)),
    dispatchUpdateSearchErrorMessage: payload =>
      dispatch(updateSearchErrorMessage(payload)),
    dispatchClearSearchMode: payload => dispatch(clearSearchMode(payload)),
    dispatchUpdateRatingFilter: payload => dispatch(updateRatingFilter(payload))
  })
)(withRouter(MapSearchPage));
