import React from "react";
import {
  getUserLocation as getUserLocationFromStorage,
  saveUserLocation
} from "./lib/localStorage";
import { getUserLocation as getUserLocationFromGeolocation } from "./lib/geolocation";
import { connect } from "react-redux";
import { userLocationLoaded } from "../../redux/reducers/map/actions";

function getUserLocation() {
  return new Promise((resolve, reject) => {
    // The geolocation is async
    getUserLocationFromGeolocation()
      .then(location => {
        saveUserLocation({ center: location }, "geo");
        return resolve({ center: location });
      })
      .catch(e => {
        console.error(e);
      });
  });
}

function getLastLocationOrUserlocation() {
  const lastSavedLocation = getUserLocationFromStorage();
  if (lastSavedLocation) {
    getUserLocation(); // async, not used on the first requests
    return Promise.resolve(lastSavedLocation);
  } else {
    return getUserLocation();
  }
}

export function GetLastLocationOrGeolocateUser(props) {
  getLastLocationOrUserlocation()
    .then(data => {
      props.dispatchUserLocationLoaded(data);
    })
    .catch(e => {
      console.error(e);
    });
  return <></>;
}

export default connect(null, dispatch => ({
  dispatchUserLocationLoaded: payload => dispatch(userLocationLoaded(payload))
}))(GetLastLocationOrGeolocateUser);
