import { createAction } from "@reduxjs/toolkit";

export const updateNewsSearchResults = createAction(
  "search/updateNewsSearchResults"
);
export const updateNewsSearchErrorMessage = createAction(
  "search/updateNewsSearchErrorMessage"
);
export const updateMapSearchResults = createAction(
  "search/updateMapSearchResults"
);
export const updateMapSearchErrorMessage = createAction(
  "search/updateMapSearchErrorMessage"
);

export const clearAllSearchResults = createAction(
  "search/clearAllSearchResults"
);
