import { createReducer } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import Cookies from "js-cookie";

import initialState from "./initialState";
import {
  showPasswordRecoveryForm,
  showSignUpForm,
  showSignInForm,
  passwordRecoveryAttempt,
  emailUpdated,
  signInAttempt,
  signUpAttempt,
  showModal,
  hideModal,
  updatePasswordRecoveryErrorMessage,
  updatePasswordRecoverySuccessMessage,
  updateSignInErrorMessage,
  updateSignUpErrorMessage,
  signedIn,
  updateSignUpSuccessMessage,
  logout,
  recoveredSession,
  updateMyPlacesResults,
  myPlacesFetchError,
  myPlacesPageChanged,
  startLoadingMyPlaces
} from "./actions";
import { facilityDislike, facilityLike } from "../map/actions";

function clone(state) {
  if (state === null) {
    return null;
  }
  return { ...state };
}

const actionsMap = {
  [showPasswordRecoveryForm]: (state, { payload }) => {
    const newState = clone(state);

    newState.stage = "password-recovery";
    newState.title = "Password Recovery";
    return newState;
  },
  [showSignUpForm]: (state, { payload }) => {
    const newState = clone(state);

    newState.stage = "sign-up";
    newState.title = "Sign Up";
    return newState;
  },
  [passwordRecoveryAttempt]: (state, { payload }) => {
    const newState = clone(state);

    newState.passwordRecoveryError = "";
    newState.loading = true;
    return newState;
  },
  [emailUpdated]: (state, { payload }) => {
    const newState = clone(state);

    newState.email = payload;
    return newState;
  },
  [showSignInForm]: (state, { payload }) => {
    const newState = clone(state);

    newState.stage = "sign-in";
    newState.title = "Sign In";
    return newState;
  },
  [signInAttempt]: (state, { payload }) => {
    const newState = clone(state);

    newState.signInError = "";
    newState.loading = true;
    return newState;
  },
  [signUpAttempt]: (state, { payload }) => {
    const newState = clone(state);

    newState.loading = true;
    newState.signUpError = "";
    return newState;
  },
  [showModal]: (state, { payload }) => {
    const newState = clone(state);

    newState.showModal = true;
    return newState;
  },
  [hideModal]: (state, { payload }) => {
    const newState = clone(state);

    newState.showModal = false;
    return newState;
  },
  [updatePasswordRecoveryErrorMessage]: (state, { payload }) => {
    const newState = clone(state);

    newState.passwordRecoveryError = payload;
    newState.loading = false;
    return newState;
  },
  [updatePasswordRecoverySuccessMessage]: (state, { payload }) => {
    const newState = clone(state);

    newState.passwordRecoverySuccessMessage = payload;
    newState.loading = false;
    return newState;
  },
  [updateSignInErrorMessage]: (state, { payload }) => {
    const newState = clone(state);

    newState.signInError = payload;
    newState.loading = false;
    return newState;
  },
  [updateSignUpErrorMessage]: (state, { payload }) => {
    const newState = clone(state);

    newState.signUpError = payload;
    newState.loading = false;
    return newState;
  },
  [signedIn]: (state, { payload }) => {
    const newState = clone(initialState);

    newState.loggedInUser = payload;
    localStorage.setItem("auth-session", JSON.stringify(payload));
    return newState;
  },
  [updateSignUpSuccessMessage]: (state, { payload }) => {
    const newState = clone(state);

    newState.signUpSuccessMessage = payload;
    return newState;
  },
  [recoveredSession]: (state, { payload }) => {
    const newState = clone(initialState);

    // If we recover from cookie we want to save it
    localStorage.setItem("auth-session", JSON.stringify(payload));
    newState.loggedInUser = payload;
    return newState;
  },
  [logout]: (state, { payload }) => {
    localStorage.removeItem("auth-session");
    Cookies.remove("signin-post-oauth");

    const newState = clone(state);
    newState.loggedInUser = clone(initialState.loggedInUser);
    return newState;
  },
  [facilityLike]: (state, { payload }) => {
    let newState = clone(state);

    function setToTrue(facility) {
      if (facility.id === payload) {
        facility.liked = true;
      }
    }
    if (newState.myPlaces.stadiums) {
      newState.myPlaces = cloneDeep(state.myPlaces);

      newState.myPlaces.stadiums.forEach(s => {
        s.relationships.facilities.forEach(setToTrue);
      });
    }
    return newState;
  },
  [facilityDislike]: (state, { payload }) => {
    let newState = clone(state);

    function setToFalse(facility) {
      if (facility.id === payload) {
        facility.liked = false;
      }
    }
    if (newState.myPlaces.stadiums) {
      newState.myPlaces = cloneDeep(state.myPlaces);

      newState.myPlaces.stadiums.forEach(s => {
        s.relationships.facilities.forEach(setToFalse);
      });
    }
    return newState;
  },
  [startLoadingMyPlaces]: (state, { payload }) => {
    const newState = clone(state);
    newState.myPlaces = clone(state.myPlaces);
    newState.myPlaces.loading = true;
    return newState;
  },
  [updateMyPlacesResults]: (state, { payload }) => {
    const newState = clone(state);
    newState.myPlaces = clone(state.myPlaces);

    newState.myPlaces.error = null;
    newState.myPlaces.loading = false;
    newState.myPlaces.stadiums = payload;
    return newState;
  },
  [myPlacesPageChanged]: (state, { payload }) => {
    let newState = clone(state);
    newState.myPlaces = clone(state.myPlaces);

    newState.myPlaces.sidebarCurrentPage = payload;
    return newState;
  },
  [myPlacesFetchError]: (state, { payload }) => {
    let newState = clone(state);
    newState.myPlaces = clone(state.myPlaces);

    newState.myPlaces.loading = false;
    newState.myPlaces.error = payload;
    return newState;
  }
};

export default createReducer(initialState, actionsMap);
