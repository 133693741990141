import React from "react";
import s from "./MainSearchBar.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const inputBaseClass = s["main-search-bar--input"];
const inputActiveClass = "rounded-b-none";

class MainSearchBar extends React.PureComponent {
  static defaultProps = {
    placeholder: "Search...",
    initialValue: null,
    isActive: false,
    onFocus: () => {}
  };

  static propTypes = {
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isActive: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.clear = this.clear.bind(this);
    this.state = {
      query: props.initialValue || ""
    };

    this.ref = React.createRef();
    this.inputRef = React.createRef();
    this.listenClickOutside = this.listenClickOutside.bind(this);
  }
  onFocus(e) {
    e.preventDefault();
    this.props.onFocus();
  }
  onSubmit(e) {
    e.preventDefault();
    if (this.state.query.trim()) {
      this.props.onSubmit(this.state.query);
    }
  }
  onChange(e) {
    let value = e.target.value;
    value = value.replace(/[\/;"\\\[\]\{\}\?&@#\(\)%<>]/, "");
    this.setState({ query: value });
    this.props.onChange(value);
  }
  clear() {
    if (this.state.query) {
      this.setState({ query: "" });
    }
    this.props.onClear();
  }

  listenClickOutside(e) {
    if (
      e.target !== this.ref.current &&
      e.target !== this.inputRef.current &&
      this.props.onBlur
    ) {
      this.props.onBlur();
    }
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      document.addEventListener("click", this.listenClickOutside);
    }
  }
  componentWillUnmount() {
    if (typeof window !== "undefined") {
      document.removeEventListener("click", this.listenClickOutside);
    }
  }

  render() {
    const inputValue =
      this.state.query !== ""
        ? this.state.query
        : this.props.initialValue || "";
    return (
      <div
        className={classNames([s["main-search-bar"], this.props.className])}
        ref={this.ref}
      >
        <form onSubmit={this.onSubmit}>
          <FontAwesomeIcon
            icon={faSearch}
            className={s["main-search-bar--icon"]}
          />
          <input
            type={"text"}
            className={classNames(
              inputBaseClass,
              this.props.isActive && inputActiveClass
            )}
            placeholder={this.props.placeholder}
            value={inputValue}
            onFocus={this.onFocus}
            onChange={this.onChange}
            ref={this.inputRef}
            tabIndex={0}
          />
          <input type={"submit"} className={"hidden"} value={"search"} />
          {this.state.query !== "" && (
            <button
              onClick={this.clear}
              className={classNames([
                s["main-search-bar--close"],
                "text-gray-700 px-3"
              ])}
            >
              <svg
                width={14}
                height={14}
                version="1.1"
                viewBox="0 0 13.141 13.141"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m1.5332 0-1.5332 1.5352 5.0352 5.0352-5.0352 5.0371 1.5332 1.5332 5.0371-5.0352 5.0352 5.0352 1.5352-1.5332-5.0371-5.0371 5.0371-5.0352-1.5352-1.5352-5.0352 5.0371z"
                  fill="#dbdbdb"
                  fillRule="evenodd"
                  strokeWidth=".80393"
                />
              </svg>
            </button>
          )}
        </form>
      </div>
    );
  }
}

export default MainSearchBar;
