import React from "react";

class SearchStories extends React.Component {
  static defaultProps = {};

  static propTypes = {};

  render() {
    return (
      <div className={"p-6 font-serif"}>
        This feature is no ready yet.
        <br />
        We'll add stories soon.
      </div>
    );
  }
}

export default SearchStories;
