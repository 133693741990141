import { createAction } from "@reduxjs/toolkit";

export const updateExploreResults = createAction("map/updateExploreResults");

export const updateSearchResults = createAction("map/updateSearchResults");
export const updateSearchModeSearchResults = createAction(
  "map/updateSearchModeSearchResults"
);
export const updateHistorySearchResults = createAction(
  "map/updateHistorySearchResults"
);

export const updateSearchString = createAction("map/updateSearchString");

export const mapAreaUpdated = createAction("map/mapAreaUpdated");

export const newSearchStarted = createAction("map/newSearchStarted");

export const openStadium = createAction("map/openStadium");

export const userLocationLoaded = createAction("map/userLocationLoaded");

export const exploreNewArea = createAction("map/exploreNewArea");

export const stadiumMouseEnter = createAction("map/stadiumMouseEnter");

export const stadiumMouseLeave = createAction("map/stadiumMouseLeave");

export const fetchedStadium = createAction("map/fetchedStadium");

export const fetchedFacility = createAction("map/fetchedFacility");

export const pageChanged = createAction("map/pageChanged");

export const facilityLike = createAction("map/facilityLike");
export const facilityDislike = createAction("map/facilityDislike");

export const sidebarCollapse = createAction("map/sidebarCollapse");
export const sidebarExpand = createAction("map/sidebarExpand");

export const closeReviewModal = createAction("map/closeReviewModal");
export const openReviewModal = createAction("map/openReviewModal");

export const startSearch = createAction("map/startSearch");
export const endSearch = createAction("map/endSearch");
export const closeSearch = createAction("map/closeSearch");
export const openSearch = createAction("map/openSearch");
export const cancelSearch = createAction("map/cancelSearch");
export const commitSearch = createAction("map/commitSearch");

export const closeSidebarStadium = createAction("map/closeSidebarStadium");
export const closeSidebarFacility = createAction("map/closeSidebarFaciliity");
export const exploreModeFetchError = createAction("map/exploreModeFetchError");
export const searchFetchError = createAction("map/searchFetchError");
export const stadiumFetchError = createAction("map/stadiumFetchError");
export const facilityFetchError = createAction("map/facilityFetchError");

export const updateGeneralError = createAction("map/updateGeneralError");

export const updateSearchErrorMessage = createAction(
  "map/updateSearchErrorMessage"
);
export const clearSearchMode = createAction("map/clearSearchMode");
export const clearStadiumMode = createAction("map/clearStadiumMode");
export const clearExploreMode = createAction("map/clearExploreMode");
export const updateRatingFilter = createAction("map/updateRatingFilter");

export const searchPageChanged = createAction("map/searchPageChanged");

export const changeMapStyle = createAction("map/changeMapStyle");

export const adjustMap = createAction("map/adjustMap");

export const clearCityMode = createAction("map/clearCityMode");
export const setCityModeCity = createAction("map/setCityModeCity");
export const updateCityModeResults = createAction("map/updateCityModeResults");
export const newCitySearchStarted = createAction("map/newCitySearchStarted");
export const updateCitySearchRatingFilter = createAction(
  "map/updateCitySearchRatingFilter"
);

export const updateCitySearchErrorMessage = createAction(
  "map/updateCitySearchErrorMessage"
);
export const updateCityModeSearchResults = createAction(
  "map/updateCityModeSearchResults"
);

export const citySearchPageChanged = createAction("map/citySearchPageChanged");
export const openFacility = createAction("map/openFacility");
