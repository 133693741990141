import React from "react";
import PropTypes from "prop-types";

export default class FullScreenMessage extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string
  };
  render() {
    return (
      <div
        className={"w-full h-full bg-gray-200 flex items-center justify-center"}
      >
        <div className={"bg-white shadow-md p-5 max-w-lg"}>
          {this.props.title && (
            <h1 className={"font-bold mb-2"}>{this.props.title}</h1>
          )}
          <p className={"font-serif"}>{this.props.children}</p>
        </div>
      </div>
    );
  }
}
