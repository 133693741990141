import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import s from "./MapStyleSwitch.module.scss";

import satelliteThumb from "./satellite-thumb@2x.jpg";
import satelliteThumbWebp from "./satellite-thumb@2x.jpg?format=webp";
import streetsThumb from "./streets-thumb@2x.jpg";
import streetsThumbWebp from "./streets-thumb@2x.jpg?format=webp";
import satelliteThumb2x from "./satellite-thumb@2x.jpg";
import satelliteThumb2xWebp from "./satellite-thumb@2x.jpg?format=webp";
import streetsThumb2x from "./streets-thumb@2x.jpg";
import streetsThumb2xWebp from "./streets-thumb@2x.jpg?format=webp";

class MapStyleSwitch extends React.PureComponent {
  static MODE_STREET = "street";
  static MODE_SATELLITE = "satellite";
  static defaultProps = { currentMode: "street", onChange: () => {} };

  static propTypes = {
    currentMode: PropTypes.string,
    onChange: PropTypes.func
  };

  renderSatellite() {
    return (
      <>
        <picture>
          <source
            srcSet={`${satelliteThumbWebp.src} 1x, ${satelliteThumb2xWebp.src} 2x`}
            media={"image/webp"}
          />
          <source
            srcSet={`${satelliteThumb.src} 1x, ${satelliteThumb2x.src} 2x`}
          />
          <img
            alt={""}
            src={satelliteThumb}
            width={70}
            height={70}
            className={"absolute top-0 left-0 w-full h-full z-0"}
          />
        </picture>
        <span className={"z-1 relative"}>Satellite</span>
      </>
    );
  }

  renderStreet() {
    return (
      <>
        <picture>
          <source
            srcSet={`${streetsThumbWebp.src} 1x, ${streetsThumb2xWebp.src} 2x`}
            media={"image/webp"}
          />
          <source srcSet={`${streetsThumb.src} 1x, ${streetsThumb2x.src} 2x`} />
          <img
            alt={""}
            src={streetsThumb}
            width={70}
            height={70}
            className={"absolute top-0 left-0 w-full h-full z-0"}
          />
        </picture>
        <span className={"z-1 relative"}>Streets</span>
      </>
    );
  }

  render() {
    return (
      <button
        onClick={() => {
          if (this.props.currentMode === MapStyleSwitch.MODE_STREET) {
            this.props.onChange(MapStyleSwitch.MODE_SATELLITE);
          } else {
            this.props.onChange(MapStyleSwitch.MODE_STREET);
          }
        }}
        className={classNames([
          "flex items-start justify-end bg-blue-900 text-white leading-none flex-col absolute bottom-0 left-0 z-20 border-white border shadow-md p-1 text-sm",
          s["map-style-switch"]
        ])}
      >
        {this.props.currentMode === "street"
          ? this.renderSatellite()
          : this.renderStreet()}
      </button>
    );
  }
}

export default MapStyleSwitch;
