const initialState = {
  title: "Sign In",
  stage: "sign-in",
  email: null,
  showModal: false,
  loading: false,
  signInError: "",
  signUpError: "",
  passwordRecoveryError: "",
  passwordRecoverySuccessMessage: "",
  signUpSuccessMessage: "",
  loggedInUser: null,

  // My Places
  myPlaces: {
    sidebarCurrentPage: 1,
    stadiums: null,
    loading: false,
    error: null
  }
};

export default initialState;
