import React from "react";
import TabContainer from "../../../components/tab-container";
import SidebarNearBy from "../../../views/sidebar-near-by";
import Pagination from "../../../components/pagination";
import {
  pageChanged,
  updateExploreResults,
  exploreModeFetchError,
  exploreNewArea,
  adjustMap,
  clearExploreMode
} from "../../../redux/reducers/map/actions";
import { myPlacesPageChanged } from "../../../redux/reducers/login/actions";
import { connect } from "react-redux";
import LoadingComponent from "../../../components/loading-component";
import SidebarMyPlaces from "../../../views/sidebar-my-places";
import { withRouter } from "react-router";
import Api from "../../../services/api";

function mapAreaDefined(props) {
  return props.mapArea.ne !== null;
}

class MapExplorePage extends React.Component {
  componentDidMount() {
    // We can't fetch here unless we know the 4 corners
    // See frontend/src/pages/map/index.js we are loading the map there in the proper explore location from the url
    if (mapAreaDefined(this.props)) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    this.props.dispatchClearExploreMode();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // If the url changes (and the map area is resolved)
    if (
      (mapAreaDefined(this.props) &&
        prevProps.match.params.lat !== this.props.match.params.lat) ||
      prevProps.match.params.lng !== this.props.match.params.lng
    ) {
      this.fetch();
    }

    // If the map area wasn't resolved but it is now
    if (!mapAreaDefined(prevProps) && mapAreaDefined(this.props)) {
      this.fetch();
      // If the map area was already in some state
    } else if (mapAreaDefined(prevProps) && this.firstMount) {
      this.firstMount = false;
    }
  }

  fetch() {
    if (mapAreaDefined(this.props)) {
      // Remove the explore area button
      this.props.dispatchExploreNewArea();

      // Fetch the data from the api
      Api.explore(this.props.mapArea)
        .then(data => {
          this.props.dispatchUpdateExploreResults(data);
        })
        .catch(error => {
          this.props.dispatchExploreModeFetchError(error);
        });
    }
  }

  /*
   * Here the map view handles the Api requests because it contains all the info we need to do a request
   */
  render() {
    const sidebar = (
      <>
        <TabContainer
          data={[
            {
              key: "nearby",
              label: {
                location: "/map",
                text: "Nearby",
                onClick: () => {
                  if (this.props.mapArea) {
                    this.props.history.push(
                      `/map/${this.props.mapArea.center.lat}/${this.props.mapArea.center.lng}/${this.props.mapArea.zoom}`
                    );
                  }
                }
              },
              content: <SidebarNearBy />
            },
            {
              key: "myplaces",
              label: {
                location: "/map/my-places",
                text: "My Places"
              },
              content: <SidebarMyPlaces />
            }
          ]}
          footer={{
            nearby:
              this.props.data !== null && this.props.data.length > 0 ? (
                <Pagination
                  onPageChanged={payload => {
                    this.props.dispatchPageChanged(payload);
                  }}
                  currentPage={this.props.currentPage}
                  totalPages={Math.ceil(this.props.data.length / 10)}
                />
              ) : this.props.data !== null &&
                this.props.data.length === 0 ? null : (
                <LoadingComponent />
              ),
            myplaces:
              this.props.myPlacesData !== null &&
              this.props.myPlacesData.length > 0 ? (
                <Pagination
                  onPageChanged={payload => {
                    this.props.dispatchMyPlacesPageChanged(payload);
                  }}
                  currentPage={this.props.currentPageMyPlaces}
                  totalPages={Math.ceil(this.props.myPlacesData.length / 10)}
                />
              ) : this.props.loadingMyPlaces ? (
                <LoadingComponent />
              ) : null
          }}
        />
        <span aria-hidden={true} data-testid={"page-map-explore"} />
      </>
    );

    return sidebar;
  }
}

export default connect(
  state => ({
    mapArea: state.map.mapArea,
    loadingMyPlaces: state.login.myPlaces.loading,
    data: state.map.exploreMode.stadiums,
    myPlacesData: state.login.myPlaces.stadiums,
    currentPage: state.map.exploreMode.sidebarCurrentPage,
    currentPageMyPlaces: state.login.myPlaces.sidebarCurrentPage
  }),
  dispatch => ({
    dispatchPageChanged: payload => dispatch(pageChanged(payload)),
    dispatchMyPlacesPageChanged: payload =>
      dispatch(myPlacesPageChanged(payload)),
    dispatchUpdateExploreResults: payload =>
      dispatch(updateExploreResults(payload)),
    dispatchExploreModeFetchError: payload =>
      dispatch(exploreModeFetchError(payload)),
    dispatchExploreNewArea: payload => dispatch(exploreNewArea(payload)),
    dispatchAdjustMap: payload => dispatch(adjustMap(payload)),
    dispatchClearExploreMode: payload => dispatch(clearExploreMode(payload))
  })
)(withRouter(MapExplorePage));
