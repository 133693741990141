import React from "react";
import LandingPage from "../../layouts/landing-page";
import FullScreenMessage from "../../components/full-screen-message";
import { withRouter } from "react-router";
import PasswordResetForm from "../../views/password-reset-form";

class PasswordReset extends React.Component {
  render() {
    return (
      <LandingPage>
        <span aria-hidden={true} data-testid={"page-password-reset"} />
        <FullScreenMessage title={"Password Reset"}>
          <PasswordResetForm
            token={this.props.match.params.token}
            email={this.props.match.params.email}
          />
        </FullScreenMessage>
      </LandingPage>
    );
  }
}

export default withRouter(PasswordReset);
