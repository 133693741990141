import MapStyleSwitch from "../../../components/map-style-switch";

const initialState = {
  // The last detected user location
  userLocation: {
    ne: null,
    sw: null,
    center: null,
    zoom: 12
  },

  // Search mode
  searchMode: {
    results: null,
    sidebarCurrentPage: 1,
    ratingFilter: 0,
    mapShouldReadjust: true,
    error: null
  },

  // city mode
  cityMode: {
    state: null,
    city: null,
    results: null,
    sidebarCurrentPage: 1,
    ratingFilter: 0,
    mapShouldReadjust: true,
    error: null
  },

  // Search dropdown
  searcher: {
    query: null,
    results: {
      history: null,
      myPlaces: null,
      suggestions: null,
      stadiums: null,
      cities: null
    },
    searching: false,
    open: false,
    error: null
  },

  // What would be the initial area of the map on page load
  initialLocation: {
    center: {
      lat: null,
      lng: null
    },
    zoom: null
  },

  // The coordinates delimiting the area visible on the explore and search view
  mapArea: {
    ne: null,
    sw: null,
    center: null,
    zoom: null
  },
  // What the map will listen to
  mapAreaTrigger: {
    center: null,
    zoom: null,
    data: null,
    highlightedMarker: null,
    adjustToMarkers: null,
    mapStyle: MapStyleSwitch.MODE_STREET, // street | satellite
    markerImage: "marker",
    markerHoverImage: "marker_hover",
    mouseOvering: false
  },

  // Map area did change (to show the search again button)
  mapAreaChanged: false,

  exploreMode: {
    loading: false,
    stadiums: null, // Fetched from API
    geojson: null,
    sidebarCurrentPage: 1,
    error: null,
    zoom: 10
  },

  stadiumMode: {
    stadium: {},
    loading: false,
    error: null,
    openedFacility: null
  },

  facilityMode: {
    facility: {},
    error: null
  },

  reviewForm: {
    showModal: false,
    modalTitle: "Write review",
    facilityId: null,
    facilityReviews: [],
    editableReview: null,
    error: null
  },

  // The ui state of the sidebar (expanded or collapsed
  sidebarExpanded: false,

  // The red banner with errors
  generalError: null
};

export default initialState;
