import React from "react";
import Map from "../../components/mapbox-map";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  mapAreaUpdated,
  changeMapStyle,
  stadiumMouseEnter,
  stadiumMouseLeave
} from "../../redux/reducers/map/actions";
import ExploreThisArea from "../../components/explore-this-area";

class MainMap extends React.Component {
  static defaultProps = {
    mapProps: {},
    onExploreThisArea: () => {}
  };
  static propTypes = {
    onExploreThisArea: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.changeStyle = this.changeStyle.bind(this);
    this.exploreThisArea = this.exploreThisArea.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMarkerHoverStart = this.onMarkerHoverStart.bind(this);
    this.onMarkerHoverEnd = this.onMarkerHoverEnd.bind(this);
  }

  exploreThisArea() {
    this.props.onExploreThisArea(this.props.currentMapArea);
  }

  changeStyle(mode) {
    this.props.dispatchChangeMapStyle(mode);
  }
  onMarkerClick(id) {
    this.props.onMarkerClick(id);
  }
  onMarkerHoverStart(id) {
    this.props.dispatchStadiumMouseEnter(id);
  }
  onMarkerHoverEnd() {
    this.props.dispatchStadiumMouseLeave();
  }
  render() {
    return (
      <div className={"relative flex-1 w-full h-full text-center"}>
        {this.props.mapAreaChanged && (
          <ExploreThisArea
            onClick={this.exploreThisArea}
            className={"absolute z-20 left-50 transform -translate-x-1/2"}
          />
        )}
        <Map
          initialLocation={this.props.initialLocation}
          initialZoom={this.props.initialZoom}
          geolocateIfNoInitialLocation={this.props.geolocateIfNoInitialLocation}
          mapProps={this.props.mapProps}
          onChangeStyle={this.changeStyle}
          className={"z-10"}
          onMapAreaUpdated={this.props.dispatchOnMapAreaUpdated}
          onGeolocationEnds={this.props.onGeolocationEnds}
          onMarkerHoverStart={this.onMarkerHoverStart}
          onMarkerHoverEnd={this.onMarkerHoverEnd}
          onMarkerClick={this.onMarkerClick}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      mapProps: state.map.mapAreaTrigger,
      mapAreaChanged: state.map.mapAreaChanged,
      currentMapArea: state.map.mapArea,
      isExploring: state.map.exploreMode.loading
    }),
    dispatch => ({
      dispatchChangeMapStyle: payload => dispatch(changeMapStyle(payload)),
      dispatchOnMapAreaUpdated: payload => dispatch(mapAreaUpdated(payload)),
      dispatchStadiumMouseEnter: payload =>
        dispatch(stadiumMouseEnter(payload)),
      dispatchStadiumMouseLeave: payload => dispatch(stadiumMouseLeave(payload))
    })
  )(MainMap)
);
