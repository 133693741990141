import React from "react";
import PropTypes from "prop-types";
import StadiumCard from "../stadium-card";

class CardList extends React.PureComponent {
  static defaultProps = {
    dataSet: [],
    onMouseLeave: () => {
      console.log("default");
    },
    onMouseEnter: () => {
      console.log("default");
    }
  };
  static propTypes = {
    dataSet: PropTypes.array,
    highlighted: PropTypes.number
  };

  render() {
    const items = this.props.dataSet.map(data => (
      <li key={data.id} className={"border-t first:border-t-0"}>
        <StadiumCard
          onMouseEnter={() => this.props.onMouseEnter(data.id)}
          onMouseLeave={() => this.props.onMouseLeave(data.id)}
          data={data}
          highlighted={this.props.highlighted === data.id}
          link={data.link}
        />
      </li>
    ));

    return <ul>{items}</ul>;
  }
}

export default CardList;
