import React from "react";
import classNames from "classnames";
import s from "./collapsible-sidebar.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Swipeable } from "react-swipeable";
import PropTypes from "prop-types";

class CollapsibleSidebar extends React.PureComponent {
  static propTypes = {
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired
  };
  constructor(props) {
    super(props);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  onOpen() {
    this.props.onOpen();
  }
  onClose() {
    this.props.onClose();
  }
  onToggle() {
    if (this.props.expanded) {
      this.props.onClose();
    } else {
      this.props.onOpen();
    }
  }

  render() {
    const sidebarWrapperStatusClass =
      this.props.expanded && s["sidebar--wrapper__expanded"];
    return (
      <div className={"flex-0 min-h-0 lg:flex"}>
        <div
          className={classNames([
            sidebarWrapperStatusClass,
            s["sidebar--wrapper"]
          ])}
        >
          {this.props.children}
          <Swipeable onSwipedRight={this.onOpen} onSwipedLeft={this.onClose}>
            <div className={s["sidebar--toggle"]} onClick={this.onToggle}>
              <FontAwesomeIcon icon={faCaretRight} />
            </div>
          </Swipeable>
        </div>
      </div>
    );
  }
}

export default CollapsibleSidebar;
