import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

const activeClass = "text-white";

const links = [
  {
    to: "/map",
    label: "Map"
  },
  {
    to: "/stories",
    label: "Stories"
  },
  {
    to: "https://nalathletics.com/blog/",
    label: "News"
  }
];

class Navigation extends React.PureComponent {
  static propTypes = {
    activeClass: PropTypes.oneOf([PropTypes.string, PropTypes.object])
  };
  render() {
    return (
      <nav className={classNames([this.props.className, "flex-none text-md"])}>
        <ul className="flex flex-col text-center md:text-left md:flex-row mx-5">
          {links.map(link => (
            <li className="px-5" key={link.label}>
              {link.to.match("https://") ? (
                <a href={link.to}>{link.label}</a>
              ) : (
                <NavLink
                  activeClassName={classNames([
                    activeClass,
                    this.props.activeClass
                  ])}
                  to={link.to}
                >
                  {link.label}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}

export default Navigation;
