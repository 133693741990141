const initialState = {
  map: {
    results: null,
    error: null
  },
  stories: {
    results: null,
    error: null
  },
  news: {
    results: null,
    error: null
  }
};

export default initialState;
