import Api from "../api";
import React from "react";
import { connect } from "react-redux";
import { recoveredSession } from "../../redux/reducers/login/actions";
import Cookies from "js-cookie";
import store from "../../redux/store";

class Auth {
  withAuthHeader(config) {
    const bearerToken = this.getBearerToken();
    if (bearerToken) {
      const originHeaders = config.headers;
      const newHeaders = { authorization: bearerToken };
      config.headers = Object.assign(newHeaders, originHeaders);
    }

    return config;
  }
  googleSignIn() {
    const url =
      process.env.REACT_APP_GOOGLE_OAUTH_URL +
      "?redirect=" +
      encodeURI(window.location.href);
    window.location.href = url;
  }
  facebookSignIn() {
    const url =
      process.env.REACT_APP_FACEBOOK_OAUTH_URL +
      "?redirect=" +
      encodeURI(window.location.href);
    window.location.href = url;
  }
  getCaptchaKey() {
    return process.env.REACT_APP_RECAPTCHA_KEY;
  }
  signIn(data) {
    return Api.signIn(data);
  }
  signUp(data) {
    return Api.signUp(data);
  }
  recoverPassword(data) {
    return Api.recoverPassword(data);
  }
  getBearerToken() {
    let state = store.getState();
    if (state.login.loggedInUser !== null) {
      return `Bearer ${state.login.loggedInUser.token}`;
    }
  }
  resetPassword(data) {
    return new Promise((resolve, reject) => {
      Api.resetToken(data)
        .then(data => {
          if (data && data.errors) {
            reject(data.errors[0]);
          } else {
            resolve();
          }
        })
        .catch(() => reject());
    });
  }
}

export default new Auth();

export const BootstrapLogIn = connect(null, dispatch => ({
  dispatchRecoveredSession: payload => dispatch(recoveredSession(payload))
}))(
  class BootstrapLogIn extends React.Component {
    componentDidMount() {
      if (typeof window != "undefined") {
        let cooValue = Cookies.get("signin-post-oauth");
        if (typeof cooValue !== "undefined") {
          cooValue = JSON.parse(cooValue);
        }
        if (cooValue) {
          this.props.dispatchRecoveredSession(cooValue);
          Cookies.remove("signin-post-oauth");
        } else {
          let lsValue = window.localStorage.getItem("auth-session");
          lsValue = JSON.parse(lsValue);
          if (lsValue) {
            this.props.dispatchRecoveredSession(lsValue);
          }
        }
      }
    }

    render() {
      return <></>;
    }
  }
);
