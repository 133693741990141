import React from "react";
import PropTypes from "prop-types";

export default class UserCard extends React.PureComponent {
  static defaultProps = {
    widget: <></>
  };
  static propTypes = {
    avatar: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    widget: PropTypes.object
  };
  render() {
    return (
      <div className={this.props.className}>
        <div className={"flex items-start justify-stretch"}>
          <div className={"flex-none"}>
            <img
              src={this.props.avatar}
              width={"32px"}
              height={"32px"}
              className={"rounded rounded-full inline mr-3"}
            />
          </div>
          <div className={"flex-none"}>
            <span>{this.props.fullName}</span>
            {this.props.widget}
          </div>
        </div>
      </div>
    );
  }
}
