import React from "react";
import s from "./hamburger.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames";

const activeClass = s["is-active"];

class HamburguerButton extends React.PureComponent {
  static defaultProps = {};

  static propTypes = {
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.click = this.click.bind(this);
  }

  click() {
    this.setState(
      prevState => ({
        open: !prevState.open
      }),
      () => {
        if (this.state.open) {
          this.props.onOpen();
        } else {
          this.props.onClose();
        }
      }
    );
  }

  render() {
    return (
      <button
        onClick={this.click}
        className={classNames(
          s["hamburger"],
          s["hamburger--3dx"],
          this.state.open && activeClass,
          this.props.className
        )}
        type="button"
      >
        <span className={s["hamburger-box"]}>
          <span className={s["hamburger-inner"]}></span>
        </span>
      </button>
    );
  }
}

export default HamburguerButton;
