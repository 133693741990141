import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Review from "../review";

class ReviewList extends React.Component {
  static defaultProps = {};

  static propTypes = {
    data: PropTypes.array,
    total: PropTypes.number.isRequired
  };

  render() {
    return (
      <>
        <h3 className={"mb-4"}>Reviews ({this.props.total})</h3>
        <ul>
          {this.props.data.map(review => (
            <li>
              <Review
                data={review}
                editable={true}
                onEditClick={this.props.onEditClick}
              />
            </li>
          ))}
        </ul>
      </>
    );
  }
}

export default ReviewList;
