import React from "react";

class ErrorMessage extends React.PureComponent {
  static defaultProps = {};

  static propTypes = {};

  render() {
    return (
      <div className={this.props.className}>
        <p
          className={
            "rounded border bg-red-200 border-red-300 font-serif p-2 text-sm"
          }
        >
          {this.props.children}
        </p>
      </div>
    );
  }
}

export default ErrorMessage;
