import React from "react";
import ThreeDots from "../three-dots";

export default class LoadingComponent extends React.PureComponent {
  render() {
    return (
      <span className={"px-6 py-3 font-serif"}>
        Loading <ThreeDots />
      </span>
    );
  }
}
