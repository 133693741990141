import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Collapsible from "../collapsible";
import FacilityIcon from "../facility-icon";
import FacilityTitle from "../facility-title";
import FacilityCard from "../facility-card";
import { withRouter } from "react-router";
import { openReviewModal } from "../../redux/reducers/map/actions";
import { connect } from "react-redux";

class FacilityList extends React.Component {
  static defaultProps = {
    onMouseEnter: id => {},
    onMouseLeave: id => {}
  };

  static propTypes = {
    expandedGroup: PropTypes.object,
    triggerOpenFacility: PropTypes.object,
    stadium: PropTypes.object.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      glazing: null
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.triggerOpenFacility !== this.props.triggerOpenFacility &&
      this.props.triggerOpenFacility !== null
    ) {
      const id = this.props.triggerOpenFacility.id;
      this.setState(
        {
          glazing: id
        },
        () => {
          clearTimeout(this.glazingTO);
          this.glazingTO = setTimeout(() => {
            this.setState(prevState => {
              if (prevState.glazing === id) {
                return { glazing: null };
              }
            });
          }, 1500);
        }
      );
    }
  }

  render() {
    return (
      <Collapsible
        expandedGroup={this.props.expandedGroup}
        data={
          this.props.stadium.attributes.facilities &&
          this.props.stadium.attributes.facilities.map(type => {
            const filteredFacilities = this.props.stadium.relationships.facilities.filter(
              f => f.type === type
            );

            return {
              title: type,
              icon: <FacilityIcon icon={type} className={"mr-3"} />,
              preview: filteredFacilities.map(f => {
                const link = `/map/stadium/${this.props.stadium.slug}/${this.props.location.search}${this.props.location.hash}`;

                return (
                  <FacilityTitle
                    data={f}
                    key={f.id}
                    highlighted={this.props.highlighted === f.id}
                    onMouseEnter={() => this.props.onMouseEnter(f.id)}
                    onMouseLeave={() => this.props.onMouseLeave(f.id)}
                    onClick={() => this.props.history.push(link)}
                  />
                );
              }),
              content: filteredFacilities.map(f => {
                const link = {
                  to: `/map/stadium/${this.props.stadium.slug}/facility/${f.what3words}/${this.props.location.search}${this.props.location.hash}`
                };

                return (
                  <FacilityCard
                    highlighted={this.props.highlighted === f.id}
                    key={f.id}
                    glaze={this.state.glazing === f.id}
                    onMouseEnter={() => this.props.onMouseEnter(f.id)}
                    onMouseLeave={() => this.props.onMouseLeave(f.id)}
                    data={f}
                    onReviewClick={() => this.props.dispatchOpenReviewModal(f)}
                    showReviews={true}
                    showFewReviews={true}
                    readMoreLink={link}
                  />
                );
              })
            };
          })
        }
      />
    );
  }
}

export default connect(null, dispatch => ({
  dispatchOpenReviewModal: payload => dispatch(openReviewModal(payload))
}))(withRouter(FacilityList));
