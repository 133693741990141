import React from "react";
import UserCard from "../user-card";
import PropTypes from "prop-types";
import Stars from "../stars";
import { connect } from "react-redux";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Review extends React.PureComponent {
  static defaultProps = {
    editable: false,
    currentUser: null
  };
  static propTypes = {
    data: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    currentUser: PropTypes.number
  };
  render() {
    const data = this.props.data;
    const isEditable =
      this.props.editable &&
      this.props.currentUser &&
      this.props.currentUser.id === data.user.id;

    return (
      <>
        <UserCard
          avatar={data.user.avatar}
          fullName={data.user.full_name}
          widget={<Stars total={data.rating} count={false} showTotal={false} />}
        />
        <p className={"mt-3 font-serif text-sm"}>{data.comment}</p>
        {isEditable && (
          <div className={"text-right"}>
            <button
              className={"text-blue-700 font-bold text-xs"}
              onClick={this.props.onEditClick}
            >
              <FontAwesomeIcon icon={faPencilAlt} className={"mr-1"} />
              Edit review
            </button>
          </div>
        )}
      </>
    );
  }
}
export default connect(
  state => ({
    currentUser: state.login.loggedInUser
  }),
  null
)(Review);
