import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import CardList from "../../components/card-list";
import { connect } from "react-redux";
import {
  stadiumMouseEnter,
  stadiumMouseLeave
} from "../../redux/reducers/map/actions";
import LoadingComponent from "../../components/loading-component";
import ErrorMessage from "../../components/error-message";

class SidebarNearBy extends React.Component {
  static defaultProps = {
    stadiumsInArea: [],
    currentPage: 1
  };

  static propTypes = {
    match: PropTypes.any.isRequired,
    stadiumsInArea: PropTypes.array,
    currentPage: PropTypes.number
  };
  constructor(props) {
    super(props);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter(id) {
    this.props.dispatchMouseEnter(id);
  }
  onMouseLeave(id) {
    this.props.dispatchMouseLeave(id);
  }

  render() {
    if (this.props.error !== null) {
      return (
        <ErrorMessage className={"py-6 px-4"}>{this.props.error}</ErrorMessage>
      );
    }

    if (this.props.stadiumsInArea === null) {
      return <LoadingComponent />;
    }

    // Add links to the stadiums
    let data = this.props.stadiumsInArea.map(stadiumData => {
      const copy = { ...stadiumData };
      copy.link = {
        to: `/map/stadium/${stadiumData.slug}`
      };

      return copy;
    });

    // Calculate the pagination offset
    const offset = (this.props.currentPage - 1) * 10;

    return (
      <>
        {data.length === 0 ? (
          <p className={"p-6 text-gray-700 text-center"}>
            There are no stadiums in the selected area. Please try in a
            different location.
          </p>
        ) : (
          <CardList
            dataSet={data.slice(offset, offset + 10)}
            onMouseLeave={this.onMouseLeave}
            onMouseEnter={this.onMouseEnter}
            highlighted={this.props.highlightedMarker}
          />
        )}
      </>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      stadiumsInArea: state.map.exploreMode.stadiums,
      currentPage: state.map.exploreMode.sidebarCurrentPage,
      error: state.map.exploreMode.error,
      highlightedMarker: state.map.mapAreaTrigger.highlightedMarker
    }),
    dispatch => ({
      dispatchMouseEnter: payload => dispatch(stadiumMouseEnter(payload)),
      dispatchMouseLeave: payload => dispatch(stadiumMouseLeave(payload))
    })
  )(SidebarNearBy)
);
