import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withRouter, matchPath } from "react-router";
import { Link } from "react-router-dom";

const activeTabClassName =
  "font-bold border-r border-l border-t -mb-px bg-white first:border-l-0 text-blue-900 pr-1";
const inactiveTabClassName = "text-blue-600";

class TabContainer extends React.PureComponent {
  static defaultProps = {
    data: []
  };
  static propTypes = {
    data: PropTypes.array
  };

  getActiveTab(data) {
    let longestMatch = null;
    data.forEach(tab => {
      let isActive = matchPath(this.props.location.pathname, {
        path: tab.label.location,
        exact: false,
        strict: false
      });
      if (isActive) {
        if (
          !longestMatch ||
          tab.label.location.length > longestMatch.label.location.length
        ) {
          longestMatch = tab;
        }
      }
    });

    return longestMatch;
  }

  render() {
    const activeTab = this.getActiveTab(this.props.data);
    /**
     * @var this.props.data [{
     *     string label,
     *     React.PureComponent content(),
     * }]
     */
    let labels = [];
    let content = <></>;
    let footer = this.props.footer && this.props.footer[activeTab.key] && (
      <footer className={"flex-0 text-sm p-2 border-t"}>
        {this.props.footer[activeTab.key]}
      </footer>
    );

    this.props.data.forEach(tab => {
      const isActive = tab === activeTab;
      const linkTo = tab.label.location;
      const onClick = tab.label.onClick;

      let tabElement = tab.label.text;

      if (typeof onClick !== "undefined") {
        tabElement = (
          <button
            className={"py-2 px-6 inline-block"}
            onClick={e => {
              e.preventDefault();
              onClick();
            }}
          >
            {tabElement}
          </button>
        );
      } else {
        tabElement = (
          <Link
            to={!isActive ? linkTo : ""}
            className={"py-2 px-6 inline-block"}
          >
            {tabElement}
          </Link>
        );
      }

      labels.push(
        <li
          key={tab.key}
          className={classNames([
            "flex-0",
            "text-sm",
            "cursor-pointer",
            isActive && activeTabClassName,
            !isActive && inactiveTabClassName
          ])}
        >
          {tabElement}
        </li>
      );

      if (isActive) {
        content = (
          <div className={"overflow-y-scroll flex-1"}>{tab.content}</div>
        );
      }
    });

    const tabList = (
      <nav className={"flex-none"}>
        <ul className={"flex border-b"}>{labels}</ul>
      </nav>
    );
    return (
      <div
        className={classNames([
          this.props.className,
          "h-full w-full flex flex-col flex-1 min-h-0"
        ])}
      >
        {tabList}
        {content}
        {footer}
      </div>
    );
  }
}

export default withRouter(TabContainer);
