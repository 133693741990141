import Api from "../api";

class DropdownSearcher {
  constructor() {
    this.lastFetchRequest = null;
  }
  searchFromHistory(q) {
    if (q === "") {
      return [];
    }

    const historyRecords =
      JSON.parse(window.localStorage.getItem("search-history")) || [];

    return historyRecords
      .filter(record => {
        const match = record !== null && record.match(q);
        return match !== null;
      })
      .slice(0, 3);
  }
  searchFromLikes(q, lat, lng) {
    return new Promise((resolve, reject) => {
      if (q === "" || q.length < 3) {
        return resolve({
          myPlaces: []
        });
      }
      // Resolve from myPlaces and suggestion
      Api.dropdownSearchLikes(q, lat, lng)
        .then(result => {
          resolve({
            myPlaces: result.data.savedPlaces || []
          });
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  }
  search(q, lat, lng) {
    return new Promise((resolve, reject) => {
      if (q === "" || q.length < 3) {
        return resolve({
          myPlaces: [],
          suggestions: []
        });
      }
      // Resolve from myPlaces and suggestion
      Api.dropdownSearch(q, lat, lng)
        .then(result => {
          if (result && typeof result.data !== "undefined") {
            resolve({
              suggestions: result.data.suggestions || [],
              stadiums: result.data.stadiums || [],
              cities: result.data.cities || [],
              near_me: result.data.near_me || []
            });
          } else {
            console.error("Data in invalid format, no data key on json");
            reject();
          }
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  }
}
export default new DropdownSearcher();
