import React from "react";
import { connect } from "react-redux";
import LoadingComponent from "../../../components/loading-component";
import TabContainer from "../../../components/tab-container";
import SidebarMyPlaces from "../../../views/sidebar-my-places";
import Pagination from "../../../components/pagination";
import { myPlacesPageChanged } from "../../../redux/reducers/login/actions";
import ExploreThisArea from "../../../components/explore-this-area";

class MapDefaultPage extends React.Component {
  constructor(props) {
    super(props);
    this.exploreThisArea = this.exploreThisArea.bind(this);
  }

  exploreThisArea() {
    this.props.onExploreThisArea(this.props.mapArea);
  }
  render() {
    const sidebar = (
        <>
      <TabContainer
        data={[
          {
            key: "nearby",
            label: {
              location: "/map",
              text: "Nearby",
              onClick: () => {
                if (this.props.mapArea) {
                  this.props.history.push(
                    `/map/${this.props.mapArea.center.lat}/${this.props.mapArea.center.lng}/${this.props.mapArea.zoom}`
                  );
                }
              }
            },
            content: (
              <div className={"p-6 text-gray-700 text-center"}>
                <p>
                  Enable location tracking on your browser or choose a location
                  from the map to start exploring. Once you found your location
                  click on:
                </p>
                <p>
                  <ExploreThisArea
                    onClick={this.exploreThisArea}
                    className={"border relative text-black"}
                  />
                </p>
              </div>
            )
          },
          {
            key: "myplaces",
            label: {
              location: "/map/my-places",
              text: "My Places"
            },
            content: <SidebarMyPlaces />
          }
        ]}
        footer={{
          nearby: null,
          myplaces:
            this.props.myPlacesData !== null ? (
              <Pagination
                onPageChanged={payload => {
                  this.props.dispatchMyPlacesPageChanged(payload);
                }}
                currentPage={this.props.currentPageMyPlaces}
                totalPages={Math.ceil(this.props.myPlacesData.length / 10)}
              />
            ) : this.props.loadingMyPlaces ? (
              <LoadingComponent />
            ) : null
        }}
      />
            <span aria-hidden={true} data-testid={"page-map-default"}/>
      </>
    );

    return sidebar;
  }
}

export default connect(
  state => ({
    myPlacesData: state.login.myPlaces.stadiums,
    loadingMyPlaces: state.login.myPlaces.loading,
    currentPageMyPlaces: state.login.myPlaces.sidebarCurrentPage,
    mapArea: state.map.mapArea
  }),
  dispatch => ({
    dispatchMyPlacesPageChanged: payload =>
      dispatch(myPlacesPageChanged(payload))
  })
)(MapDefaultPage);
