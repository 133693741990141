export function getUserLocation() {
  if (typeof window !== "undefined") {
    return new Promise((resolve, reject) =>
      window.navigator.geolocation.getCurrentPosition(data => {
        resolve({
          lat: data.coords.latitude,
          lng: data.coords.longitude
        });
      }, reject)
    );
  } else {
    return new Promise((resolve, reject) => reject());
  }
}
