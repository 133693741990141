import React from "react";
import LandingPage from "../../layouts/landing-page";
import BannerTitle from "../../components/banner-title";
import TabContainer from "../../components/tab-container-fs";
import SearchMap from "../../views/search-map";
import SearchStories from "../../views/search-stories";
import SearchNews from "../../views/search-news";
import { matchPath, generatePath, withRouter } from "react-router";
import GetLastLocationOrGeolocateUser from "../../services/location";
import { connect } from "react-redux";
import { clearAllSearchResults } from "../../redux/reducers/search/actions";

class Search extends React.Component {
  componentWillUnmount() {
    this.props.dispatchClearAllSearchResults();
  }

  render() {
    return (
      <LandingPage>
        <span aria-hidden={true} data-testid={"page-search"} />
        <GetLastLocationOrGeolocateUser />
        <div className={"flex-1 h-full overflow-auto"}>
          <BannerTitle>
            Search results: <strong>{this.props.match.params.query}</strong>
          </BannerTitle>
          <TabContainer
            matchPath={path => {
              return matchPath(this.props.location.pathname, { path });
            }}
            url={paths => {
              const path = paths[0];
              return generatePath(path, {
                query: this.props.match.params.query
              });
            }}
            tabs={[
              {
                key: "map",
                label: "Map",
                paths: ["/search/:query", "/search/map/:query"],
                content: <SearchMap />
              },
              {
                key: "stories",
                label: "Stories",
                paths: ["/search/stories/:query"],
                content: <SearchStories />
              },
              {
                key: "news",
                label: "News",
                paths: ["/search/news/:query"],
                content: <SearchNews />
              }
            ]}
          />
        </div>
      </LandingPage>
    );
  }
}

export default connect(
  state => ({}),
  dispatch => ({
    dispatchClearAllSearchResults: payload =>
      dispatch(clearAllSearchResults(payload))
  })
)(withRouter(Search));
