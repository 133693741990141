import Axios from "axios";
import Auth from "./auth";
const CancelToken = Axios.CancelToken;
let cancelQueues = {};
function post(url, data = null, cancelQueue = false) {
  return new Promise(function(resolve, reject) {
    if (cancelQueue && typeof cancelQueues[cancelQueue] == "function") {
      cancelQueues[cancelQueue]();
    }
    Axios.post(
      url,
      data,
      Auth.withAuthHeader({
        cancelToken: new CancelToken(c => {
          // An executor function receives a cancel function as a parameter
          if (cancelQueue) {
            cancelQueues[cancelQueue] = c;
          }
        }),
        responseType: "json"
      })
    )
      .then(response => {
        if (typeof response.data !== "undefined") {
          resolve(response.data);
        } else {
          reject(`There's been an error retrieving the data from the server.
            If the problem persists please try again after refreshing this page.`);
        }
      })
      .catch(e => {
        if (Axios.isCancel(e)) {
          console.log("post Request canceled");
        } else {
          reject(`Failed to process your request. Please try again.`);
        }
      });
  });
}

function get(url, cancelQueue = false) {
  return new Promise(function(resolve, reject) {
    if (cancelQueue && typeof cancelQueues[cancelQueue] == "function") {
      cancelQueues[cancelQueue]();
    }
    Axios.get(
      url,
      Auth.withAuthHeader({
        cancelToken: new CancelToken(c => {
          // An executor function receives a cancel function as a parameter
          if (cancelQueue) {
            cancelQueues[cancelQueue] = c;
          }
        }),
        responseType: "json"
      })
    )
      .then(response => {
        if (typeof response.data !== "undefined") {
          resolve(response.data);
        } else {
          reject(`There's been an error retrieving the data from the server.
            If the problem persists please try again after refreshing this page.`);
        }
      })
      .catch(e => {
        if (Axios.isCancel(e)) {
          console.log("post Request canceled");
        } else {
          reject(`There's been an error retrieving the data from the server.
            If the problem persists please try again after refreshing this page.`);
        }
      });
  });
}

function httpDelete(url) {
  return new Promise(function(resolve, reject) {
    Axios.delete(url, Auth.withAuthHeader({ responseType: "json" }))
      .then(response => {
        resolve(response.data);
      })
      .catch(e => {
        reject(`Failed to process your request. Please try again.`);
      });
  });
}
function put(url, data) {
  return new Promise(function(resolve, reject) {
    Axios.put(url, data, Auth.withAuthHeader({ responseType: "json" }))
      .then(response => {
        resolve(response.data);
      })
      .catch(e => {
        reject(`Failed to process your request. Please try again.`);
      });
  });
}

export default class Api {
  static explore(mapArea) {
    const endpoint = "/api/v1/stadium/explore/";
    const coordinates = [
      mapArea.ne.lat,
      mapArea.ne.lng,
      mapArea.sw.lat,
      mapArea.sw.lng
    ];

    const url = endpoint + coordinates.join("/");

    return get(url, "stadium_explore_search");
  }

  // This is a small microservice
  static dropdownSearch(searchString, lat, lng) {
    const endpoint = "/api/v1/stadium/dropdownsearch/";
    const encodedString = encodeURIComponent(searchString);
    const url = `${endpoint}${encodedString}/${lat}/${lng}/`;

    return get(url, "dropdown_search");
  }

  // This is in Laravel
  static dropdownSearchLikes(searchString, lat, lng) {
    const endpoint = "/api/v1/stadium/dropdownsavedsearch/";
    const url = `${endpoint}${searchString}/${lat}/${lng}/`;

    return get(url, "dropdown_search_likes");
  }
  static mapSearch(searchString, lat = null, lng = null, ratingFilter = 0) {
    const endpoint = "/api/v1/search/stadiums/";
    let url = `${endpoint}${searchString}/`;
    if (lat && lng) {
      url += `${lat}/${lng}/`;
    }
    if (ratingFilter) {
      url += `${ratingFilter}`;
    }

    return get(url, "stadium_explore_search");
  }

  static citySearch(state, city, ratingFilter = 0) {
    const endpoint = "/api/v1/search/city";
    let url = `${endpoint}/${state}/${city}/`;
    if (ratingFilter) {
      url += `${ratingFilter}/`;
    }

    return get(url, "stadium_explore_search");
  }

  static newsSearch(searchString) {
    const endpoint = "/api/v1/search/news/";
    const url = `${endpoint}${searchString}`;

    return get(url);
  }

  static getFacility(stadiumid, facilityid) {
    const url = `/api/v1/stadium/${stadiumid}/facility/${facilityid}`;
    return get(url);
  }

  static getStadium(id) {
    return get("/api/v1/stadium/" + id, "stadium_explore_search");
  }

  static signIn(data) {
    return post("/oauth/signin", data);
  }
  static signUp(data) {
    return post("/oauth/signup", data);
  }
  static recoverPassword(data) {
    return post("/oauth/recover", data);
  }
  static likeFacility(id) {
    return post(`/api/v1/facility/${id}/like`, "like");
  }
  static dislikeFacility(id) {
    return httpDelete(`/api/v1/facility/${id}/like`, "like");
  }
  static getMyPlaces() {
    return get("/api/v1/myPlaces", "my_places");
  }
  static postReview(id, data) {
    return post(`/api/v1/facility/${id}/reviews`, data);
  }
  static putReview(fid, rid, data) {
    return put(`/api/v1/facility/${fid}/reviews/${rid}`, data);
  }
  static deleteReview(fid, rid) {
    return httpDelete(`/api/v1/facility/${fid}/reviews/${rid}`);
  }
  static resetToken(data) {
    return post(`/oauth/passwordReset`, data);
  }
}
