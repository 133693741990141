import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import Stars from "../stars";
import FacilityIcon from "../facility-icon";

class StadiumSearchResultCard extends React.PureComponent {
  static defaultProps = {
    link: {},
    data: {}
  };

  static propTypes = {
    link: PropTypes.object,
    data: PropTypes.object
  };

  render() {
    let baseClassName = "";

    if (this.props.link && (this.props.link.href || this.props.link.to)) {
      baseClassName += "cursor-pointer hover:bg-blue-100 px-6 py-3";
    }
    const card = (
      <article className={classNames([this.props.className, baseClassName])}>
        <div className={"w-full flex"}>
          <picture className={"flex-none mr-3"} style={{ width: "128px" }}>
            {this.props.data.attributes && this.props.data.attributes.picture && (
              <picture>
                <img
                  alt="sample"
                  srcSet={this.props.data.attributes.picture}
                  src={this.props.data.attributes.picture}
                  style={{ width: "128px" }}
                />
              </picture>
            )}
          </picture>
          <div className={"flex-1 relative"}>
            <h3 className={"font-bold"}>{this.props.data.name}</h3>

            {this.props.liked && (
              <span className={"absolute right-0 top-0"}>
                <FontAwesomeIcon
                  className={"text-gray-500"}
                  icon={faHeartSolid}
                  title={"From your saved places"}
                />
              </span>
            )}

            <p className={"font-serif text-gray-500"}>
              <Stars
                count={this.props.data.rating_counter}
                total={this.props.data.rating_total}
              />
            </p>
            <p className={"font-serif text-gray-500"}>
              <small>{this.props.data.address}</small>
            </p>
            <ul className={"flex"}>
              {this.props.data.facility_types.split("|").map(t => (
                <li key={t}>
                  <FacilityIcon icon={t} className={"mr-3"} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </article>
    );

    if (this.props.link && this.props.link.to) {
      return (
        <Link to={this.props.link.to} target={this.props.link.target}>
          {card}
        </Link>
      );
    } else if (this.props.link && this.props.link.href) {
      return (
        <a href={this.props.link.href} target={this.props.link.target}>
          {card}
        </a>
      );
    } else {
      return card;
    }
  }
}

export default StadiumSearchResultCard;
