import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartOutline } from "@fortawesome/free-regular-svg-icons";
import { connect } from "react-redux";
import Api from "../../services/api";
import {
  facilityLike,
  facilityDislike,
  updateGeneralError
} from "../../redux/reducers/map/actions";
import { showModal } from "../../redux/reducers/login/actions";

const highlightedClassName = "bg-gray-200";
export class FacilityTitle extends React.PureComponent {
  static defaultProps = {
    data: {},
    link: {},
    loggedInUser: null
  };

  static propTypes = {
    className: PropTypes.any,
    data: PropTypes.object,
    loggedInUser: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.like = this.like.bind(this);
    this.dislike = this.dislike.bind(this);
    this.showSignIn = this.showSignIn.bind(this);
  }
  like() {
    if (this.props.loggedInUser === null) {
      return this.showSignIn();
    }

    Api.likeFacility(this.props.data.id)
      .then(() => this.props.dispatchLike(this.props.data.id))
      .catch(error => {
        this.props.dispatchUpdateGeneralError(error);
      });
  }
  dislike() {
    if (this.props.loggedInUser === null) {
      return this.showSignIn();
    }

    Api.dislikeFacility(this.props.data.id)
      .then(() => this.props.dispatchDislike(this.props.data.id))
      .catch(error => {
        this.props.dispatchUpdateGeneralError(error);
      });
  }

  showSignIn() {
    this.props.dispatchShowModal();
  }

  render() {
    let baseClassName = "pt-2 cursor-pointer";

    return (
      <article
        className={classNames([
          this.props.className,
          baseClassName,
          this.props.highlighted ? highlightedClassName : null
        ])}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        onClick={this.props.onClick}
      >
        <div className={"w-full pl-6 pr-3 pb-2"}>
          <div className={"pr-4 relative"}>
            <span className={"absolute right-0"}>
              <FontAwesomeIcon
                className={"text-red-500 cursor-pointer"}
                onClick={e => {
                  e.preventDefault();

                  this.props.data.liked === true ? this.dislike() : this.like();
                }}
                icon={
                  this.props.data.liked === true ? faHeartSolid : faHeartOutline
                }
                title={"Save to my places"}
              />
            </span>
            <h3 className={"font-bold text-xl flex-full"}>
              {this.props.data.name}
            </h3>
          </div>
        </div>
      </article>
    );
  }
}

export default connect(
  state => ({
    loggedInUser: state.login.loggedInUser
  }),
  dispatch => ({
    dispatchLike: payload => dispatch(facilityLike(payload)),
    dispatchDislike: payload => dispatch(facilityDislike(payload)),
    dispatchUpdateGeneralError: payload =>
      dispatch(updateGeneralError(payload)),
    dispatchShowModal: payload => dispatch(showModal(payload))
  })
)(FacilityTitle);
