import React from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import kebabCase from "lodash/kebabCase";
import s from "./Collapsible.module.scss";

export class Collapsible extends React.Component {
  static defaultProps = {
    data: [],
    expandedGroup: null
  };
  static propTypes = {
    expandedGroup: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      active: null,
      glazing: null
    };
    this.refArray = [];
    this.titleClick = this.titleClick.bind(this);
  }
  getRef(i) {
    if (!this.refArray[i]) {
      this.refArray[i] = React.createRef();
    }
    return this.refArray[i];
  }
  titleClick(i) {
    return (scroll = true) => {
      const isClosing = this.state.active === i;

      this.setState({
        active: isClosing ? null : i
      });
      if (scroll && !isClosing) {
        const current = this.getRef(i).current;
        if (current) {
          setTimeout(() => {
            current.scrollIntoView({ alignToTop: true, behavior: "smooth" });
          }, 200);
        }
      }
    };
  }
  isActive(i) {
    return this.state.active === i;
  }
  openAndGlaze(i) {
    this.setState(
      {
        active: i,
        glazing: i
      },
      () => {
        clearTimeout(this.glazingTO);
        this.glazingTO = setTimeout(() => {
          this.setState(prevState => {
            if (prevState.glazing === i) {
              return { glazing: null };
            }
          });
        }, 1500);

        const current = this.getRef(i).current;
        if (current) {
          current.scrollIntoView({ alignToTop: true, behavior: "smooth" });
        }
      }
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.expandedGroup) !==
        JSON.stringify(this.props.expandedGroup) &&
      this.props.expandedGroup.index !== null
    ) {
      if (this.props.expandedGroup.glaze) {
        this.openAndGlaze(this.props.expandedGroup.index);
      } else if (this.state.active !== this.props.expandedGroup.index) {
        // Condition to avoid closing it when already expanded
        this.titleClick(this.props.expandedGroup.index)(false);
      }
    }
  }

  render() {
    let i = 0;
    return (
      <dl>
        {this.props.data.map(data => (
          <React.Fragment key={i}>
            <dt
              ref={this.getRef(i)}
              key={`dt${i}`}
              onClick={this.titleClick(i)}
              className={
                "flex justify-between align-center py-2 pl-4 pr-3 bg-gray-650 cursor-pointer"
              }
              id={kebabCase(data.title)}
            >
              <h2 className={"font-bold text-xs flex"}>
                {data.icon}
                {data.title}
              </h2>
              {this.isActive(i) ? (
                <FontAwesomeIcon
                  size={"sm"}
                  icon={faChevronUp}
                  className={"mr-0.5"}
                />
              ) : (
                <FontAwesomeIcon
                  size={"sm"}
                  icon={faChevronDown}
                  className={"mr-0.5"}
                />
              )}
            </dt>
            {this.isActive(i++) ? (
              <dd
                key={`dd${i - 1}`}
                className={this.state.glazing === i - 1 && s["glazing"]}
              >
                {data.content}
              </dd>
            ) : (
              <dd key={`dd${i - 1}`}>{data.preview}</dd>
            )}
          </React.Fragment>
        ))}
      </dl>
    );
  }
}

export default Collapsible;
