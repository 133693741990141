import loadable from "@loadable/component";
import stub from "./stub";

export const CLUSTER_MARKER_SRC = "/img/map/marker-cluster.png";
export const CLUSTER_HOVER_MARKER_SRC = "/img/map/marker-cluster_hover.png";
export const MARKER_SRC = "/img/map/marker.png";
export const MARKER_HOVER_SRC = "/img/map/marker_hover.png";
export const HEART_MARKER_SRC = "/img/map/marker-heart.png";
export const HEART_HOVER_MARKER_SRC = "/img/map/marker-heart_hover.png";
export const FACILITY_MARKER_SRC = "/img/map/marker-facility.png";
export const FACILITY_HOVER_MARKER_SRC = "/img/map/marker-facility_hover.png";

let returnable = stub;
if (process.env.REACT_APP_IS_SSR !== "true") {
  returnable = loadable(() => import("./map"));
}
export default returnable;
