import React from "react";
import { connect } from "react-redux";
import { updateGeneralError } from "../../redux/reducers/map/actions";
import ReviewForm from "../../components/review-form";
import UserCard from "../../components/user-card";
import Api from "../../services/api";
import PropTypes from "prop-types";

export class ReviewFacilityForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewComment: "",
      reviewRating: 0
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    // If edit vs if new
    const editableReview = this.getEditableReview();
    Api.deleteReview(this.props.facility, editableReview.id)
      .then(answer => {
        window.location.reload();
      })
      .catch(e => {
        this.props.dispatchUpdateGeneralError(e);
      });
  }

  onSubmit(data) {
    // If edit vs if new
    const editableReview = this.getEditableReview();
    if (editableReview) {
      Api.putReview(this.props.facility, editableReview.id, data)
        .then(answer => {
          window.location.reload();
        })
        .catch(e => {
          this.props.dispatchUpdateGeneralError(e);
        });
    } else {
      Api.postReview(this.props.facility, data)
        .then(answer => {
          window.location.reload();
        })
        .catch(e => {
          this.props.dispatchUpdateGeneralError(e);
        });
    }
  }

  _getFormNew() {
    return (
      <ReviewForm user={this.props.loggedInUser} onSubmit={this.onSubmit} />
    );
  }

  _getFormEdit(review) {
    const { comment, rating } = review;
    return (
      <ReviewForm
        comment={comment}
        rating={rating}
        user={this.props.loggedInUser}
        onSubmit={this.onSubmit}
        onDelete={this.onDelete}
      />
    );
  }

  getEditableReview() {
    const filtered =
      this.props.facilityReviews?.filter(r => {
        return r.user?.id === this.props.loggedInUser.id;
      }) || [];

    return filtered.length > 0 ? filtered[0] : null;
  }

  render() {
    const editableReview = this.getEditableReview();
    const form = editableReview
      ? this._getFormEdit(editableReview)
      : this._getFormNew();
    return (
      <div className={"p-6"}>
        <UserCard
          avatar={this.props.loggedInUser.avatar}
          className={"pb-2"}
          fullName={`${this.props.loggedInUser.name} ${this.props.loggedInUser.last_name}`}
        />
        {form}
      </div>
    );
  }
}

export default connect(
  state => ({
    loggedInUser: state.login.loggedInUser,
    facilityReviews: state.map.reviewForm.facilityReviews,
    facility: state.map.reviewForm.facilityId
  }),
  dispatch => ({
    dispatchUpdateGeneralError: payload => dispatch(updateGeneralError(payload))
  })
)(ReviewFacilityForm);
