import React from "react";
import PropTypes from "prop-types";
import LoadingComponent from "../../components/loading-component";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import {
  updateMapSearchResults,
  updateMapSearchErrorMessage
} from "../../redux/reducers/search/actions";
import Api from "../../services/api";
import { withRouter } from "react-router";
import ErrorBanner from "../../components/error-banner";
import StadiumSearchResultCard from "../../components/stadium-search-result-card";

class SearchMap extends React.Component {
  static defaultProps = {};

  static propTypes = {
    data: PropTypes.array,
    error: PropTypes.string
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.query !== this.props.match.params.query) {
      this.fetch();
    }
  }

  fetch() {
    Api.mapSearch(
      this.props.match.params.query,
      this.props.userLocation.center && this.props.userLocation.center.lat,
      this.props.userLocation.center && this.props.userLocation.center.lng
    )
      .then(data => {
        this.props.dispatchUpdatedMapSearchResults(data.data.stadiums);
      })
      .catch(error => {
        this.props.dispatchUpdateMapSearchErrorMessage(error);
      });
  }
  componentDidMount() {
    this.fetch();
  }

  render() {
    let ret = [];
    if (this.props.data === null) {
      ret.push(<LoadingComponent />);
    } else if (isEmpty(this.props.data)) {
      ret.push(
        <div className={"p-6 font-serif"}>
          No results found. Please try a different search.
        </div>
      );
    } else {
      ret.push(
        <ul>
          {this.props.data.map(data => {
            return (
              <li key={data.slug}>
                <StadiumSearchResultCard
                  link={{ to: "/map/stadium/" + data.slug }}
                  className={"p-6 border-b"}
                  data={data}
                />
              </li>
            );
          })}
        </ul>
      );
    }
    if (this.props.error) {
      ret.push(<ErrorBanner>{this.props.error}</ErrorBanner>);
    }

    return ret;
  }
}

export default connect(
  state => ({
    data: state.search.map.results,
    error: state.search.map.error,
    userLocation: state.map.userLocation
  }),
  dispatch => ({
    dispatchUpdatedMapSearchResults: payload =>
      dispatch(updateMapSearchResults(payload)),
    dispatchUpdateMapSearchErrorMessage: payload =>
      dispatch(updateMapSearchErrorMessage(payload))
  })
)(withRouter(SearchMap));
