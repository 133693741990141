import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import history from "./history";

let preloadedState = {};
if (typeof window !== "undefined") {
  preloadedState = window.__PRELOADED_STATE__;
  delete window.__PRELOADED_STATE__;
}
export default configureStore({
  reducer: rootReducer(history),
  devTools: true,
  middleware: history !== null ? [routerMiddleware(history)] : null,
  preloadedState
});
