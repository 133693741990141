import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default class ReadMore extends React.PureComponent {
  static defaultProps = {
    label: "Read More"
  };
  static propTypes = {
    readMoreLink: PropTypes.object.isRequired
  };
  render() {
    return (
      <div className={this.props.className}>
        <div>{this.props.children}</div>
        <Link
          className={"text-sm block text-center text-blue-700 mt-2"}
          to={this.props.readMoreLink}
        >
          {this.props.label}
        </Link>
      </div>
    );
  }
}
