import React from "react";
import StarSelector from "../star-selector";
import PropTypes from "prop-types";
import classNames from "classnames";

const disabledClassName = "bg-blue-100";
const activeClassName = "bg-blue-700";

export default class ReviewForm extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    comment: PropTypes.string,
    rating: PropTypes.number
  };
  constructor(props) {
    super(props);
    this.rating = props.rating;
    this.comment = props.comment;
    this.state = {
      disabled: true,
      remainingCharacters: props.comment ? 280 - props.comment.length : 280
    };

    this.onTextAreaChange = this.onTextAreaChange.bind(this);
    this.onRatingChange = this.onRatingChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onTextAreaChange(value) {
    this.comment = value;
    this.validate();
    this.setState({
      remainingCharacters: 280 - value.length
    });
  }
  onRatingChange(value) {
    this.rating = value;
    this.validate();
  }
  validate() {
    // false if this should be enabled (validation passed)
    const newResult = !(this.comment && this.rating);
    if (this.state.disabled !== newResult) {
      this.setState({
        disabled: newResult
      });
    }
  }

  onSubmit() {
    this.props.onSubmit({
      comment: this.comment,
      rating: this.rating
    });
  }
  render() {
    return (
      <div>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.onSubmit();
          }}
        >
          <textarea
            name={"comment"}
            onChange={e => this.onTextAreaChange(e.target.value)}
            className={"bg-gray-200 py-3 px-4 mt-2 w-full"}
            style={{ height: "200px" }}
            maxLength={280}
            placeholder={
              "Share details of your own experience at this place..."
            }
          >
            {this.props.comment}
          </textarea>
          <p className={"text-xs text-right font-serif mb-6"}>
            {this.state.remainingCharacters} char. left
          </p>
          <footer className={"flex justify-end items-center"}>
            <button
              className={classNames([
                "mr-auto rounded-md text-blue-700 text-md border-blue-700 px-6 py-2 border"
              ])}
              onClick={e => {
                e.preventDefault();
                this.props.onDelete();
              }}
            >
              Delete
            </button>
            <StarSelector
              onChange={this.onRatingChange}
              initialValue={this.props.rating}
            />
            <button
              className={classNames([
                this.state.disabled ? disabledClassName : activeClassName,
                "ml-5 rounded-md text-white text-md  px-6 py-2"
              ])}
              disabled={this.state.disabled}
              type={"submit"}
            >
              Post
            </button>
          </footer>
        </form>
      </div>
    );
  }
}
