import { createReducer } from "@reduxjs/toolkit";

import initialState from "./initialState";
import * as Action from "./actions";

function clone(state) {
  return { ...state };
}

const actionsMap = {
  [Action.updateNewsSearchResults]: (state, { payload }) => {
    const newState = clone(state);
    newState.news = clone(state.news);

    newState.news.results = payload;
    newState.news.error = null;
    return newState;
  },
  [Action.updateNewsSearchErrorMessage]: (state, { payload }) => {
    const newState = clone(state);
    newState.news = clone(state.news);

    newState.news.error = payload;
    return newState;
  },
  [Action.updateMapSearchResults]: (state, { payload }) => {
    const newState = clone(state);
    newState.map = clone(state.map);

    newState.map.results = payload;
    newState.map.error = null;
    return newState;
  },
  [Action.updateMapSearchErrorMessage]: (state, { payload }) => {
    const newState = clone(state);
    newState.map = clone(state.map);

    newState.map.error = payload;
    return newState;
  },
  [Action.clearAllSearchResults]: (state, { payload }) => {
    const newState = clone(initialState);
    return newState;
  }
};

export default createReducer(initialState, actionsMap);
