import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Captcha from "../captcha";

const altButtonClass = "border rounded-md py-2 mt-3 bg-white";
const baseButtonClass = "text-white rounded-md py-2 mt-3";
const activeButtonClass = "bg-blue-700";
const disabledButtonClass = "bg-blue-200";

export default class PasswordRecoveryForm extends React.Component {
  static defaultProps = {
    onEmailUpdated: email => {},
    onCancel: () => {},
    errorMessage: "",
    autocompletedEmail: ""
  };
  static propTypes = {
    recoverPassword: PropTypes.func.isRequired,
    onEmailUpdated: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    autocompletedEmail: PropTypes.string,
    errorMessage: PropTypes.string,
    recaptchaKey: PropTypes.string.isRequired,
    captchaChallengeFails: PropTypes.func.isRequired,
    captchaChallengeStarts: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      email: ""
    };
    this.setEmail = this.setEmail.bind(this);
    this.refEmail = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.continueRecoveringPassword = this.continueRecoveringPassword.bind(
      this
    );
    this.refCaptcha = React.createRef();
    this.captchaStarted = false;
  }
  setEmail(email) {
    this.setState({ email });
    this.props.onEmailUpdated(email);
  }

  onSubmit() {
    this.refCaptcha.current.execute();
    this.props.captchaChallengeStarts();
  }

  componentDidMount() {
    this.setState({ email: this.props.autocompletedEmail });

    if (this.refEmail.current) {
      this.refEmail.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const disabled = !this.state.email;

    if (
      (prevState.disabled && !disabled) ||
      (!prevState.disabled && disabled)
    ) {
      this.setState({ disabled });
    }

    // props.disabled === true if the form was loading
    if (prevProps.disabled && !disabled) {
      this.refEmail.current.focus();
    }

    if (
      prevProps.errorMessage !== this.props.errorMessage &&
      this.props.errorMessage &&
      this.refCaptcha.current
    ) {
      this.failedRecovery();
    }
  }

  continueRecoveringPassword(token) {
    this.props.recoverPassword({
      email: this.state.email,
      "g-recaptcha-response": token
    });
  }

  failedRecovery() {
    this.refCaptcha.current.reset();
  }
  render() {
    const disabled = this.state.disabled || this.props.disabled;
    return (
      <div className={"flex flex-col px-6"}>
        {this.props.errorMessage && (
          <p className={"text-red-500 text-sm mb-1"}>
            {this.props.errorMessage}
          </p>
        )}
        <input
          placeholder={"Email"}
          onChange={e => this.setEmail(e.target.value)}
          type={"email"}
          value={this.state.email}
          className={"border border-gray-200 rounded-md py-1 px-2"}
          tabIndex={10}
          ref={this.refEmail}
        />
        <button
          type={"submit"}
          onClick={e => {
            e.preventDefault();
            this.onSubmit();
          }}
          disabled={disabled}
          className={classNames([
            baseButtonClass,
            disabled ? disabledButtonClass : activeButtonClass
          ])}
          tabIndex={11}
          minLength={8}
        >
          Reset Password
        </button>
        <button
          type={"button"}
          onClick={this.props.onCancel}
          className={altButtonClass}
          tabIndex={12}
        >
          Cancel
        </button>
        <Captcha
          recaptchaRef={this.refCaptcha}
          recaptchaKey={this.props.recaptchaKey}
          onErrored={this.props.captchaChallengeFails}
          onChange={this.continueRecoveringPassword}
        />
      </div>
    );
  }
}
