import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import s from "./sidebar.module.scss";

class Sidebar extends React.PureComponent {
  static propTypes = {
    containerClassName: PropTypes.string,
    unstyledContainer: PropTypes.bool
  };
  static defaultProps = {
    containerClassName: "",
    unstyledContainer: false
  };

  render() {
    const baseClassName = "flex-1 h-full min-h-0 flex flex-col";
    const defaultClassName = [
      this.props.containerClassName,
      "overflow-y-scroll"
    ];

    return (
      <aside
        className={classNames([
          this.props.className,
          s["sidebar"],
          "h-full bg-white shadow-xl flex flex-none flex-col min-h-0"
        ])}
      >
        {this.props.header}
        <div
          className={classNames([
            baseClassName,
            !this.props.unstyledContainer && defaultClassName
          ])}
        >
          {this.props.children}
        </div>
      </aside>
    );
  }
}

export default Sidebar;
