import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Stars from "../stars";

const options = {
  0: <span>Any rating</span>,
  1: <Stars total={1.0} showTotal={true} />,
  2: <Stars total={2.0} showTotal={true} />,
  3: <Stars total={3.0} showTotal={true} />,
  4: <Stars total={4.0} showTotal={true} />,
  5: <Stars total={5.0} showTotal={true} />
};

const optionBaseClass = "font-serif hover:bg-blue-100";
const optionActiveClass = "bg-blue-800 hover:bg-blue-800 text-white";

class RatingFilter extends React.Component {
  static defaultProps = {
    selected: 0
  };

  static propTypes = {
    selected: PropTypes.number,
    onChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
    this.open = this.open.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  open() {
    this.setState(prevState => ({
      opened: !prevState.opened
    }));
  }
  onChange(value) {
    this.props.onChange(value);
    this.setState({ opened: false });
  }

  render() {
    return (
      <div className={"relative"}>
        <button onClick={this.open}>
          Rating{" "}
          <FontAwesomeIcon icon={this.state.opened ? faCaretUp : faCaretDown} />
        </button>
        {this.state.opened && (
          <div
            className={`absolute left-0 bottom-0 transform translate-y-full z-10
            bg-white shadow-md pt-2`}
          >
            <ul>
              {Object.keys(options).map(key => (
                <li
                  key={key}
                  className={classNames([
                    optionBaseClass,
                    this.props.selected == key && optionActiveClass // eslint-disable-line
                  ])}
                >
                  <button
                    onClick={e => {
                      e.preventDefault();
                      this.onChange(parseInt(key));
                    }}
                    className={"w-full px-3 py-2"}
                  >
                    {options[key]}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default RatingFilter;
