import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import CardList from "../../components/card-list";
import { connect } from "react-redux";
import {
  stadiumMouseEnter,
  stadiumMouseLeave
} from "../../redux/reducers/map/actions";
import LoadingComponent from "../../components/loading-component";
import ErrorMessage from "../../components/error-message";

export class SidebarSearch extends React.Component {
  static defaultProps = {
    results: {},
    className: null,
    currentPage: 1
  };

  static propTypes = {
    results: PropTypes.object.isRequired,
    currentPage: PropTypes.number,
    className: PropTypes.string
  };
  constructor(props) {
    super(props);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter(id) {
    this.props.dispatchMouseEnter(id);
  }
  onMouseLeave(id) {
    this.props.dispatchMouseLeave(id);
  }

  render() {
    if (this.props.error !== null) {
      return (
        <div className={this.props.className}>
          <ErrorMessage className={"py-6 px-4"}>
            {this.props.error}
          </ErrorMessage>
        </div>
      );
    }

    if (this.props.results === null) {
      return (
        <div className={this.props.className}>
          <LoadingComponent />
        </div>
      );
    }

    // Add links to the stadiums
    let data = [];

    if (this.props.results.savedPlaces) {
      data = this.props.results.savedPlaces.map(stadiumData => {
        const copy = { ...stadiumData };
        copy.link = {
          to: `/map/stadium/${stadiumData.slug}`
        };

        return copy;
      });
    }
    // Add links to the stadiums
    data = [
      ...data,
      ...this.props.results.stadiums.map(stadiumData => {
        const copy = { ...stadiumData };
        copy.link = {
          to: `/map/stadium/${stadiumData.slug}`
        };

        return copy;
      })
    ];

    // Calculate the pagination offset
    const offset = (this.props.currentPage - 1) * 10;
    return (
      <div className={this.props.className}>
        {data.length === 0 ? (
          <p className={"p-6 text-gray-700 text-center"}>
            There are no results for the searched term.
          </p>
        ) : (
          <CardList
            dataSet={data.slice(offset, offset + 10)}
            onMouseLeave={this.onMouseLeave}
            onMouseEnter={this.onMouseEnter}
            highlighted={this.props.highlightedMarker}
          />
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      results: state.map.searchMode.results,
      currentPage: state.map.searchMode.sidebarCurrentPage,
      error: state.map.searchMode.error,
      highlightedMarker: state.map.mapAreaTrigger.highlightedMarker
    }),
    dispatch => ({
      dispatchMouseEnter: payload => dispatch(stadiumMouseEnter(payload)),
      dispatchMouseLeave: payload => dispatch(stadiumMouseLeave(payload))
    })
  )(SidebarSearch)
);
