import React from "react";
import { connect } from "react-redux";
import {
  updateSearchString,
  setCityModeCity,
  clearCityMode,
  newCitySearchStarted,
  updateCitySearchRatingFilter,
  updateCitySearchErrorMessage,
  updateCityModeSearchResults,
  citySearchPageChanged
} from "../../../redux/reducers/map/actions";
import { withRouter } from "react-router";
import Api from "../../../services/api";
import Pagination from "../../../components/pagination";
import LoadingComponent from "../../../components/loading-component";
import FilterBar from "../../../components/filter-bar";
import RatingFilter from "../../../components/rating-filter";
import SidebarCity from "../../../views/sidebar-city";

class MapCityPage extends React.Component {
  static defaultProps = {
    data: null
  };

  constructor(props) {
    super(props);
    this.fetch = this.fetch.bind(this);
    this.onRatingFilterChanged = this.onRatingFilterChanged.bind(this);
  }

  componentDidMount() {
    this.setNewSearch();
    this.fetch();
  }

  setNewSearch() {
    try {
      const state = decodeURIComponent(this.props.match.params.state);
      const city = decodeURIComponent(this.props.match.params.city);

      this.props.dispatchUpdateSearchString(`${city}, ${state}`);
      this.props.dispatchSetCityModeCity({
        state,
        city
      });
      this.props.dispatchNewCitySearchStarted();
    } catch (e) {
      this.props.history.replace("/map");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match.params.state !== this.props.match.params.state ||
      prevProps.match.params.city !== this.props.match.params.city
    ) {
      this.setNewSearch();
    } else {
      if (
        (prevProps.userLocation.center === null &&
          this.props.userLocation.center !== null) ||
        prevProps.ratingFilter !== this.props.ratingFilter
      ) {
        this.fetch();
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatchClearCityMode();
  }

  fetch() {
    Api.citySearch(
      this.props.match.params.state,
      this.props.match.params.city,
      this.props.ratingFilter
    )
      .then(data => {
        this.props.dispatchUpdateCityModeSearchResults(data.data);
      })
      .catch(error => {
        this.props.dispatchUpdateCitySearchErrorMessage(error);
      });
  }

  onRatingFilterChanged(value) {
    this.props.dispatchUpdateCitySearchRatingFilter(value);
  }

  render() {
    let totalPages = 0;

    if (this.props.data !== null) {
      totalPages += this.props.data.stadiums.length;
      if (this.props.data.savedPlaces) {
        totalPages += this.props.data.savedPlaces.length;
      }

      totalPages = Math.ceil(totalPages / 10);
    }

    const sidebar = (
      <>
        <FilterBar>
          <RatingFilter
            selected={this.props.ratingFilter}
            onChange={this.onRatingFilterChanged}
          />
        </FilterBar>
        <span aria-hidden={true} data-testid={"page-map-city"}/>
        <SidebarCity className={"h-full min-h-0 flex-1 overflow-y-auto"} />

        {this.props.data !== null ? (
          <div className={"flex-0 text-sm p-2 border-t"}>
            <Pagination
              onPageChanged={payload => {
                this.props.dispatchCitySearchPageChanged(payload);
              }}
              currentPage={this.props.currentPage}
              totalPages={totalPages}
            />
          </div>
        ) : (
          <div className={"flex-0 text-sm p-2 border-t"}>
            <LoadingComponent />
          </div>
        )}
      </>
    );

    return sidebar;
  }
}

export default connect(
  state => ({
    data: state.map.cityMode.results,
    currentPage: state.map.cityMode.sidebarCurrentPage,
    searching: state.map.cityMode.searching,
    userLocation: state.map.userLocation,
    ratingFilter: state.map.cityMode.ratingFilter
  }),
  dispatch => ({
    dispatchSetCityModeCity: payload => dispatch(setCityModeCity(payload)),
    dispatchCitySearchPageChanged: payload =>
      dispatch(citySearchPageChanged(payload)),
    dispatchUpdateSearchString: payload =>
      dispatch(updateSearchString(payload)),
    dispatchUpdateCityModeSearchResults: payload =>
      dispatch(updateCityModeSearchResults(payload)),
    dispatchNewCitySearchStarted: payload =>
      dispatch(newCitySearchStarted(payload)),
    dispatchUpdateCitySearchErrorMessage: payload =>
      dispatch(updateCitySearchErrorMessage(payload)),
    dispatchClearCityMode: payload => dispatch(clearCityMode(payload)),
    dispatchUpdateCitySearchRatingFilter: payload =>
      dispatch(updateCitySearchRatingFilter(payload))
  })
)(withRouter(MapCityPage));
