import React from "react";
import Logo from "../logo";
import Navigation from "../navigation";
import MainSearchBar from "../main-search-bar";
import classNames from "classnames";
import s from "./MainHeader.module.scss";
import PropTypes from "prop-types";
import HamburgerButton from "../hamburger-button";

class MainHeader extends React.PureComponent {
  static defaultProps = {
    onSearch: () => console.warn("property not assigned"),
    rightMenu: null
  };
  static propTypes = {
    onSearch: PropTypes.func.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false
    };
    this.mobileMenuOpen = this.mobileMenuOpen.bind(this);
    this.mobileMenuClose = this.mobileMenuClose.bind(this);
  }
  mobileMenuOpen() {
    this.setState({
      mobileMenuOpen: true
    });
  }
  mobileMenuClose() {
    this.setState({
      mobileMenuOpen: false
    });
  }

  render() {
    const hamburgerMenuStatusClass =
      this.state.mobileMenuOpen === true ? "translate-x-0" : "translate-x-full";
    return (
      <div className={s["main-header--container"]}>
        <header className={classNames([s["main-header"]])}>
          <Logo title={"National Athletics League"} />

          {/* Hamburguer menu container*/}
          <div
            className={classNames([
              hamburgerMenuStatusClass,
              s["main-header--mobilizable"]
            ])}
          >
            <Navigation className={"mb-4 md:mb-0"} />
            <MainSearchBar
              onChange={() => {}}
              className={"md:flex-1 mb-4 mt-4 md:mb-0 md:mt-0"}
              placeholder={
                "Search for a stadium, location, story, or news article..."
              }
              onSubmit={this.props.onSearch}
            />
            {this.props.rightMenu}
          </div>
          <HamburgerButton
            className={"md:hidden relative z-20"}
            onOpen={this.mobileMenuOpen}
            onClose={this.mobileMenuClose}
          />
        </header>
      </div>
    );
  }
}

export default MainHeader;
