import React from "react";

import CarouselLibrary from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import s from "./Carousel.module.scss";
import className from "classnames";
import PropTypes from "prop-types";

const responsive = {
  all: {
    breakpoint: { min: 0, max: 999999 },
    items: 3,
    partialVisibilityGutter: 10,
    slidesToSlide: 2
  }
};

function LeftArrow({ onClick, ...rest }) {
  return (
    <button
      onClick={() => onClick()}
      className={className([
        "absolute block rounded-full bg-blue-600 left-0 ml-2 text-white flex items-center justify-center",
        s["arrow"]
      ])}
    >
      <FontAwesomeIcon size={"sm"} icon={faChevronLeft} />
    </button>
  );
}
function RightArrow({ onClick, ...rest }) {
  return (
    <button
      onClick={() => onClick()}
      className={className([
        "absolute block rounded-full bg-blue-600 right-0 mr-2 text-white flex items-center justify-center",
        s["arrow"]
      ])}
    >
      <FontAwesomeIcon size={"sm"} icon={faChevronRight} />
    </button>
  );
}

export default class Carousel extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired
  };
  render() {
    return (
      <CarouselLibrary
        {...this.props}
        ssr={true}
        responsive={responsive}
        swipeable={true}
        draggable={false}
        showDots={false}
        customLeftArrow={<LeftArrow />}
        customRightArrow={<RightArrow />}
        partialVisbile={this.props.items.length > 3}
        keyBoardControl={false}
      >
        {this.props.items}
      </CarouselLibrary>
    );
  }
}
