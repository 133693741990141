import React from "react";
import PropTypes from "prop-types";
import LoadingComponent from "../../components/loading-component";
import { isEmpty } from "lodash";
import NewsCard from "../../components/news-card";
import { connect } from "react-redux";
import {
  updateNewsSearchResults,
  updateNewsSearchErrorMessage
} from "../../redux/reducers/search/actions";
import Api from "../../services/api";
import { withRouter } from "react-router";
import ErrorBanner from "../../components/error-banner";

class SearchNews extends React.Component {
  static defaultProps = {};

  static propTypes = {
    data: PropTypes.array,
    error: PropTypes.string
  };

  componentDidMount() {
    Api.newsSearch(this.props.match.params.query)
      .then(data => {
        this.props.dispatchUpdatedSearchResults(data.data);
      })
      .catch(error => {
        this.props.dispatchUpdateNewsSearchErrorMessage(error);
      });
  }

  render() {
    let ret = [];
    if (this.props.data === null) {
      ret.push(<LoadingComponent />);
    } else if (isEmpty(this.props.data)) {
      ret.push(
        <div className={"p-6 font-serif"}>
          No results found. Please try a different search.
        </div>
      );
    } else {
      ret.push(
        <ul>
          {this.props.data.map(data => (
            <li key={data.url}>
              <NewsCard className={"p-6 border-b"} data={data} />
            </li>
          ))}
        </ul>
      );
    }
    if (this.props.error) {
      ret.push(<ErrorBanner>{this.props.error}</ErrorBanner>);
    }

    return ret;
  }
}

export default connect(
  state => ({
    data: state.search.news.results,
    error: state.search.news.error
  }),
  dispatch => ({
    dispatchUpdatedSearchResults: payload =>
      dispatch(updateNewsSearchResults(payload)),
    dispatchUpdateNewsSearchErrorMessage: payload =>
      dispatch(updateNewsSearchErrorMessage(payload))
  })
)(withRouter(SearchNews));
