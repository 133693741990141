import React from "react";
import LandingPage from "../../layouts/landing-page";
import FullScreenMessage from "../../components/full-screen-message";

class Verified extends React.Component {
  render() {
    return (
      <LandingPage>
        <span aria-hidden={true} data-testid={"page-verified"} />
        <FullScreenMessage title={"Email Verification"}>
          Your account was verified successfully.
          <br />
          You can{" "}
          <a className={"underline text-blue-400"} href={"/map"}>
            go back to the website
          </a>{" "}
          and sign in.
        </FullScreenMessage>
      </LandingPage>
    );
  }
}

export default Verified;
