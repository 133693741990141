import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

class GoBackContainer extends React.PureComponent {
  static defaultProps = {};
  static propTypes = {
    backTo: PropTypes.string
  };
  constructor(props) {
    super(props);

    this.goBack = this.goBack.bind(this);
  }
  goBack() {
    if (this.props.backTo) {
      this.props.history.push(this.props.backTo);
      return;
    }

    if (
      window.document.referrer !== "" &&
      !window.document.referrer.match(/nalathletics\.com/) &&
      !window.document.referrer.match(/localhost/)
    ) {
      window.location.href = "/map";
    } else {
      this.props.history.goBack();
    }
  }

  render() {
    return (
      <div className={"min-h-0 h-full w-full flex flex-col"}>
        <nav className={"flex-none border-1 pt-1 pb-2 px-6 border-b"}>
          <button
            onClick={this.goBack}
            className={"text-xs font-bold text-blue-700"}
          >
            <FontAwesomeIcon icon={faChevronLeft} className={"mr-2 text-sm"} />
            Back
          </button>
        </nav>
        <div className={"overflow-y-scroll flex-1 "}>{this.props.children}</div>
      </div>
    );
  }
}

export default withRouter(GoBackContainer);
