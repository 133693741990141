import { createAction } from "@reduxjs/toolkit";

export const showPasswordRecoveryForm = createAction(
  "login/showPasswordRecoveryForm"
);
export const showSignInForm = createAction("login/showSignInForm");
export const showSignUpForm = createAction("login/showSignUpForm");
export const emailUpdated = createAction("login/emailUpdated");
export const signInAttempt = createAction("login/signInAttempt");
export const signUpAttempt = createAction("login/signUpAttempt");
export const passwordRecoveryAttempt = createAction(
  "login/passwordRecoveryAttempt"
);
export const showModal = createAction("login/showModal");
export const hideModal = createAction("login/hideModal");

export const updateSignInErrorMessage = createAction(
  "login/updateSignInErrorMessage"
);
export const updateSignUpErrorMessage = createAction(
  "login/updateSignUpErrorMessage"
);
export const updatePasswordRecoveryErrorMessage = createAction(
  "login/updatePasswordRecoveryErrorMessage"
);
export const updatePasswordRecoverySuccessMessage = createAction(
  "login/updatePasswordRecoverySuccessMessage"
);

export const signedIn = createAction("login/signedIn");
export const updateSignUpSuccessMessage = createAction(
  "login/updateSignUpSuccessMessage"
);
export const recoveredSession = createAction("login/recoveredSession");
export const logout = createAction("login/logout");
export const updateMyPlacesResults = createAction(
  "login/updateMyPlacesResults"
);
export const myPlacesPageChanged = createAction("login/myPlacesPageChanged");

export const myPlacesFetchError = createAction("login/myPlacesFetchError");

export const facilityLike = createAction("login/facilityLike");
export const facilityDislike = createAction("login/facilityDislike");

export const startLoadingMyPlaces = createAction("login/startLoadingMyPlaces");
