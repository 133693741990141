import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import map from "./reducers/map/reducer";
import login from "./reducers/login/reducer";
import search from "./reducers/search/reducer";
import history from "./history";

export default () =>
  combineReducers({
    router: history !== null ? connectRouter(history) : null,
    map,
    login,
    search
  });
