import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const baseButtonClass = "text-white rounded-md py-2 mt-3";
const activeButtonClass = "bg-blue-700";
const disabledButtonClass = "bg-blue-200";

class PasswordResetForm extends React.Component {
  static defaultProps = {
    errorMessage: "",
    disabled: false
  };
  static propTypes = {
    errorMessage: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.password = "";
    this.passwordRepeat = "";
    this.refPassword = React.createRef();
    this.setPassword = this.setPassword.bind(this);
    this.setRepeatPassword = this.setRepeatPassword.bind(this);
    this.showPasswordHint = this.showPasswordHint.bind(this);
    this.hidePassworodHint = this.hidePassworodHint.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      disabled: true,
      passwordHint: null
    };
  }
  setPassword(password) {
    this.password = password;
    this.validate();

    const colorClass = password.length >= 8 ? "text-blue-700" : "text-red-500";
    this.setState({
      passwordHint: (
        <span className={classNames([colorClass, "text-xs"])}>
          At least 8 characters
        </span>
      ),
      password
    });
  }
  setRepeatPassword(password) {
    this.passwordRepeat = password;
    this.validate();
  }

  componentDidMount() {
    if (this.refPassword.current) {
      this.refPassword.current.focus();
    }
  }

  validate() {
    if (
      this.passwordRepeat === this.password &&
      this.password !== "" &&
      this.password.length >= 8
    ) {
      this.setState({ disabled: false });
    } else {
      this.setState({ disabled: true });
    }
  }

  showPasswordHint() {
    this.setState({
      showPasswordHint: true
    });
  }
  hidePassworodHint() {
    this.setState({
      showPasswordHint: false
    });
  }

  onSubmit() {
    this.props.onSubmit(this.password);
  }

  render() {
    const disabled = this.state.disabled || this.props.disabled;
    return (
      <div className={"flex flex-col px-6"}>
        {this.props.errorMessage && (
          <p className={"text-red-500 text-sm mb-1"}>
            {this.props.errorMessage}
          </p>
        )}

        <input
          type={"email"}
          value={this.props.email}
          disabled={true}
          readOnly={true}
          className={"border border-gray-200 rounded-md py-1 px-2"}
        />

        <input
          placeholder={"Password"}
          onChange={e => this.setPassword(e.target.value)}
          type={"password"}
          className={"border border-gray-200 rounded-md py-1 px-2 mt-3"}
          tabIndex={1}
          ref={this.refPassword}
          minLength={8}
          onFocus={this.showPasswordHint}
          onBlur={this.hidePassworodHint}
        />
        {this.state.showPasswordHint && this.state.passwordHint}
        <input
          placeholder={"Repeat password"}
          onChange={e => this.setRepeatPassword(e.target.value)}
          type={"password"}
          className={"border border-gray-200 rounded-md py-1 px-2 mt-3"}
          tabIndex={2}
          minLength={8}
        />
        <button
          type={"submit"}
          onClick={e => {
            e.preventDefault();
            this.onSubmit();
          }}
          disabled={disabled}
          className={classNames([
            baseButtonClass,
            disabled ? disabledButtonClass : activeButtonClass
          ])}
          tabIndex={11}
        >
          Reset Password
        </button>
      </div>
    );
  }
}

export default PasswordResetForm;
