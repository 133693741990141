import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";

export class Pagination extends React.PureComponent {
  static propTypes = {
    currentPage: PropTypes.number,
    onPageChanged: PropTypes.func.isRequired,
    totalPages: PropTypes.number
  };
  static defaultProps = {
    currentPage: 1,
    totalPages: 1
  };
  constructor(props) {
    super(props);

    this.goToNextPage = this.goToNextPage.bind(this);
    this.goToPrevPage = this.goToPrevPage.bind(this);
  }

  goToNextPage() {
    this.props.onPageChanged(
      Math.min(this.props.currentPage + 1, this.props.totalPages)
    );
  }

  goToPrevPage() {
    this.props.onPageChanged(Math.max(this.props.currentPage - 1, 1));
  }

  render() {
    const disableLeft = this.props.currentPage === 1 && true;
    const disableRight =
      this.props.currentPage === this.props.totalPages && true;
    return (
      <div
        className={
          "flex w-full justify-between items-center text-xs font-serif px-4 pr-3 mt-0.5"
        }
      >
        <div>
          Showing results {this.props.currentPage}-{this.props.totalPages}
        </div>
        {this.props.totalPages > 1 && (
          <nav>
            <button
              className={classNames([
                "px-1 mr-1",
                disableLeft && "text-gray-600"
              ])}
              disabled={disableLeft}
              onClick={this.goToPrevPage}
            >
              <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
            </button>
            <button
              className={classNames([
                "px-1 ml-1",
                disableRight && "text-gray-600"
              ])}
              disabled={disableRight}
              onClick={this.goToNextPage}
            >
              <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
            </button>
          </nav>
        )}
      </div>
    );
  }
}

export default Pagination;
