import React from "react";
import PasswordResetFormComponent from "../../components/password-reset-form";
import Auth from "../../services/auth";
import PropTypes from "prop-types";

class PasswordResetForm extends React.Component {
  static defaultProps = {};

  static propTypes = {
    token: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      loading: false,
      success: null
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(password) {
    const data = {
      password,
      email: this.props.email,
      token: this.props.token
    };

    this.setState({ loading: true });

    Auth.resetPassword(data)
      .then(() => this.setState({ success: true, loading: false }))
      .catch(errorMessage =>
        this.setState({
          errorMessage:
            errorMessage || "There's been an error. Please try again.",
          loading: false
        })
      );
  }

  render() {
    if (this.state.success === true) {
      return (
        <p>
          Your password was reset successfully. Please go back{" "}
          <a className={"underline text-blue-400"} href={"/map"}>
            to the website
          </a>{" "}
          and sign in
        </p>
      );
    } else {
      return (
        <PasswordResetFormComponent
          onSubmit={this.onSubmit}
          errorMessage={this.state.errorMessage}
          email={this.props.email}
          disabled={this.state.loading}
        />
      );
    }
  }
}

export default PasswordResetForm;
